import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface ForgotPasswordOptions {
  email: string;
}

async function postForgotPassword({ email }: ForgotPasswordOptions) {
  const { data } = await axios.post<ForgotPasswordOptions>(`/v1/dashboard/forgot-password`, {
    email
  });
  return data;
}

export function useForgotPasswordMutation() {
  return useMutation({
    mutationFn: postForgotPassword
  });
}
