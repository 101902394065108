import { useEffect, useState, type HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as ThreeSmallStars } from 'react-icons/bs';
import { PiStarFourFill as SmallStar } from 'react-icons/pi';
import { Dialog } from '@knack/asterisk-react';

import { useNextRandomFromArray } from '@/hooks/useNextRandomFromArray';
import GradientSpinner from '@/components/ui/GradientSpinner';

type Props = {
  isAi?: boolean;
  phrases: string[];
} & HTMLProps<HTMLDivElement>;

export function LoadingModal({ phrases, isAi, ...props }: Props) {
  const { t } = useTranslation();
  const { next } = useNextRandomFromArray<string>(phrases);
  const [phrase, setPhrase] = useState(phrases[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPhrase(next());
    }, 5000);

    return () => clearInterval(interval);
  }, [phrases, next]);

  return (
    <Dialog open {...props}>
      <Dialog.Content
        width="lg"
        className="absolute bottom-0 left-0 right-0 top-0 [&>button]:hidden"
        data-testid="loading-modal"
      >
        <div className="flex h-full flex-col items-center justify-center">
          <div className="relative flex items-center justify-center p-6">
            {isAi && (
              <ThreeSmallStars
                className="relative top-[-24px] mr-8 rotate-90 fill-[url(#svg-gradient-2)]"
                size={40}
              />
            )}
            <GradientSpinner className="h-12 w-12" />
            {isAi && (
              <div className="relative ml-8 mt-8 flex h-7 w-7 justify-between">
                <SmallStar size="10" className="absolute bottom-0 fill-[url(#svg-gradient-2)]" />
                <SmallStar
                  size="13"
                  className="absolute right-0 top-2 fill-[url(#svg-gradient-2)]"
                />
              </div>
            )}
          </div>
          <p className="mb-4 text-xl" data-testid="loading-modal-text">
            {phrase}
          </p>
          <p className="text-sm text-subtle">
            {t('components.create_app.this_may_take_some_time')}
          </p>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
