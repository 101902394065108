import { type HearAboutUsOption } from './types';

export const options: HearAboutUsOption[] = [
  { id: 'social_media', hasInput: false },
  { id: 'search_engines', hasInput: false },
  { id: 'youtube', hasInput: false },
  { id: 'event', hasInput: false },
  { id: 'newsletter', hasInput: false },
  { id: 'podcast', hasInput: false },
  { id: 'friend', hasInput: true },
  { id: 'article', hasInput: false },
  { id: 'other', hasInput: true }
];
