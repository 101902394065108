import { type FormState, type UseFormRegister } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge, Card, Input } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { type Session } from '@/types/session';
import { FormControl } from '@/components/ui/FormControl';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}

interface AccountOwnerInfoFormProps {
  isGoogleUser: boolean;
  session: Session;
  register: UseFormRegister<FormValues>;
  accountOwnerInfoFormState: FormState<FormValues>;
}

export function AccountOwnerInfoForm({
  isGoogleUser,
  session,
  register,
  accountOwnerInfoFormState
}: AccountOwnerInfoFormProps) {
  const [t] = useTranslation();
  const { errors, isSubmitting } = accountOwnerInfoFormState;
  const hasSetPassword = session?.user?.hasSetPassword;
  const disableInput = isGoogleUser && !hasSetPassword;

  return (
    <Card className="flex flex-col p-6 sm:p-6">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-xl text-emphasis">{t('components.profile.title')}</h2>
        {isGoogleUser && (
          <Badge intent="success" className="text-center">
            {t('components.profile.connected_with_google')}
          </Badge>
        )}
      </div>
      <div className="mb-7 flex flex-col gap-6">
        <div className="flex flex-col lg:flex-row lg:gap-6">
          <FormControl className="mb-6 flex-1">
            <FormControl.Label
              htmlFor="profile-first-name"
              intent={errors.firstName && 'destructive'}
              data-testid="account-overview-required-field-error-message"
            >
              {t('components.profile.first_name')}
            </FormControl.Label>
            <Input
              id="profile-first-name"
              data-testid="account-overview-first-name"
              disabled={isSubmitting}
              title={t('components.profile.first_name')}
              type="text"
              intent={errors.firstName && 'destructive'}
              defaultValue={session.user?.firstName}
              placeholder={t('components.profile.placeholder_first_name')}
              {...register('firstName')}
            />
            {errors.firstName && (
              <FormControl.Message type="error">{errors.firstName.message}</FormControl.Message>
            )}
          </FormControl>
          <FormControl className="mb-6 flex-1">
            <FormControl.Label
              htmlFor="profile-last-name"
              intent={errors.lastName && 'destructive'}
            >
              {t('components.profile.last_name')}
            </FormControl.Label>
            <Input
              id="profile-last-name"
              data-testid="account-overview-last-name"
              title={t('components.profile.last_name')}
              type="text"
              intent={errors.lastName && 'destructive'}
              defaultValue={session.user?.lastName}
              placeholder={t('components.profile.placeholder_last_name')}
              disabled={isSubmitting}
              {...register('lastName')}
            />
            {errors.lastName && (
              <FormControl.Message type="error">{errors.lastName.message}</FormControl.Message>
            )}
          </FormControl>
        </div>
      </div>

      <FormControl className="mb-6">
        <FormControl.Label htmlFor="profile-email" intent={errors.email && 'destructive'}>
          {t('components.profile.email')}
        </FormControl.Label>
        <Input
          disabled={disableInput || isSubmitting}
          id="profile-email"
          data-testid="account-overview-email"
          title={t('components.profile.email')}
          type="email"
          intent={errors.email && 'destructive'}
          defaultValue={session.user?.email}
          placeholder={t('components.profile.placeholder_email')}
          {...register('email')}
        />
        {errors.email && (
          <FormControl.Message type="error">{errors.email.message}</FormControl.Message>
        )}
        {errors.password && (
          <FormControl.Message type="error">{errors.password.message}</FormControl.Message>
        )}
        {!errors.email && hasSetPassword && (
          <FormControl.Message className="text-subtle">
            {t('components.profile.email_helper_text')}
          </FormControl.Message>
        )}
        {isGoogleUser && !hasSetPassword && (
          <FormControl.Message className="text-subtle">
            <Trans i18nKey="components.profile.google_account_message">
              <Link className="underline" to={`/${Route.Settings}/${Route.Security}`}>
                set password
              </Link>
            </Trans>
          </FormControl.Message>
        )}
      </FormControl>
    </Card>
  );
}
