import { useCallback, useLayoutEffect, useState } from 'react';

type UseMediaQueryOptions = {
  defaultValue?: boolean;
};

export function useMediaQuery(
  query: string,
  { defaultValue = false }: UseMediaQueryOptions = {}
): boolean {
  // Helper function to determine the media query match
  const getMatches = (q: string): boolean => window.matchMedia(q).matches;

  // State to store the current match status
  const [matches, setMatches] = useState<boolean>(defaultValue);

  // Handles the change event of the media query
  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  // Effect to manage event listeners for media query changes
  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Set the initial state based on the media query
    handleChange();

    // Use modern `addEventListener` to support all modern browsers
    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [handleChange, query]);

  return matches;
}
