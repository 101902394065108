import { useQueryClient } from '@tanstack/react-query';

import { type Session } from '@/types/session';
import { sessionDefault } from './api/queries/useSessionQuery';
import { queryKeys } from './api/queryKeys';

export function useSession() {
  const queryClient = useQueryClient();
  return queryClient.getQueryData<Session>([queryKeys.auth.session]) || sessionDefault;
}

export function useIsSharedBuilderOnly() {
  const session = useSession();
  return !session.account?.id;
}
