import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowTopRightOnSquare } from 'react-icons/hi2';
import { NavLink, useLocation } from 'react-router-dom';

import { cn } from '@/utils/tailwind';
import { type ReadonlyLinkItem } from './MainNav';

export function NavItem({ item }: { item: ReadonlyLinkItem }) {
  const { search } = useLocation();
  const [t] = useTranslation();
  const Icon = item.icon;

  const LinkComponent = useMemo(() => {
    const props = {
      'data-testid': item.dataTestId,
      className: `flex items-center rounded-lg px-3 py-2.5 hover:bg-subtle focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 ${item.class}`
    };
    const isExternalRoute = item.route?.startsWith('http://') || item.route?.startsWith('https://');

    if (isExternalRoute) {
      return function ExternalLink({ children }: { children: React.ReactNode }) {
        return (
          <a
            {...props}
            className={cn(props.className, 'justify-between')}
            href={item.route}
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center">{children}</div>
            <HiArrowTopRightOnSquare className="ml-2 text-subtle" />
          </a>
        );
      };
    }

    return function InternalLink({ children }: { children: React.ReactNode }) {
      return (
        <NavLink
          {...props}
          to={{ pathname: `/${item.route}`, search }}
          className={({ isActive }) =>
            cn([props.className, { 'bg-emphasis hover:bg-emphasis': isActive }])
          }
        >
          {children}
        </NavLink>
      );
    };
  }, [item, search]);

  return (
    <li key={item.route} className={cn({ 'mt-8 first:mt-0': !!item.children })}>
      {item.children ? (
        <div className="mb-2 flex w-full items-center justify-between px-3 py-2.5 text-xs text-subtle">
          {Icon && <Icon className="mr-2 text-subtle" />}
          {t(item.i18nKey)}
        </div>
      ) : (
        <LinkComponent>
          {Icon && <Icon className="mr-2 text-subtle" />}
          {t(item.i18nKey)}
        </LinkComponent>
      )}
      {item.children && (
        <ul>
          {item.children.map((childItem) => (
            <NavItem key={childItem.i18nKey} item={childItem} />
          ))}
        </ul>
      )}
    </li>
  );
}
