import { Skeleton } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export function InvoicesSkeleton() {
  const NUMBER_OF_ROWS = 12;
  const commonGridClasses = 'grid grid-cols-[220px_1fr_142px_2fr]';

  return (
    <div className="mb-6">
      <div className={cn('mb-10 h-9 content-center border-b border-subtle', commonGridClasses)}>
        <Skeleton className="h-2 w-9" />
        <Skeleton className="h-2 w-14" />
        <Skeleton className="h-2 w-20 justify-self-end" />
        <Skeleton className="ml-5 h-2 w-14" />
      </div>
      <div className="space-y-8">
        {Array.from({ length: NUMBER_OF_ROWS }, (_, index) => (
          <div className={commonGridClasses} key={index}>
            {index % 2 === 0 ? (
              <>
                <Skeleton className="h-2 w-28" />
                <Skeleton className="h-2 w-16" />
                <Skeleton className="h-2 w-10 justify-self-end" />
              </>
            ) : (
              <>
                <Skeleton className="h-2 w-36" />
                <Skeleton className="h-2 w-28" />
                <Skeleton className="h-2 w-6 justify-self-end" />
              </>
            )}
            <div className="ml-5 grid grid-cols-2 gap-6">
              <Skeleton className="h-2 w-28" />
              <Skeleton className="h-2 w-28" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
