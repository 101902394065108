import { type Coupon } from '@/hooks/api/mutations/useValidateCouponMutation';
import { STARTER_PRO_DISCOUNT_CAMPAIGN_COUPON_ID } from './constants';

const isDiscountCampaignCoupon = (id: string) =>
  id === STARTER_PRO_DISCOUNT_CAMPAIGN_COUPON_ID.test.starter ||
  id === STARTER_PRO_DISCOUNT_CAMPAIGN_COUPON_ID.test.pro ||
  id === STARTER_PRO_DISCOUNT_CAMPAIGN_COUPON_ID.prod.starter ||
  id === STARTER_PRO_DISCOUNT_CAMPAIGN_COUPON_ID.prod.pro;

export function getPriceWithCouponDiscount(
  price: number,
  coupon?: Coupon | null,
  isAnnual?: boolean,
  shouldSkipDiscountCampaignCalculation?: boolean
) {
  let finalPrice = price;

  if (isAnnual) {
    finalPrice /= 12;
  }
  if (coupon && coupon.valid) {
    // For the new plan invoices (Discount Campaign), we don't want to calculate the discount
    // as it will never be applied on an upgrade/downgrade, so the the price
    // will always be the base fare. For the current plan, we do want to calculate it
    // to show the user the final price
    // This only applies on the Confirm Plan Dialog
    if (isDiscountCampaignCoupon(coupon.id) && shouldSkipDiscountCampaignCalculation) {
      return finalPrice;
    }
    if (coupon.percent_off) {
      finalPrice *= 1 - coupon.percent_off / 100;
    }

    // Stripe sends the amount_off in cents
    if (coupon.amount_off) {
      finalPrice -= coupon.amount_off / 100;
    }
  }
  return Math.round(finalPrice);
}
