import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AsteriskLogo, Button, InputPassword, ThemeProviderContext } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { cn } from '@/utils/tailwind';
import { FormErrorBanner } from '@/components/forms';
import { FormControl } from '@/components/ui/FormControl';
import { type ResetPasswordPageParams } from '.';
import { useResetPasswordForm } from './useResetPasswordForm';

export function ResetPasswordForm() {
  const [t] = useTranslation();
  const { search } = useLocation();
  const urlParams = useParams<ResetPasswordPageParams>();
  if (!urlParams.token) {
    throw new Error('Reset password form can only be called within a route with a token parameter');
  }
  const { isDarkMode } = useContext(ThemeProviderContext);

  const { register, handleSubmit, errors, isSubmitting, onSubmitHandler, isSubmitSuccessful } =
    useResetPasswordForm({
      token: urlParams.token
    });

  if (isSubmitSuccessful) {
    return (
      <>
        <p className="mb-8 self-start">{t('components.reset_password.confirmation')}</p>
        <div className="flex w-full items-center">
          <MdArrowBack className="mr-2" />
          <Link className="hover:underline" to={{ pathname: `/${Route.SignIn}`, search }}>
            {t('components.reset_password.back_to_login')}
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={cn(
          'mb-12 hidden h-14 w-16 text-white sm:block md:text-brand',
          isDarkMode && 'md:text-white'
        )}
      >
        <AsteriskLogo />
      </div>
      <h1 className="mb-4 self-start text-3xl sm:mb-6 sm:self-center sm:text-4xl">
        {t('components.reset_password.title')}
      </h1>
      <p className="mb-10">{t('components.reset_password.description')}</p>

      <FormErrorBanner errors={errors} />
      <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full">
        <FormControl>
          <FormControl.Label htmlFor="newPassword" intent={errors.newPassword && 'destructive'}>
            {t('components.reset_password.new_password')}
          </FormControl.Label>
          <InputPassword
            data-private
            title={t('components.auth.new_password_title')}
            aria-label={t('components.auth.new_password_title')}
            id="newPassword"
            data-testid="newPassword"
            disabled={isSubmitting}
            placeholder={t('components.auth.new_password_placeholder')}
            {...register('newPassword')}
          />
          {errors.newPassword && (
            <FormControl.Message type="error">{errors.newPassword.message}</FormControl.Message>
          )}
        </FormControl>
        <FormControl className="mt-4">
          <FormControl.Label
            htmlFor="confirmPassword"
            intent={errors.confirmPassword && 'destructive'}
          >
            {t('components.reset_password.confirm_password')}
          </FormControl.Label>
          <InputPassword
            data-private
            title="Confirm Password"
            id="confirmPassword"
            data-testid="confirmNewPassword"
            disabled={isSubmitting}
            {...register('confirmPassword')}
          />
          {errors.confirmPassword && (
            <FormControl.Message type="error">{errors.confirmPassword.message}</FormControl.Message>
          )}
        </FormControl>
        <Button
          type="submit"
          data-testid="resetPasswordButton"
          isLoading={isSubmitting}
          className="mt-8 w-full"
        >
          {t('components.reset_password.submit')}
        </Button>
      </form>
    </>
  );
}
