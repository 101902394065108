import { Skeleton } from '@knack/asterisk-react';

import { APPS_SKELETON_NUMBER_OF_ROWS } from '@/pages/apps/create-app/constants';

export function MyAppsSkeleton({ rows = APPS_SKELETON_NUMBER_OF_ROWS }: { rows?: number }) {
  return (
    <div>
      <div className="mb-9 grid h-9 grid-cols-[248px_1fr_1fr_1fr_328px_1.5fr] content-center border-b border-subtle">
        <Skeleton className="h-2 w-9" />
        <Skeleton className="h-2 w-14 justify-self-end" />
        <Skeleton className="h-2 w-14 justify-self-end" />
        <Skeleton className="h-2 w-10 justify-self-end" />
        <Skeleton className="ml-6 h-2 w-20" />
        <Skeleton className="ml-5 h-2 w-14" />
      </div>
      <div className="space-y-9">
        {Array.from({ length: rows }, (_, index) => (
          <div className="grid grid-cols-[248px_1fr_1fr_1fr_328px_1.5fr]" key={index}>
            {index % 2 === 0 ? (
              <>
                <Skeleton className="h-3 w-36" />
                <Skeleton className="h-3 w-8 justify-self-end" />
                <Skeleton className="h-3 w-11 justify-self-end" />
                <Skeleton className="h-3 w-4 justify-self-end" />
                <Skeleton className="ml-6 h-3 w-60" />
              </>
            ) : (
              <>
                <Skeleton className="h-3 w-48" />
                <Skeleton className="h-3 w-10 justify-self-end" />
                <Skeleton className="h-3 w-12 justify-self-end" />
                <Skeleton className="h-3 w-8 justify-self-end" />
                <Skeleton className="ml-6 h-3 w-72" />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
