import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Input, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { useDisableTwoFAMutation } from '@/hooks/api/mutations/useDisableTwoFAMutation';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { useServerErrors } from '@/hooks/api/useServerErrors';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { FormControl } from '@/components/ui/FormControl';

export function DisableTwoFactorAuth() {
  const [t] = useTranslation();
  const { getError } = useServerErrors();
  const { mutate: disableTwoFa, isPending } = useDisableTwoFAMutation();
  const { refetch } = useSessionQuery();
  const { presentToast } = useToast();
  const schema = z.object({
    code: z
      .string()
      .regex(/^[0-9]{6,6}$/g, t('components.two_factor_authentication.error_code_numbers_only'))
      .max(6, t('components.two_factor_authentication.error_max_code'))
  });

  type FormSchemaType = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid }
  } = useForm<FormSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(schema)
  });

  const disableTwoFA = (data: { code: string }) =>
    disableTwoFa(data.code, {
      onSuccess: () => {
        presentToast({
          title: t('components.two_factor_authentication.disable_success')
        });
        reset();
        void refetch();
      },
      onError: (err: any) => {
        const errorCode = err.response?.data?.errors[0]?.message;
        const error = getError(errorCode);

        if (error?.key === 'invalid_code') {
          setError('code', {
            message: error.message
          });
        } else {
          presentToast({
            title: t('errors.generic_error')
          });
        }
      }
    });

  const Footer = (
    <Dialog.Footer>
      <div className="flex justify-end gap-2">
        <Dialog.Close asChild>
          <Button disabled={isPending} intent="minimal">
            {t('actions.cancel')}
          </Button>
        </Dialog.Close>
        <Button disabled={!isValid} isLoading={isPending} type="submit" form="disable-form">
          {t('actions.disable')}
        </Button>
      </div>
    </Dialog.Footer>
  );

  return (
    <Dialog>
      <Dialog.Trigger asChild>
        <Button intent="minimal">{t('components.two_factor_authentication.disable_2fa')}</Button>
      </Dialog.Trigger>
      <DashboardDialogContent
        headerTitle={t('components.two_factor_authentication.disable_2fa_title')}
        footer={Footer}
      >
        <div className="flex flex-col gap-12">
          <p>{t('components.two_factor_authentication.disable_confirm')}</p>
          <form id="disable-form" onSubmit={handleSubmit(disableTwoFA)}>
            <FormControl>
              <FormControl.Label
                htmlFor="two-fa-confirm-code"
                intent={errors.code?.message ? 'destructive' : undefined}
                className="text-subtle"
              >
                {t('components.two_factor_authentication.six_digit_code')}
              </FormControl.Label>
              <Input
                id="two-fa-confirm-code"
                className="form-input appearance-none"
                type="string"
                maxLength={6}
                minLength={6}
                placeholder={t('components.two_factor_authentication.six_digit_code')}
                inputMode="numeric"
                title={t('components.two_factor_authentication.six_digit_code_title')}
                intent={errors.code?.message ? 'destructive' : 'default'}
                {...register('code')}
              />
              {errors.code?.message && (
                <FormControl.Message type="error">{errors.code?.message}</FormControl.Message>
              )}
            </FormControl>
          </form>
        </div>
      </DashboardDialogContent>
    </Dialog>
  );
}
