import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowSmallRight as ArrowIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { useLogoutUserMutation } from '@/hooks/api/mutations/useLogoutUserMutation';
import { useSession } from '@/hooks/useSession';
import { PageError } from '@/components/errors/PageError';

export function AccountDeleted() {
  const [t, { language }] = useTranslation();
  const logout = useLogoutUserMutation();
  const session = useSession();

  return (
    <main className="full-page-wrapper">
      <PageError
        title={t('components.account_deleted.title')}
        message={t('components.account_deleted.message')}
        button={
          <Button
            asChild
            className="gap-1"
            onClick={() =>
              logout.mutate({
                accountId: session.account.id,
                userId: session.user.id
              })
            }
          >
            <div>
              <a href={`/${Route.SignIn}?accountDeleted=true`}>
                {t('components.account_deleted.button_text')}
              </a>
              <ArrowIcon size={14} />
            </div>
          </Button>
        }
      />
      <Helmet>
        <title lang={language}>{`${t('titles.account_deleted')} - ${t('titles.app_name')}`}</title>
      </Helmet>
    </main>
  );
}
