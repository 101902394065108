import axios, { type AxiosError } from 'axios';
import { t } from 'i18next';

type ServerError = {
  i18nKey?: string;
  message?: string;
};

const getMessageFromError = (error: ServerError, defaultMessage: string) => {
  let { message } = error;

  // If the error contains a i18nKey, we want to use the translation.
  if (error.i18nKey) {
    const i18nMessage = t(error.i18nKey);

    // Ensure we have a translation for the given key.
    if (i18nMessage !== error.i18nKey) {
      message = i18nMessage;
    }
  }

  return message ?? defaultMessage;
};

export function getErrorMessage(error: Error | AxiosError | unknown, defaultMessage: string) {
  let message = defaultMessage;
  if (axios.isAxiosError(error)) {
    const errors = error.response?.data?.errors;
    if (Array.isArray(errors)) {
      errors.forEach((errorObject, index) => {
        if (index === 0) {
          // Show user the first error;
          message = getMessageFromError(errorObject, defaultMessage);
        } else {
          // TODO: Daniel Cassil - Do we need a UI solution for multiple errors?
        }
      });
    }
  }
  return message;
}
