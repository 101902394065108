import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type BillingOverview } from '@/types/billing';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getBillingOverview(accountId: string) {
  const endpoint = `/v1/accounts/${accountId}/billing/overview`;

  const { data } = await axios.get<BillingOverview>(endpoint, {
    withCredentials: true
  });

  return data;
}

export function useBillingOverviewQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.billing.overview],
    queryFn: () => getBillingOverview(session.account.id),
    staleTime: 1000 * 30
  });
}
