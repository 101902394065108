import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowTopRightOnSquare } from 'react-icons/hi2';
import { Button, Card, Skeleton } from '@knack/asterisk-react';

import {
  useVideoResourcesQuery,
  type VideoResource as VideoResourceType
} from '@/hooks/api/queries/useVideoResourcesQuery';
import { useSession } from '@/hooks/useSession';
import { VIDEOS_URL } from '@/utils/constants';
import { PageLayout } from '@/components/layout';
import {
  COMMUNITY_URL,
  EXPERT_DIRECTORY_URL,
  KNOWLEDGE_BASE_URL
} from '@/components/navigation/main-nav';

type VideoResourceProps = {
  video?: VideoResourceType;
  isLoading: boolean;
};

export function VideoResource({ video, isLoading }: VideoResourceProps) {
  const containerClassName = 'flex flex-1 flex-col gap-2';

  if (isLoading) {
    return (
      <div className={containerClassName}>
        <Skeleton className="h-16" />
        <Skeleton className="h-4" />
      </div>
    );
  }

  if (!video) {
    return null;
  }

  return (
    <a href={video.url} target="_blank" rel="noreferrer" className={containerClassName}>
      <img className="rounded-md object-cover" src={video.thumbnail} alt={video.title} />
      <div className="text-xs">{video.title}</div>
    </a>
  );
}

type BrandCardProps = {
  title: string;
  description: string;
  link: string;
  linkTitle: string;
};

function BrandCard({ title, description, link, linkTitle }: BrandCardProps) {
  return (
    <div>
      <div className="h-2 w-full rounded-t-lg bg-gradient-2" />
      <Card className="flex h-full flex-col justify-between gap-6 sm:p-4 sm:pb-6">
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-emphasis">{title}</div>
          <div className="font-light">{description}</div>
        </div>
        <Button intent="link" className="w-fit" asChild>
          <a href={link} target="_blank" rel="noreferrer">
            {linkTitle}
            <Button.Icon position="right" icon={HiArrowTopRightOnSquare} />
          </a>
        </Button>
      </Card>
    </div>
  );
}

export const VIDEO_LIMIT = 5;

export function GettingStarted() {
  const session = useSession();
  const { data: videos, isLoading } = useVideoResourcesQuery(
    session.user.marketing?.questionnaire?.user_type,
    VIDEO_LIMIT
  );
  const [t, { language }] = useTranslation();

  return (
    <PageLayout>
      <h1 className="mb-6 text-3xl font-semibold text-emphasis">
        {t('components.getting_started.getting_started')}
      </h1>
      <div className="flex flex-col gap-12">
        <Card className="flex flex-col gap-4 sm:p-4">
          <h3 className="text-xl font-medium text-emphasis">
            {t('components.getting_started.suggested_videos')}
          </h3>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:flex lg:flex-row">
            {Array.from({ length: VIDEO_LIMIT }, (_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <VideoResource key={index} isLoading={isLoading} video={videos?.[index]} />
            ))}
            <div className="hidden min-h-full w-px bg-subtle lg:block" />
            <div className="flex flex-1 border-subtle px-4 py-4 sm:border-l-[1px] lg:border-l-0">
              <Button intent="link" className="mx-auto my-auto text-center text-sm" asChild>
                <a href={VIDEOS_URL} target="_blank" rel="noreferrer">
                  {t('components.getting_started.video_tutorials')}
                  <Button.Icon position="right" icon={HiArrowTopRightOnSquare} />
                </a>
              </Button>
            </div>
          </div>
        </Card>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <BrandCard
            title={t('components.getting_started.help_articles')}
            description={t('components.getting_started.help_articles_description')}
            link={KNOWLEDGE_BASE_URL}
            linkTitle={t('components.getting_started.knowledge_base')}
          />
          <BrandCard
            title={t('components.getting_started.hire_an_expert')}
            description={t('components.getting_started.hire_an_expert_description')}
            link={EXPERT_DIRECTORY_URL}
            linkTitle={t('components.getting_started.expert_directory')}
          />
          <BrandCard
            title={t('components.getting_started.community')}
            description={t('components.getting_started.community_description')}
            link={COMMUNITY_URL}
            linkTitle={t('components.getting_started.community_forum')}
          />
        </div>
      </div>
      <Helmet>
        <title lang={language}>
          {`${t('navigation.getting_started')} - ${t('titles.app_name')}`}
        </title>
      </Helmet>
    </PageLayout>
  );
}
