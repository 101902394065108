import { useTranslation } from 'react-i18next';
import { Banner } from '@knack/asterisk-react';
import { isAxiosError } from 'axios';

type ServerErrorType = {
  message: string;
};

type ServerErrorResponseType = {
  errors: ServerErrorType[];
};

export function AxiosErrorBanner({
  errors,
  translationPrefix
}: {
  errors: unknown;
  translationPrefix: string;
}) {
  const [t] = useTranslation();

  if (!isAxiosError<ServerErrorResponseType>(errors)) return null;

  return (
    <>
      {errors.response?.status !== 500 &&
        errors.response?.data.errors.map((error) => (
          <Banner key={error.message} intent="destructive">
            <Banner.Message>{t(`${translationPrefix}.${error.message}`)}</Banner.Message>
          </Banner>
        ))}
      {errors.response?.status === 500 && (
        <Banner intent="destructive">
          <Banner.Message>{t('errors.generic_error')}</Banner.Message>
        </Banner>
      )}
    </>
  );
}
