import { Divider } from '@knack/asterisk-react';

import type { Credentials } from '@/types/auth';
import { cn } from '@/utils/tailwind';
import { AuthForm } from '@/components/auth-form';
import { GoogleButton } from '@/components/ui/GoogleButton';

interface SignUpFormProps {
  onSubmit: (data: Credentials) => void;
  signUpError: string;
  isLoading: boolean;
  className?: string;
}

export function SignUpForm({ onSubmit, signUpError, isLoading, className = '' }: SignUpFormProps) {
  return (
    <>
      <div className={cn('flex items-center justify-center', className)}>
        <GoogleButton isSignUp />
      </div>

      <Divider className="mb-6" text="or" />

      <AuthForm
        isSignUp
        onSubmit={onSubmit}
        submissionError={signUpError}
        className="mb-6"
        isLoading={isLoading}
      />
    </>
  );
}
