export enum ReasonsForDeleting {
  Price = 'price',
  IssueWithProduct = 'issue-with-product',
  IssueWithSupport = 'issue-with-support',
  ProjectEnded = 'project-ended',
  JobChanged = 'job-change',
  NotGoodFit = 'not-a-good-fit-for-us',
  Competitor = 'competitor',
  Other = 'other'
}

export enum IssueWithProductReasons {
  LackDesignElements = 'lack-of-design-elements',
  TooDifficultToUse = 'too-difficult-to-use',
  TooSlowLaggy = 'too-slow-laggy',
  BugsNotFixed = 'bugs-not-fixed',
  MissingFeatures = 'missing-features'
}

export enum IssueWithSupportReasons {
  SlowResponses = 'slow-responses',
  NeverReceivedResponse = 'never-received-response',
  IssueWasNotResolved = 'issue-was-not-resolved',
  AgentWasRude = 'agent-was-rude',
  NotEnoughHelpResources = 'not-enough-help-resources'
}

export enum MovedToCompetitorReasons {
  Price = 'price',
  BetterFeatures = 'better-features',
  ReferredBySomeone = 'referred-by-someone'
}
