import { focusManager, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

export interface PaymentCardAddress {
  line1: string;
  line2: string | null;
  city: string;
  state?: string;
  postal_code: string;
  country: string;
}

export interface UpdatePaymentCardPayload {
  stripe_token: string;
  name?: string;
  address: PaymentCardAddress;
  plan?: string;
  promoCode?: string;
}

async function updateCard(updatedCardData: UpdatePaymentCardPayload, accountId: string) {
  const { data } = await axios.post(`/v1/accounts/${accountId}/billing/card`, updatedCardData, {
    withCredentials: true
  });

  return data;
}

export function usePaymentCardMutation() {
  const session = useSession();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedCardData: UpdatePaymentCardPayload) =>
      updateCard(updatedCardData, session.account.id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.billing.overview] });
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
      focusManager.setFocused(true);
    }
  });
}
