import { useLocation, useNavigate } from 'react-router-dom';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { useChecklistsContext } from '@/contexts/ChecklistsContext';

export function useChecklistsStepInitiator() {
  const { steps } = useChecklists();
  const { setHighlightedFeatures } = useChecklistsContext();
  const navigate = useNavigate();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const stepId = params.get('checklist_step');
  const step = steps.find((s) => s.definition.id === stepId);

  if (step && step.definition.type === 'in-product') {
    const { link } = step.definition.extra;

    const [area, url, features] = link!.split('::');

    if (area !== 'dashboard') return;

    if (features) {
      setHighlightedFeatures(features.split(','));
    }

    if (url === '{current}') return;

    navigate(`/${url}`);
  }
}
