import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowPath as RefreshIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { PageError } from '@/components/errors/PageError';
import { PageLayout } from '@/components/layout';

export function ErrorFallback({ resetError }: { resetError: () => void }) {
  const [t, { language }] = useTranslation();

  return (
    <PageLayout>
      <PageError
        title={t('components.something_went_wrong.title')}
        message={t('components.something_went_wrong.try_again')}
        button={
          <Button className="gap-1" onClick={resetError}>
            <RefreshIcon size={14} />
            {t('actions.refresh')}
          </Button>
        }
      />
      <Helmet>
        <title lang={language}>{`${t('components.something_went_wrong.title')} - ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </PageLayout>
  );
}
