import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function completeChecklistStep(stepId: string) {
  const url = `/v1/checklists`;

  const { data } = await axios.put(
    url,
    { stepId },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useCompleteChecklistStepMutation() {
  return useMutation({
    mutationFn: completeChecklistStep
  });
}
