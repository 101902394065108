import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { KnackLogo, ThemeProviderContext } from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { ChecklistsNavButton } from '@/components/checklists/ChecklistsNavButton';
import { MainNav, NavItemProfile } from '@/components/navigation/main-nav';
import { TrialCountdownWidget } from '@/components/ui/TrialCountdownWidget';

export function SideNav() {
  const { isDarkMode } = useContext(ThemeProviderContext);
  const { search } = useLocation();
  const session = useSession();

  return (
    <aside className="sticky top-0 hidden h-screen flex-col justify-between border-r border-subtle bg-muted p-4 text-sm md:flex">
      <NavLink
        to={{ pathname: '/', search }}
        className={`mb-8 block focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 ${
          isDarkMode ? 'text-white' : 'text-brand'
        }`}
        aria-label="Homepage"
      >
        <KnackLogo />
      </NavLink>
      <MainNav />
      <div className="mt-auto flex flex-col gap-4">
        <div className="mt-2 h-px w-full bg-emphasis" />
        <TrialCountdownWidget className="mt-2" />
        {session.user.marketing?.checklist && <ChecklistsNavButton className="mt-2" />}
        <NavItemProfile className="mt-2" />
      </div>
    </aside>
  );
}
