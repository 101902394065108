import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Dialog } from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';
import { useSession } from '@/hooks/useSession';

type Props = {
  stepId: string;
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function SelectAppChecklistModal({ stepId, open, setIsOpen }: Props) {
  const [t] = useTranslation();
  const { data: appsData } = useAppsQuery();
  const { search } = useLocation();
  const session = useSession();
  const [isLoading, setIsLoading] = useState(false);

  const openApp = (appSlug: string) => {
    setIsLoading(true);

    const builderUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${appSlug}`;

    const params = new URLSearchParams(search);
    params.append('checklist_step', stepId);

    window.location.href = `${builderUrl}?${params.toString()}`;
  };

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <Dialog.Content width="sm">
        <Dialog.MainContent className="p-0">
          <Dialog.Header className="flex flex-col p-6">
            <div className="text-xl font-medium text-emphasis">
              {t('components.checklists.select_app_modal.title')}
            </div>
            <div className="text-xs">{t('components.checklists.select_app_modal.subtitle')}</div>
          </Dialog.Header>

          <div className="flex flex-col gap-2 px-6">
            {appsData.map((app: KnackApplication) => (
              <Button
                key={app.id}
                intent="secondary"
                size="sm"
                className="justify-start"
                disabled={isLoading}
                onClick={() => openApp(app.slug)}
              >
                {app.name}
              </Button>
            ))}
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2">
          <Dialog.Close asChild>
            <Button intent="minimal" isLoading={isLoading} disabled={isLoading}>
              {t('actions.close')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
