import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getQR(accountId: string, userId: string) {
  const endpoint = `/v1/accounts/session/2fa/generate?account_id=${accountId}`;

  const { data } = await axios.post(
    endpoint,
    { user_id: userId },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useQRGenQuery() {
  const session = useSession();

  return useQuery({
    queryFn: () => getQR(session.account.id, session.user.id),
    queryKey: [queryKeys.qrCodeGen],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    gcTime: 0,
    retry: false
  });
}
