import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner, Button, Dialog, useToast } from '@knack/asterisk-react';

import { useApiPlanMutation } from '@/hooks/api/mutations/useApiPlanMutation';
import { getOrdinalNumber } from '@/utils/formatters';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { type ApiAddonItem, type ApiAddonItemAction } from './AddOns';

interface ConfirmApiPlanDialogProps {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedItem: ApiAddonItem;
  selectedAction: ApiAddonItemAction;
  hasApiPlan: boolean;
}

export function ConfirmApiPlanDialog({
  open,
  setIsOpen,
  selectedItem,
  selectedAction,
  hasApiPlan
}: ConfirmApiPlanDialogProps) {
  const { presentToast } = useToast();
  const [t] = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const { setApiPlanLimitMutation, removeApiPlanMutation } = useApiPlanMutation();

  const isLoading = setApiPlanLimitMutation.isPending || removeApiPlanMutation.isPending;

  const handleUpdateApiPlanLimit = () => {
    setApiPlanLimitMutation.mutate(
      { quantity: selectedItem.quantity },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.billing.addons.api_calls_plan_update_success')
          });
          setIsOpen(false);
        },
        onError: () => {
          setErrorMessage(t('errors.generic_error'));
        }
      }
    );
  };

  const handleRemoveApiPlan = () => {
    removeApiPlanMutation.mutate(undefined, {
      onSuccess: () => {
        presentToast({
          title: t('components.billing.addons.api_calls_plan_remove_success')
        });
        setIsOpen(false);
      },
      onError: () => {
        setErrorMessage(t('errors.generic_error'));
      }
    });
  };

  const onModalClose = () => {
    setErrorMessage('');
  };

  const dialogTitle = () => {
    if (selectedAction === 'remove') {
      return t('components.billing.addons.api_calls_remove_calls');
    }

    if (hasApiPlan) {
      return t('components.billing.addons.api_calls_select_calls_pack');
    }

    return t('components.billing.addons.api_calls_more_calls');
  };

  const dialogContent = () => {
    const date = new Date();
    const currentDayOfMonth = date.getDate();

    if (selectedAction === 'remove') {
      return t('components.billing.addons.api_calls_remove_description');
    }

    if (hasApiPlan) {
      return t('components.billing.addons.api_calls_change_selection_description', {
        numberOfCalls: selectedItem.calls.toLocaleString(),
        price: selectedItem.price.toLocaleString(),
        billingDay: getOrdinalNumber(currentDayOfMonth)
      });
    }

    return t('components.billing.addons.api_calls_upgrade_new_plan_description', {
      numberOfCalls: selectedItem.calls.toLocaleString(),
      price: selectedItem.price.toLocaleString(),
      billingDay: getOrdinalNumber(currentDayOfMonth)
    });
  };

  const dialogFooter = () => (
    <Dialog.Footer intent="borderless">
      <Dialog.Close asChild>
        <Button intent="minimal" onClick={() => setIsOpen(false)}>
          {t('actions.cancel')}
        </Button>
      </Dialog.Close>
      {selectedAction === 'remove' ? (
        <Button
          type="submit"
          intent="destructive"
          isLoading={isLoading}
          onClick={handleRemoveApiPlan}
        >
          {t('actions.remove')}
        </Button>
      ) : (
        <Button type="submit" isLoading={isLoading} onClick={handleUpdateApiPlanLimit}>
          {t('actions.purchase')}
        </Button>
      )}
    </Dialog.Footer>
  );

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DashboardDialogContent
        headerTitle={dialogTitle()}
        footer={dialogFooter()}
        onCloseAutoFocus={onModalClose}
      >
        {dialogContent()}

        {errorMessage && (
          <Banner className="mt-6" intent="destructive" icon={ExclamationIcon}>
            <Banner.Message>{errorMessage}</Banner.Message>
          </Banner>
        )}
      </DashboardDialogContent>
    </Dialog>
  );
}
