import { createContext, useContext, useMemo, useState, type ReactNode } from 'react';

type ChecklistsContextState = {
  highlightedFeatures: string[];
  setHighlightedFeatures: (features: string[]) => void;
};

const contextDefaultValues: ChecklistsContextState = {
  highlightedFeatures: [],
  setHighlightedFeatures: () => {}
};

const ChecklistsContext = createContext<ChecklistsContextState>(contextDefaultValues);

export function ChecklistsProvider({ children }: { children: ReactNode }) {
  const [highlightedFeatures, setHighlightedFeatures] = useState<string[]>([]);

  const stateValues = useMemo(
    () => ({
      highlightedFeatures,
      setHighlightedFeatures
    }),
    [highlightedFeatures]
  );

  return <ChecklistsContext.Provider value={stateValues}>{children}</ChecklistsContext.Provider>;
}

export const useChecklistsContext = () => useContext(ChecklistsContext);
