import { useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Link, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Button, Dialog, Input, Label, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { Route } from '@/enums';
import { type KnackApplication } from '@/types/apps';
import { useBuilderMutation } from '@/hooks/api/mutations/useBuilderMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useBuildersQuery } from '@/hooks/api/queries/useBuildersQuery';
import { getErrorMessage } from '@/utils/errors';
import { zodEmailRegex } from '@/utils/zod';
import { FormControl } from '@/components/ui/FormControl';
import { BuildersList } from './BuildersList';

interface ManageBuildersFormProps {
  app: KnackApplication;
}

export function ManageBuildersForm({ app }: ManageBuildersFormProps) {
  const [t] = useTranslation();
  const { createBuilder } = useBuilderMutation();
  const { data: account } = useAccountQuery();
  const { data: builders } = useBuildersQuery(app.id);
  const { presentToast } = useToast();
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  // When creating an account, builders count is 0, but we want to show 1 becase the owner counts as a builder.
  // The team is working to solve this in the backend, but for now we need to do this workaround.
  const sharedBuildersCount = account?.counts?.builders === 0 ? 1 : account?.counts?.builders;
  const accountSharedBuilderLimit = account?.product_plan?.builders;
  const hasReachedBuildersLimit =
    sharedBuildersCount &&
    accountSharedBuilderLimit &&
    sharedBuildersCount >= accountSharedBuilderLimit;

  const manageBuildersFormSchema = z.object({
    builderFirstName: z.string().nonempty(t('components.auth.first_name_required')),
    builderLastName: z.string().nonempty(t('components.auth.last_name_required')),
    builderEmail: z.string().regex(zodEmailRegex, t('components.auth.email_invalid'))
  });

  type ManageBuildersFormSchemaType = z.infer<typeof manageBuildersFormSchema>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isValid }
  } = useForm<ManageBuildersFormSchemaType>({
    resolver: zodResolver(manageBuildersFormSchema)
  });

  const onSubmitHandler: SubmitHandler<ManageBuildersFormSchemaType> = (data) => {
    if (hasReachedBuildersLimit) {
      setErrorMessage(
        t('components.manage_builders.max_number_builders', {
          builderLimit: `${accountSharedBuilderLimit} ${
            accountSharedBuilderLimit > 1 ? 'builders' : 'builder'
          }`
        })
      );
      return;
    }

    createBuilder.mutate(
      {
        appId: app.id,
        first_name: data.builderFirstName,
        last_name: data.builderLastName,
        email: data.builderEmail
      },
      {
        onSuccess: (response) => {
          if (response.builderAlreadyExistsForApplication) {
            setErrorMessage(t('components.manage_builders.builder_already_exists'));
          } else {
            presentToast({
              title: t('components.manage_builders.user_added', {
                user: data.builderFirstName
                  ? `${data.builderFirstName} ${data.builderLastName}`
                  : data.builderEmail
              })
            });
            setValue('builderFirstName', '');
            setValue('builderLastName', '');
            setValue('builderEmail', '');
            setErrorMessage('');
          }
        },
        onError: (error) => {
          const message = getErrorMessage(
            error,
            t('components.manage_builders.error_adding_builder')
          );
          setErrorMessage(message);
        }
      }
    );
  };

  return (
    <form className="flex flex-1 flex-col" onSubmit={handleSubmit(onSubmitHandler)}>
      <Dialog.MainContent>
        <div className="mb-2 space-y-6">
          <FormControl className="flex flex-col">
            <Label className="mb-4 font-medium">
              {t('components.manage_builders.add_builder')}
            </Label>
            <div className="mb-4 flex gap-4">
              <div>
                <FormControl.Label
                  isRequired
                  htmlFor="add-builder-first-name"
                  intent={errors.builderFirstName && 'destructive'}
                >
                  {t('components.profile.first_name')}
                </FormControl.Label>
                <Input.Container>
                  <Input
                    data-testid="manage-builders-first-name-field"
                    id="add-builder-first-name"
                    type="text"
                    placeholder={t('components.profile.first_name')}
                    title={t('components.profile.first_name')}
                    disabled={isSubmitting}
                    intent={errors.builderFirstName && 'destructive'}
                    {...register('builderFirstName')}
                  />
                </Input.Container>
              </div>
              <div>
                <FormControl.Label
                  isRequired
                  htmlFor="add-builder-last-name"
                  intent={errors.builderLastName && 'destructive'}
                >
                  {t('components.profile.last_name')}
                </FormControl.Label>
                <Input.Container>
                  <Input
                    data-testid="manage-builders-last-name-field"
                    id="add-builder-last-name"
                    type="text"
                    placeholder={t('components.profile.last_name')}
                    title={t('components.profile.last_name')}
                    disabled={isSubmitting}
                    intent={errors.builderLastName && 'destructive'}
                    {...register('builderLastName')}
                  />
                </Input.Container>
              </div>
            </div>
            <div className="flex items-end">
              <div className="flex-1">
                <FormControl.Label
                  isRequired
                  htmlFor="add-builder-email"
                  intent={errors.builderEmail && 'destructive'}
                >
                  {t('attributes.user.email')}
                </FormControl.Label>
                <Input.Container>
                  <Input
                    data-testid="manage-builders-email-field"
                    id="add-builder-email"
                    type="email"
                    placeholder={t('attributes.user.email')}
                    title={t('attributes.user.email')}
                    disabled={isSubmitting}
                    intent={errors.builderEmail && 'destructive'}
                    {...register('builderEmail')}
                  />
                </Input.Container>
                {errors.builderEmail && (
                  <FormControl.Message type="error">
                    {errors.builderEmail.message}
                  </FormControl.Message>
                )}
              </div>
              <Button
                data-testid="manage-builders-add-button"
                type="submit"
                className="ml-4 sm:px-4"
                isLoading={createBuilder.isPending || isSubmitting}
                disabled={isSubmitting || !!errors.builderEmail || !isValid}
              >
                {t('actions.add')}
              </Button>
            </div>
          </FormControl>
        </div>
        {errorMessage && (
          <Banner intent="destructive" icon={ExclamationIcon}>
            <Banner.Message>{errorMessage}</Banner.Message>
            {hasReachedBuildersLimit && (
              <Link
                to={{
                  pathname: `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
                  search
                }}
                className="font-medium hover:underline"
              >
                {t('components.manage_builders.upgrade_link')}
              </Link>
            )}
          </Banner>
        )}
        <BuildersList builders={builders} appId={app.id} setErrorMessage={setErrorMessage} />
      </Dialog.MainContent>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button intent="minimal" size="sm" data-testid="manage-builders-close-button">
            {t('actions.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Footer>
    </form>
  );
}
