import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

async function fetchCsrfToken() {
  const response = await axios.get<{ csrfToken: string }>('/csrf-token', {
    withCredentials: true
  });
  return response.data.csrfToken;
}

export function useCsrfTokenQuery() {
  return useQuery({
    queryKey: [queryKeys.csrfToken],
    queryFn: fetchCsrfToken,
    refetchOnWindowFocus: false
  });
}
