import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getPlanDowngradeInfo(accountId: string, planId: string) {
  const { data } = await axios.get(`/v1/accounts/${accountId}/billing/downgrade/${planId}`, {
    withCredentials: true
  });
  return data;
}

export function usePlanDowngradeInfoQuery(planId: string) {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.planDowngradeInfo, planId],
    queryFn: () => getPlanDowngradeInfo(session.account.id, planId)
  });
}
