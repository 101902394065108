import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type Builder } from '@/types/apps';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

type CreateBuilderData = {
  appId: string;
  first_name: string;
  last_name: string;
  email: string;
};

type DeleteBuilderData = {
  appId: string;
  builderId: string;
  accountId: string;
};

async function createBuilder(createBuilderData: CreateBuilderData, accountId: string) {
  const endpoint = `/v1/accounts/${accountId}/applications/${createBuilderData.appId}/builders`;

  const { data } = await axios.post(endpoint, createBuilderData, {
    withCredentials: true
  });
  return data;
}

async function deleteBuilder(deleteBuilderData: DeleteBuilderData) {
  const endpoint = `/v1/accounts/${deleteBuilderData.accountId}/applications/${deleteBuilderData.appId}/builders/${deleteBuilderData.builderId}`;

  const { data } = await axios.delete(endpoint, {
    withCredentials: true
  });

  return data;
}

export function useBuilderMutation() {
  const session = useSession();
  const queryClient = useQueryClient();

  const createBuilderMutation = useMutation({
    mutationFn: (createBuilderData: CreateBuilderData) =>
      createBuilder(createBuilderData, session.account.id),
    onSuccess: (data) => {
      queryClient.setQueryData([queryKeys.apps.builders], (oldData: Builder[] | undefined) => [
        ...(oldData || []),
        data.builder
      ]);
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
    }
  });

  const deleteBuilderMutation = useMutation({
    mutationFn: (deleteBuilderData: DeleteBuilderData) => deleteBuilder(deleteBuilderData),
    onSuccess: (data, deleteBuilderData) => {
      queryClient.setQueryData([queryKeys.apps.builders], (oldData: Builder[] | undefined) =>
        oldData?.filter((builder) => builder.id !== deleteBuilderData.builderId)
      );
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.apps.sharedApplications]
      });
    }
  });

  return {
    createBuilder: createBuilderMutation,
    deleteBuilder: deleteBuilderMutation
  };
}
