import { Skeleton } from '@knack/asterisk-react';

export function PlansSkeleton() {
  return (
    <>
      <div className="mb-6">
        <div className="mb-3 flex items-center gap-3">
          <Skeleton className="h-2 w-32" />
          <Skeleton className="h-6 w-10 rounded-full" />
          <Skeleton className="h-2 w-32" />
        </div>
        <Skeleton className="h-2 w-52" />
      </div>
      <div className="grid gap-6 lg:grid-cols-2 xl:grid-cols-4">
        <Skeleton className="h-80 w-56" />
        <Skeleton className="h-80 w-56" />
        <Skeleton className="h-80 w-56" />
        <Skeleton className="h-80 w-56" />
      </div>
      <Skeleton className="mt-7 h-3 w-52" />
    </>
  );
}
