export function ReportsIllustration() {
  return (
    <svg fill="none" viewBox="0 0 139 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g clipPath="url(#b)">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
          <rect x="17.5" y="37" width="50" height="33.688" rx="2" fill="#F2F2F3" />
          <path
            d="m22.859 40.828v23.352c0 0.4228 0.3428 0.7656 0.7656 0.7656h35.234"
            stroke="#DFDFDF"
            strokeLinecap="round"
            strokeWidth=".76562"
          />
          <path
            d="m26 63 5.8177-2.8725c0.1665-0.0822 0.3571-0.1011 0.5365-0.0532l8.0727 2.1566c0.2403 0.0643 0.4968 0.0077 0.6879-0.1516l7.0326-5.8644c0.1642-0.137 0.3783-0.199 0.5903-0.1711l7.2623 0.9562"
            stroke="#50409B"
            strokeLinecap="round"
            strokeWidth=".76562"
          />
          <path
            d="m25 49.344 6.2408 1.9202c0.1697 0.0522 0.3503 0.0583 0.5232 0.0176l7.8565-1.8486c0.2441-0.0574 0.5008-0.021 0.7193 0.1019l7.1699 4.0331c0.3045 0.1712 0.6761 0.1712 0.9806 0l7.5097-4.2242"
            stroke="#B1ABD0"
            strokeLinecap="round"
          />
          <rect x="71.5" y="37" width="50" height="33.688" rx="2" fill="#F2F2F3" />
          <path
            d="m76.859 40.984v23.234c0 0.4229 0.3428 0.7657 0.7656 0.7657h35.234"
            stroke="#DFDFDF"
            strokeLinecap="round"
            strokeWidth=".76562"
          />
          <rect x="83.5" y="47" width="4" height="18" fill="#DED488" />
          <rect x="96.5" y="53" width="4" height="12" fill="#50409B" />
          <rect x="87.5" y="58" width="4" height="7" fill="#ADA5D6" />
          <rect x="100.5" y="51" width="4" height="14" fill="#9289BD" />
          <rect x="12.5" y="13.008" width="114" height="15.546" fill="#CEC898" />
          <path
            d="m50.5 20.922c3.8333-0.3333 3 2.4918 13.5 1e-4 4.8649-1.1545 5.0276 2.7508 13-1e-4 5.5-1.8977 8.5-0.3321 12 0"
            stroke="#8F8A74"
            strokeLinecap="round"
          />
          <rect x="53.5" y="75" width="31.091" height="5.1818" rx="2.5909" fill="#8F8A74" />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
