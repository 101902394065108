import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type KnackApplication } from '@/types/apps';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { getLoaderApiUrl } from '@/utils/axiosConfig';

async function getApps(accountSlug: string) {
  const domain = getLoaderApiUrl();
  const { data } = await axios.get(`${domain}/v1/dashboard/${accountSlug}/users/apps`, {
    withCredentials: true
  });
  return data;
}

export function useAppsQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.apps.applications],
    queryFn: () => getApps(session.account.slug),
    enabled: !!session.account.slug,
    staleTime: 1000 * 30,
    refetchInterval: (query) =>
      query.state.data?.some(
        (app: KnackApplication) => app.isDeleting || app.isCopying || app.isDeletingAssets
      )
        ? 5000
        : false
  });
}
