/* eslint-disable react/no-unstable-nested-components */
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useToast } from '@knack/asterisk-react';
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table';

import { useInvoiceEmailMutation } from '@/hooks/api/mutations/useInvoiceEmailMutation';
import { useInvoicePdfMutation } from '@/hooks/api/mutations/useInvoicePdfMutation';
import { useApiPlanQuery } from '@/hooks/api/queries/useApiPlanQuery';
import { useInvoicesQuery } from '@/hooks/api/queries/useInvoicesQuery';
import { capitalize } from '@/utils/formatters';
import { Table } from '@/components/ui/Table';
import { InvoicePdfLayout } from './InvoicePdfLayout';
import { InvoicesSkeleton } from './InvoicesSkeleton';

interface InvoicesProps {
  billingEmail: string;
  billingCustomInfo: string;
}

interface InvoiceRowData {
  date: string;
  details: string;
  total: number;
  downloadAction?: () => void;
  emailAction?: () => void;
}

export function Invoices({ billingEmail, billingCustomInfo }: InvoicesProps) {
  const { data: invoicesRaw, isLoading } = useInvoicesQuery();
  const [t] = useTranslation();

  const { data: currentApiPlan } = useApiPlanQuery();
  const invoiceEmailMutation = useInvoiceEmailMutation();
  const invoicePdfMutation = useInvoicePdfMutation();

  const { presentToast } = useToast();

  const onGeneratePdf = (invoice: any) => {
    const invoiceStaticMarkup = renderToStaticMarkup(
      <InvoicePdfLayout
        invoice={invoice}
        billingCustomInfo={billingCustomInfo}
        currentApiPlan={currentApiPlan}
      />
    );

    invoicePdfMutation.mutate(
      { invoiceStaticMarkup, invoiceId: invoice.id },
      {
        onSuccess: (data) => {
          window.open(data.url, '_blank');
        },
        onError: () => {
          presentToast({
            title: t('errors.generic_error')
          });
        }
      }
    );
  };

  const onEmailInvoice = (invoice: any) => {
    invoiceEmailMutation.mutate(invoice, {
      onSuccess: () => {
        presentToast({
          title: t('components.billing.invoices.invoice_sent', {
            email: billingEmail
          })
        });
      },
      onError: () => {
        presentToast({
          title: t('errors.generic_error')
        });
      }
    });
  };

  const invoices: InvoiceRowData[] = invoicesRaw?.data.map((invoice: any) => {
    const invoiceTotal = Number(invoice.amount_due / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2
    });
    const invoiceDate = new Date(invoice.date * 1000).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });

    if (invoice.paid || invoice.forgiven) {
      // Get the invoice items from the invoice object in Stripe.
      // The location of the invoice items can live in different places depending on the Stripe schema version of the associated account.
      let invoiceItems;
      if (invoice.lines.invoiceitems?.length) {
        invoiceItems = invoice.lines.invoiceitems;
      } else if (invoice.lines.subscriptions?.length) {
        invoiceItems = invoice.lines.subscriptions;
      } else if (invoice.lines.data?.length) {
        invoiceItems = invoice.lines.data;
      }

      const invoiceMainLineItem = invoiceItems?.length
        ? invoiceItems[invoiceItems.length - 1]
        : undefined;

      let invoiceDetails;
      if (invoiceMainLineItem?.plan) {
        // Check if the invoice is for the API add-on plan
        if (currentApiPlan?.id === invoiceMainLineItem.plan.id) {
          invoiceDetails = `API - ${invoiceMainLineItem.quantity * 25}k/day Monthly Plan`;
        } else {
          const hasIntervalInTitle =
            invoiceMainLineItem.plan.name.toLowerCase().includes('yearly') ||
            invoiceMainLineItem.plan.name.toLowerCase().includes('monthly');

          invoiceDetails = hasIntervalInTitle
            ? `${invoiceMainLineItem.plan.name} Plan`
            : `${invoiceMainLineItem.plan.name} ${capitalize(
                invoiceMainLineItem.plan.interval
              )}ly Plan`;
        }
      } else if (invoiceMainLineItem?.description) {
        invoiceDetails = invoiceMainLineItem.description;
      }

      return {
        date: invoiceDate,
        details: invoiceDetails,
        total: invoiceTotal,
        downloadAction: () => onGeneratePdf(invoice),
        emailAction: () => onEmailInvoice(invoice)
      };
    }

    return {
      date: invoiceDate,
      details: 'PAST DUE',
      total: invoiceTotal
    };
  });

  const columnHelper = createColumnHelper<InvoiceRowData>();

  const columns = [
    columnHelper.accessor('date', {
      header: t('components.billing.invoices.invoice_date')
    }),
    columnHelper.accessor('details', {
      header: t('components.billing.invoices.invoice_detail')
    }),
    columnHelper.accessor('total', {
      header: () => (
        <span className="block text-right">{t('components.billing.invoices.total_charged')}</span>
      ),
      cell: (cellContext) => <span className="block text-right">${cellContext.getValue()}</span>
    }),
    columnHelper.display({
      id: 'actions',
      header: t('components.billing.invoices.invoice_actions'),
      cell: (props) => {
        const { downloadAction, emailAction } = props.row.original;
        return (
          downloadAction &&
          emailAction && (
            <div className="flex gap-6">
              <button
                type="button"
                className="text-emphasis underline"
                onClick={() => downloadAction()}
              >
                {t('components.billing.invoices.download_pdf')}
              </button>
              <button
                type="button"
                className="text-emphasis underline"
                onClick={() => emailAction()}
              >
                {t('components.billing.invoices.send_by_email')}
              </button>
            </div>
          )
        );
      }
    })
  ] as ColumnDef<InvoiceRowData>[];

  if (isLoading) {
    return <InvoicesSkeleton />;
  }

  return <Table<InvoiceRowData> columns={columns} data={invoices} />;
}
