import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

async function createTrialAccount() {
  const url = '/v1/dashboard/accounts/shared-builder-sign-up';

  const { data } = await axios.post(
    url,
    {},
    {
      withCredentials: true
    }
  );
  return data;
}

export function useCreateTrialAccountMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createTrialAccount,
    onSuccess: (data) => {
      const { session } = data;
      queryClient.setQueryData([queryKeys.auth.session], session);
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
      void queryClient.invalidateQueries({ queryKey: [queryKeys.billing.overview] });
    }
  });
}
