import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronLeft as LeftArrow, HiChevronRight as RightArrow } from 'react-icons/hi2';
import { Progress } from '@knack/asterisk-react';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { cn } from '@/utils/tailwind';
import { ChecklistsPopoverContent } from '@/components/checklists/ChecklistsPopoverContent';
import Portal from '@/components/ui/Portal';

export function ChecklistsNavButton({ className = '' }: { className?: string }) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const { totalCount, totalCompleted } = useChecklists();

  const progress = Math.round((totalCompleted / totalCount) * 100);

  return (
    <button
      ref={ref}
      type="button"
      className={cn(
        'flex flex-col gap-2 rounded-md bg-gradient-3 p-2 shadow-[0_4px_12px_0_rgba(0,0,0,0.08)] transition-opacity hover:opacity-80 active:ring-2 active:ring-brand-100',
        {
          'opacity-80 ring-2 ring-brand-100': isOpen
        },
        className
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex w-full flex-row items-center justify-between text-white">
        {t('components.checklists.nav_button.title')}
        {isOpen ? <LeftArrow /> : <RightArrow />}
      </div>

      <div className="flex w-full flex-col items-start gap-1">
        <div className="text-xs text-white">
          {t('components.checklists.nav_button.completion', {
            progress
          })}
        </div>
        <Progress
          value={progress}
          className="h-1.5 bg-white bg-opacity-40 [&>div]:rounded-full [&>div]:bg-white"
        />
      </div>
      {isOpen && (
        <Portal>
          <ChecklistsPopoverContent
            sideOffset={(ref.current?.offsetWidth ?? 0) + 32} // 32 is the button padding
            onClose={() => setIsOpen(false)}
          />
        </Portal>
      )}
    </button>
  );
}
