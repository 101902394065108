import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type ProductPlan } from '@/types/account';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getPlans(accountId: string) {
  const { data } = await axios.get<{ plans: ProductPlan[] }>(
    `/v1/accounts/${accountId}/billing/plans`,
    {
      withCredentials: true
    }
  );
  return data.plans;
}

export function usePlansQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.plans],
    queryFn: () => getPlans(session.account.id),
    staleTime: 1000 * 120
  });
}
