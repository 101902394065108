import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { type Account } from '@/types/account';
import { useAccountMutation } from '@/hooks/api/mutations/useAccountMutation';
import { zodEmailRegex } from '@/utils/zod';
import { ActiveCard } from '@/pages/settings/billing/overview/ActiveCard';
import { BillingContact } from '@/pages/settings/billing/overview/BillingContact';
import { PlanDetails } from '@/pages/settings/billing/overview/PlanDetails';
import { PlanUsage } from '@/pages/settings/billing/overview/PlanUsage';
import i18n from '@/i18n';

interface OverviewProps {
  accountData: Account;
  billingData: any;
  isSubscribed: boolean;
}

const billingInfoFormSchema = z.object({
  billingEmail: z
    .string()
    .trim()
    .min(1, i18n.t('components.auth.email_required'))
    .regex(zodEmailRegex, i18n.t('components.auth.email_invalid')),
  billingInfo: z
    .string()
    .trim()
    .min(1, i18n.t('components.billing.overview.billing_info_required'))
    .max(500, i18n.t('components.billing.overview.billing_info_exceeded'))
});

export type BillingInfoFormSchemaType = z.infer<typeof billingInfoFormSchema>;

export function Overview({ accountData, billingData, isSubscribed }: OverviewProps) {
  const mutation = useAccountMutation();
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors, isDirty }
  } = useForm<BillingInfoFormSchemaType>({
    resolver: zodResolver(billingInfoFormSchema)
  });

  const onSubmitHandler: SubmitHandler<BillingInfoFormSchemaType> = (data) => {
    if (!isDirty) {
      return;
    }

    mutation.mutate(
      {
        billing_email: {
          email: data.billingEmail
        },
        billing_info: data.billingInfo.trim()
      },
      {
        onSuccess: () => {
          resetForm(data);
          presentToast({
            title: t('components.billing.overview.billing_contact_updated_successfully')
          });
        },
        onError: () => {
          presentToast({
            title: t('errors.generic_error')
          });
        }
      }
    );
  };

  const cardTitleClasses = 'mb-6 text-xl font-medium text-emphasis';

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
      <div className="grid gap-6 lg:grid-cols-2">
        <PlanDetails
          billingData={billingData}
          isSubscribed={isSubscribed}
          trialDeadline={accountData?.betaDeadline}
          cardTitleClasses={cardTitleClasses}
          isHipaa={accountData?.isHipaa}
        />

        <PlanUsage accountData={accountData} cardTitleClasses={cardTitleClasses} />

        {isSubscribed && (
          <>
            <ActiveCard
              customer={billingData?.customer}
              billingAddress={accountData?.billing?.billing_address ?? undefined}
              cardTitleClasses={cardTitleClasses}
            />

            <BillingContact
              billingName={billingData?.customer?.name ?? billingData?.customer?.description ?? ''}
              billingEmail={billingData?.customer?.email}
              billingCustomInfo={accountData?.billing?.custom_info ?? undefined}
              cardTitleClasses={cardTitleClasses}
              errors={errors}
              register={register}
            />
          </>
        )}
      </div>

      {isSubscribed && (
        <Button className="mt-6" type="submit" disabled={!isDirty}>
          {t('actions.save_changes')}
        </Button>
      )}
    </form>
  );
}
