import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Card } from '@knack/asterisk-react';

import { type BillingAddress } from '@/types/account';
import { type StripePaymentCard } from '@/types/billing';
import { BILLING_PAGE_TABS } from '@/hooks/usePageTabs';
import { capitalize } from '@/utils/formatters';

interface ActiveCardProps {
  customer: any;
  billingAddress?: BillingAddress;
  cardTitleClasses?: string;
}

export function ActiveCard({ customer, billingAddress, cardTitleClasses }: ActiveCardProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const cardData = customer?.sources?.data;
  // Get the payment card from the customer object in Stripe.
  // The location of the card data can be in different places in the Stripe response data, depending on the Stripe schema version at which the associated account was created.
  let paymentCard: StripePaymentCard | undefined;
  if (customer?.paymentMethod?.card) {
    paymentCard = customer.paymentMethod.card;
  } else if (cardData?.length && cardData[0]?.card) {
    paymentCard = cardData[0].card;
  } else if (cardData?.length && cardData[0]?.brand) {
    [paymentCard] = cardData;
  } else if (customer?.cards?.length) {
    [paymentCard] = customer.cards;
  }

  const needsPaymentUpdate = useMemo(() => {
    const month = paymentCard?.exp_month;
    const year = paymentCard?.exp_year;

    if (!month || !year) {
      return false;
    }

    // Month is based on 0 starting index.  So December would be 11.
    // The exp_month is based on 1 starting index so December would be 12.
    // By setting day 0 Date creates a date of the last day of the previous month.
    // So (2023, 12, 0) becomes Dec 31 2023
    const now = new Date();
    const expDate = new Date(year, month, 0);

    return expDate < now;
  }, [paymentCard]);

  return (
    <Card className="flex flex-col p-6 sm:p-6" data-private>
      <div className={`${cardTitleClasses} flex items-center justify-between`}>
        <h2>{t('components.billing.overview.active_card')}</h2>
        {needsPaymentUpdate && (
          <Badge intent="destructive" className="text-xs normal-case">
            {t('components.billing.overview.needs_payment_update')}
          </Badge>
        )}
      </div>

      {paymentCard ? (
        <>
          <div className="mb-6" data-testid="plan-overview-card-ending">
            <p>
              {t('components.billing.overview.ending_in', {
                brand: capitalize(paymentCard.brand),
                last4: paymentCard.last4
              })}
            </p>
            <span className="text-xs">
              {t('components.billing.overview.expires', {
                expMonth: paymentCard.exp_month,
                expYear: paymentCard.exp_year
              })}
            </span>
          </div>
          {billingAddress && (
            <div className="mb-6" data-testid="plan-overview-billing-address">
              <p className="">{t('components.billing.overview.billing_address')}</p>
              <span className="text-xs">
                {billingAddress.street} <br />
                {billingAddress.street2 && (
                  <>
                    {billingAddress.street2} <br />
                  </>
                )}
                {billingAddress.city}, {billingAddress.state && `${billingAddress.state}, `}
                {billingAddress.zip}
                {billingAddress.country && `, ${billingAddress.country}`}
              </span>
            </div>
          )}
        </>
      ) : (
        <p> {t('components.billing.overview.no_active_card')}</p>
      )}

      <div className="mt-auto flex flex-wrap gap-2">
        <Button
          intent="secondary"
          size="sm"
          className="flex-1"
          onClick={() =>
            navigate({
              pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.Payment.path}`,
              search
            })
          }
          data-testid="plan-overview-upgrade-card-button"
        >
          {t('components.billing.overview.update_card')}
        </Button>
      </div>
    </Card>
  );
}
