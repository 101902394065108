import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useSession } from '@/hooks/useSession';

interface ForgotPasswordOptions {
  email: string;
}

async function postDisableTwoFA(token: string, userId: string) {
  const { data } = await axios.post<ForgotPasswordOptions>(
    `/v1/accounts/session/2fa/disable`,
    { user_id: userId, totp_token: token },
    { withCredentials: true }
  );
  return data;
}

export function useDisableTwoFAMutation() {
  const session = useSession();

  return useMutation({
    mutationFn: (token: string) => postDisableTwoFA(token, session.user.id)
  });
}
