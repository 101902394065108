import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowSmallRight as ArrowIcon } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { PageError } from '@/components/errors/PageError';

export function NotFound() {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const { search } = useLocation();

  return (
    <main className="full-page-wrapper">
      <PageError
        title="404"
        message={t('components.not_found.message')}
        button={
          <Button
            className="gap-1"
            onClick={() =>
              navigate({
                pathname: Route.Root,
                search
              })
            }
          >
            {t('components.not_found.button_text')}
            <ArrowIcon size={14} />
          </Button>
        }
      />
      <Helmet>
        <title lang={language}>{`${t('titles.not_found')} - ${t('titles.app_name')}`}</title>
      </Helmet>
    </main>
  );
}
