import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface ResetPasswordOptions {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

async function postResetPassword(
  { token, newPassword, confirmPassword }: ResetPasswordOptions,
  isSetPassword: boolean
) {
  let url = `/v1/dashboard/forgot-password/reset/${token}`;

  if (isSetPassword) {
    url = `/v1/dashboard/set-password/reset/${token}`;
  }

  const { data } = await axios.post(
    url,
    {
      password: newPassword,
      password_confirmation: confirmPassword,
      crumbtrail: {},
      url: ''
    },
    { withCredentials: true }
  );

  return data;
}

export function useResetPasswordMutation({ isSetPassword }: { isSetPassword: boolean }) {
  return useMutation({
    mutationFn: (data: ResetPasswordOptions) => postResetPassword(data, isSetPassword)
  });
}
