import React from 'react';

import { MobileNav } from '@/components/navigation/mobile-nav';
import { SideNav } from '@/components/navigation/sidenav';

export function PageLayout({ children }: { children: React.ReactNode }) {
  // TODO: https://knack.atlassian.net/browse/DASH-669
  // Swap .grid-wrapper with md:grid-cols-[14rem,1fr] when Logrocket fixes its bug
  return (
    <div className="grid-wrapper min-h-screen md:grid">
      <MobileNav />
      <SideNav />
      <main className="flex w-full max-w-[1000px] flex-col overflow-hidden p-4 md:p-12 2xl:max-w-[1400px]">
        {children}
      </main>
    </div>
  );
}
