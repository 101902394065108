import React from 'react';
import {
  HiXMark as CloseIcon,
  HiOutlineEllipsisVertical as EllipsisVerticalIcon
} from 'react-icons/hi2';
import { useDraggable } from '@dnd-kit/core';

type Props = {
  onClose: () => void;
};

export function ChecklistsPopoverActions({ onClose }: Props) {
  const { attributes, listeners, setActivatorNodeRef } = useDraggable({
    id: 'checklist-draggable'
  });

  return (
    <div className="absolute right-3 top-3 flex">
      <button
        type="button"
        className="flex"
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
      >
        <EllipsisVerticalIcon className="-mr-3" />
        <EllipsisVerticalIcon />
      </button>
      <CloseIcon onClick={onClose} className="cursor-pointer" />
    </div>
  );
}
