import { focusManager, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

export interface TaxFormObjectPayload {
  type: string;
  value: string;
}
export interface UpdateTaxIdPayload {
  taxes: TaxFormObjectPayload[];
}

async function updateTaxIDs(updateTaxIds: UpdateTaxIdPayload, accountId: string) {
  const { data } = await axios.post(`/v1/accounts/${accountId}/billing/tax_ids`, updateTaxIds, {
    withCredentials: true
  });

  return data;
}

export function useTaxIDsMutation() {
  const session = useSession();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateTaxIds: UpdateTaxIdPayload) =>
      updateTaxIDs(updateTaxIds, session.account.id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.billing.overview] });
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
      focusManager.setFocused(true);
    }
  });
}
