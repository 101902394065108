import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { type Account } from '@/types/account';
import { type User } from '@/types/user';
import { useSession } from '@/hooks/useSession';

async function update(queryType: string, accountId: string, userId: string) {
  const { data } = await axios.put<{ account: Account; user: User }>(
    `/v1/accounts/${accountId}/${queryType}/${userId}`,
    { id: userId },
    {
      withCredentials: true
    }
  );

  return data;
}

async function freezeAccount(accountId: string, userId: string) {
  return update('freeze', accountId, userId);
}

async function unfreezeAccount(accountId: string, userId: string) {
  return update('unfreeze', accountId, userId);
}

export function useFreezeAccountMutation() {
  const session = useSession();

  return useMutation({
    mutationFn: () => freezeAccount(session.account.id, session.user.id)
  });
}

export function useUnfreezeAccountMutation() {
  const session = useSession();

  return useMutation({
    mutationFn: () => unfreezeAccount(session.account.id, session.user.id)
  });
}
