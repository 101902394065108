import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, KnackLogo, Spinner, ThemeProviderContext } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { useForgotPasswordConfirm } from '@/hooks/api/queries/useForgotPasswordConfirm';
import { cn } from '@/utils/tailwind';
import { SetPasswordForm } from '@/pages/set-password/SetPasswordForm';

export type SetPasswordPageParams = {
  token: string;
};

export function SetPassword() {
  const { isDarkMode } = useContext(ThemeProviderContext);
  const [t, { language }] = useTranslation();
  const { search } = useLocation();

  const urlParams = useParams<SetPasswordPageParams>();
  const navigate = useNavigate();
  const { token } = urlParams;

  if (!token) {
    throw new Error('Set password form can only be called within a route with a token parameter');
  }

  const { isLoading, isError } = useForgotPasswordConfirm({ token }, { isSetPassword: true });

  if (isError) {
    navigate(
      {
        pathname: `/${Route.ForgotPassword}`,
        search
      },
      { state: { invalidLink: true } }
    );
  }

  return (
    <main
      className={cn('flex min-h-screen flex-col', {
        'bg-brand-gradient': !isDarkMode
      })}
    >
      <div
        className={cn('mx-auto pb-12 pt-10 text-brand sm:mx-0 sm:pl-15', {
          'text-white': isDarkMode
        })}
      >
        <KnackLogo />
      </div>
      <section className="container flex flex-auto items-center justify-center">
        <Card className="flex w-full max-w-[600px] flex-col items-center justify-center px-7 py-14 sm:px-14 sm:py-16">
          {!isLoading ? <SetPasswordForm /> : <Spinner />}
        </Card>
      </section>
      <Helmet>
        <title lang={language}>{`${t('components.set_password.title')} • ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </main>
  );
}
