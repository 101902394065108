import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner, Button, Dialog, useToast } from '@knack/asterisk-react';
import axios from 'axios';

import { useFreezeInfo } from '@/hooks/account/useFreezeInfo';
import {
  useFreezeAccountMutation,
  useUnfreezeAccountMutation
} from '@/hooks/api/mutations/useFreezeAccountMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useServerErrors } from '@/hooks/api/useServerErrors';
import { usePlans } from '@/hooks/usePlans';
import { getErrorMessage } from '@/utils/errors';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';

export function FreezeAccountModal({ disabled = false }: { disabled?: boolean }) {
  const [t] = useTranslation();
  const { getError } = useServerErrors();
  const { presentToast } = useToast();
  const { mutate: freezeAccount, isPending: isFreezing } = useFreezeAccountMutation();
  const { mutate: unfreezeAccount, isPending: isUnfreezing } = useUnfreezeAccountMutation();

  const { refetch, isLoading: isAccountLoading } = useAccountQuery();
  const { originalPlan } = usePlans();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { isFrozen } = useFreezeInfo();
  const primaryText = isFrozen
    ? t('components.account.freeze_modal.unfreeze_account')
    : t('components.account.freeze_account');
  const isTrial = originalPlan?.id === 'trial';
  const isLoading = isFreezing || isUnfreezing || isAccountLoading;
  const [errorMessage, setErrorMessage] = useState('');

  function handleFreezeClick() {
    if (isFrozen) {
      unfreezeAccount(undefined, {
        onSuccess: () => {
          presentToast({
            title: t('components.account.freeze_modal.unfreeze_success')
          });
          void refetch();
          closeButtonRef?.current?.click();
        },
        onError: (error) => {
          void refetch();
          const message = getErrorMessage(
            error,
            t('components.account.freeze_modal.unfreeze_error')
          );
          setErrorMessage(message);
        }
      });
    } else {
      freezeAccount(undefined, {
        onSuccess: () => {
          presentToast({
            title: t('components.account.freeze_modal.freeze_success')
          });
          void refetch();
          closeButtonRef?.current?.click();
        },
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            if (getError(error?.message)?.key === 'network_error') {
              // suppress error caused by stripe configuration that reults in 400 resp code and treat as success.
              presentToast({
                title: t('components.account.freeze_modal.freeze_success')
              });
              void refetch();
              closeButtonRef?.current?.click();
            }
          } else {
            const message = getErrorMessage(
              error,
              t('components.account.freeze_modal.freeze_error')
            );
            setErrorMessage(message);
          }
        }
      });
    }
  }

  const Footer = (
    <Dialog.Footer intent="borderless">
      <Dialog.Close
        asChild
        ref={closeButtonRef}
        data-testid="account-freeze-account-dialog-cancel-button"
      >
        <Button intent="minimal">{t('actions.cancel')}</Button>
      </Dialog.Close>
      <Button
        data-testid="account-freeze-account-dialog-confirm-freeze-button"
        isLoading={isLoading}
        onClick={() => handleFreezeClick()}
      >
        {primaryText}
      </Button>
    </Dialog.Footer>
  );

  return (
    <Dialog>
      <Dialog.Trigger asChild ref={closeButtonRef}>
        <Button
          data-testid="account-freeze-your-account-button"
          disabled={isTrial || disabled}
          aria-label={
            isTrial
              ? t('components.account.freeze_modal.cannot_freeze_trial')
              : t('components.account.freeze_account')
          }
          className="self-start"
        >
          {primaryText}
        </Button>
      </Dialog.Trigger>
      <DashboardDialogContent
        headerTitle={primaryText}
        footer={Footer}
        data-testid="account-freeze-account-dialog"
      >
        <div className="flex flex-col gap-6">
          {isFrozen ? (
            <div>
              <p>
                {t('components.account.freeze_modal.resume_info', {
                  name: originalPlan?.name,
                  cost: originalPlan?.price
                })}
              </p>
            </div>
          ) : (
            <>
              <p>{t('components.account.freeze_modal.info')}</p>
              <Banner intent="warning" className="text-md">
                {t('components.account.freeze_modal.warning')}
              </Banner>
            </>
          )}
          {errorMessage && (
            <Banner intent="destructive" icon={ExclamationIcon}>
              <Banner.Message>{errorMessage}</Banner.Message>
            </Banner>
          )}
        </div>
      </DashboardDialogContent>
    </Dialog>
  );
}
