import { useContext, useEffect, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, ThemeProviderContext, Toaster, Tooltip } from '@knack/asterisk-react';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { getStripeObject, getStripeOptions } from '@/utils/stripe';
import { ErrorFallback } from '@/components/errors/ErrorFallback';
import { ChecklistsProvider } from '@/contexts/ChecklistsContext';
import { PostSignUpProvider } from '@/contexts/PostSignUpContext';

if (import.meta.env.PUBLIC_LOGROCKET_APP_ID) {
  LogRocket.init(import.meta.env.PUBLIC_LOGROCKET_APP_ID, {
    rootHostname: import.meta.env.PUBLIC_LOGROCKET_ROOT_HOSTNAME ?? 'knack.com',
    dom: {
      baseHref: `${window.location.protocol}//${window.location.hostname}/`
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body?.includes('"password":')) {
          const params = JSON.parse(request.body);
          params.password = '********';
          request.body = JSON.stringify(params);
        }

        // otherwise log the request normally
        return request;
      }
    }
  });
  setupLogRocketReact(LogRocket);
}

const rollbarConfig = {
  accessToken: import.meta.env.PUBLIC_ROLLBAR_ACCESS_TOKEN,
  environment: 'production', // Use 'testenv' for testing
  code_version: APP_VERSION,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: APP_VERSION,

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};

function StripeProviderWrapper({ children }: { children: React.ReactNode }) {
  const { isDarkMode } = useContext(ThemeProviderContext);
  const stripeObject = useMemo(() => getStripeObject(), []);

  return (
    <StripeElementsProvider stripe={stripeObject} options={getStripeOptions(isDarkMode)}>
      {children}
    </StripeElementsProvider>
  );
}

export function AppProviders({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `%c Knack Dashboard (${APP_VERSION})`,
      'background: #982a86; color: #ffffff; font-size: 20px; padding:5px'
    );
    // eslint-disable-next-line no-console
    console.log(`%cLast modification: ${APP_LAST_MOD}`, 'font-size: 14px;');
  }, []);

  return (
    <FlagsmithProvider
      options={{
        environmentID: import.meta.env.PUBLIC_FLAGSMITH_ENV_ID
      }}
      flagsmith={flagsmith}
    >
      <HelmetProvider>
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary fallbackUI={ErrorFallback}>
            <ThemeProvider>
              <PostSignUpProvider>
                <ChecklistsProvider>
                  <Toaster />
                  <Tooltip.Provider>
                    <StripeProviderWrapper>{children}</StripeProviderWrapper>
                  </Tooltip.Provider>
                </ChecklistsProvider>
              </PostSignUpProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </HelmetProvider>
    </FlagsmithProvider>
  );
}
