import React, { useEffect, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';
import {
  HiBeaker as BeakerIcon,
  HiExclamationCircle as ExclamationIcon,
  HiArrowUpTray as ImportIcon
} from 'react-icons/hi2';
import { Banner, Button, Dialog } from '@knack/asterisk-react';

import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSession } from '@/hooks/useSession';
import { getErrorMessage } from '@/utils/errors';
import { cn } from '@/utils/tailwind';
import { AppCreateType, BUILDER_IMPORT_PATH } from '@/pages/apps/create-app/constants';

type CreateAppModalProps = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onOpenAiModal: () => void;
};

type Option = {
  id: AppCreateType;
  icon: React.ReactNode;
};

export function CreateAppModal({ open, setIsOpen, onOpenAiModal }: CreateAppModalProps) {
  const [t] = useTranslation();
  const [selectedOption, setSelectedOption] = useState<AppCreateType>();
  const { createApp } = useAppMutation();
  const shouldShowAi = import.meta.env.PUBLIC_ENABLE_AI_APP_CREATION !== 'false';
  const session = useSession();
  const [appCreationError, setAppCreationError] = useState('');

  const options = useMemo(() => {
    const output = [
      {
        id: AppCreateType.Scratch,
        icon: <BeakerIcon className="text-subtle" size={24} />
      },
      {
        id: AppCreateType.Import,
        icon: (
          <div className="text-subtle">
            <ImportIcon size={24} />
          </div>
        )
      }
    ];

    if (shouldShowAi) {
      // If we have AI enabled we use `splice` to add the AI button at the second position of the array.
      output.splice(1, 0, {
        id: AppCreateType.Ai,
        icon: <AiStarsIcon className="rotate-90 fill-[url(#svg-gradient-2)]" size={24} />
      });
    }

    return output;
  }, [shouldShowAi]);

  useEffect(() => {
    if (selectedOption === AppCreateType.Ai) {
      setSelectedOption(undefined);
      onOpenAiModal();
    }
  }, [onOpenAiModal, selectedOption]);

  function handleCreateApp() {
    setAppCreationError('');
    const data = {
      appDescription: '',
      appOrigin: selectedOption
    };

    createApp.mutate(data, {
      onSuccess: (response) => {
        setIsOpen(false);
        setSelectedOption(undefined);

        const builderDomain = import.meta.env.PUBLIC_BUILDER_URL;
        const builderImportPath = BUILDER_IMPORT_PATH;

        window.location.href = `${builderDomain}/${session.account.slug}/${response.application.slug}/${
          selectedOption === AppCreateType.Import ? builderImportPath : ''
        }`;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
      }
    });
  }

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <Dialog.Content
        className="flex flex-col bg-default"
        data-testid="starting-create-new-app-modal"
      >
        <Dialog.MainContent>
          <Dialog.Header className="mb-6 text-xl font-medium text-emphasis">
            {t('components.create_app.modal.title')}
          </Dialog.Header>
          {appCreationError && (
            <Banner
              intent="destructive"
              icon={ExclamationIcon}
              title={t('components.create_app.create_app_error_title')}
              className="mb-4"
              closeMode="text"
            >
              <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
            </Banner>
          )}
          <div className="flex flex-col gap-4 sm:flex-row">
            {options.map((option: Option) => (
              <button
                type="button"
                key={option.id}
                className={cn(
                  'flex-1 cursor-pointer select-none rounded-md bg-card p-4 text-left',
                  {
                    'bg-muted ring-1 ring-default':
                      selectedOption !== AppCreateType.Ai && selectedOption === option.id,
                    'opacity-30': createApp.isPending
                  }
                )}
                onClick={() =>
                  setSelectedOption((prev) =>
                    prev === option.id || createApp.isPending ? undefined : option.id
                  )
                }
              >
                <div className="flex flex-col gap-4">
                  {option.icon}
                  <div className="flex flex-col gap-1">
                    <div className="text-xs font-medium text-emphasis">
                      {t(`components.create_app.modal.${option.id}`)}
                    </div>
                    <div className="text-xs text-subtle">
                      {t(`components.create_app.modal.${option.id}_description`)}
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2 pt-2">
          <Button intent="minimal" disabled={createApp.isPending} onClick={() => setIsOpen(false)}>
            {t('actions.cancel')}
          </Button>
          <Button
            isLoading={createApp.isPending}
            onClick={() => handleCreateApp()}
            disabled={!selectedOption}
          >
            {t('components.create_app.create_new_app')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
