import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

interface ForgotPasswordConfirmOptions {
  token: string;
}

async function getForgotPasswordConfirm(
  { token }: ForgotPasswordConfirmOptions,
  isSetPassword: boolean
) {
  let url = `/v1/dashboard/forgot-password/confirm/${token}`;

  if (isSetPassword) {
    url = `/v1/dashboard/set-password/confirm/${token}`;
  }

  const { data } = await axios.get(url);
  return data;
}

export function useForgotPasswordConfirm(
  { token }: ForgotPasswordConfirmOptions,
  { isSetPassword }: { isSetPassword: boolean }
) {
  return useQuery({
    queryKey: [queryKeys.auth.forgotPasswordConfirm],
    queryFn: () => getForgotPasswordConfirm({ token }, isSetPassword),
    retry: false,
    refetchOnWindowFocus: false
  });
}
