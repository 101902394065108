import { useTranslation } from 'react-i18next';

type ErrorKey = keyof typeof serverErrors;

interface ServerErrors {
  key: ErrorKey;
  message: string;
}

const serverErrors = {
  wrong_password: 'Current password is incorrect.',
  common_word: 'Password must not be a common word.',
  invalid_code: 'Incorrect code; please try again.',
  network_error: 'Network Error'
};

export function useServerErrors() {
  const [t] = useTranslation();

  const getError = (serverRespError: string): ServerErrors | undefined => {
    const key = Object.keys(serverErrors).find(
      (errorKey) => serverErrors[errorKey as ErrorKey] === serverRespError
    ) as ErrorKey;

    if (key === undefined) {
      return undefined;
    }

    const message = t(`server_errors.${key}`);

    return {
      key,
      message
    };
  };

  return { getError };
}
