import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export interface Coupon {
  id: string;
  object: string;
  amount_off: number | null;
  created: number;
  currency: string | null;
  duration: string;
  duration_in_months: number;
  livemode: boolean;
  max_redemptions: number | null;
  metadata: object;
  name: string;
  percent_off: number | null;
  redeem_by: string | null;
  times_redeemed: number;
  valid: boolean;
}

export interface ValidateCouponResponse {
  success: boolean;
  coupon?: Coupon;
  errorKey?: string;
}

export interface ValidateCouponPayload {
  promoCode: string;
  planId: string;
}

export async function validateCoupon(promoCode: string, planId: string) {
  const { data } = await axios.post<ValidateCouponResponse>(
    '/stripe/coupon/validate',
    { promoCode, planId },
    { withCredentials: true }
  );
  return data;
}

export function useValidateCouponMutation() {
  return useMutation({
    mutationFn: ({ promoCode, planId }: ValidateCouponPayload) => validateCoupon(promoCode, planId)
  });
}
