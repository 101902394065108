import { useContext } from 'react';
import { ThemeProviderContext } from '@knack/asterisk-react';
import { CardElement } from '@stripe/react-stripe-js';
import { type StripeCardElementChangeEvent } from '@stripe/stripe-js';

interface InputStripeCardProps {
  onChange?: (event: StripeCardElementChangeEvent) => void;
}

export function InputStripeCard({ onChange }: InputStripeCardProps) {
  const { isDarkMode } = useContext(ThemeProviderContext);
  // Customization for the Stripe CardElement is very limited. We have to use hardcoded colors and there are no options to disable things like animations.
  // The alternative is to use Stripe's PaymentElement instead, which is more customizable. The fields also also separated, which is better for accessibility.
  // Will keep it like this to match the mocks, but we should consider using PaymentElement instead.
  // More info here: https://stripe.com/docs/payments/payment-card-element-comparison
  const cardOptions = {
    classes: {
      // These classes are for the wrapper of the element. The actual input element can't be customized, which means the actual input height
      // will be slighly off, so we have to use precise pixel values so it looks as close as possible to our other inputs.
      // Also, Stripe doesn't allow dots in class names, so classes like px-2.5 won't work.
      base: 'w-full bg-input rounded-md border border-default hover:border-emphasis px-[10px] py-[9px] transform:none',
      focus: 'outline outline-2 outline-offset-2 outline-emphasis',
      invalid: 'border-destructive hover:!border-destructive !outline-destructive'
    },
    style: {
      base: {
        fontFamily: 'Inter, Segoe UI, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        color: isDarkMode ? 'white' : '#1B181B'
      },
      invalid: {
        color: '#F44336'
      }
    },
    hidePostalCode: true
  };

  return <CardElement options={cardOptions} onChange={onChange} data-private />;
}
