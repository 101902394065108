import { useEffect, useState } from 'react';

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>;

// A wrapper for "JSON.parse()" to support "undefined" values
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    // eslint-disable-next-line no-console
    console.log('parsing error on', { value });
    return undefined;
  }
}

export const canUseLocalStorage = () =>
  typeof localStorage !== 'undefined' || localStorage !== null;

export function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  const [value, setValue] = useState<T>(() => {
    if (canUseLocalStorage()) {
      const storedValue = localStorage.getItem(key);
      if (storedValue === null) {
        return initialValue;
      }
      // If storedValue can't be parsed, return the raw value
      return parseJSON<T>(storedValue) || (storedValue as T);
    }
    // return initialValue if localStorage is undefined due to:
    // 1. User-disabled storage
    // 2. Browser's incognito mode
    // 3. Storage limit exceeded
    // 4. Unsupported browser (e.g., IE7)
    // 5. Same-origin policy restrictions
    return initialValue;
  });

  useEffect(() => {
    if (canUseLocalStorage()) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
}
