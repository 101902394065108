import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { type AxiosProgressEvent } from 'axios';

import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';

type SetAvatarSuccessResponse = {
  success: boolean;
  avatarUri: string;
};

type DeleteAvatarSuccessResponse = {
  success: boolean;
};

async function updateProfileAvatar(
  avatarFile: File,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
) {
  const formData = new FormData();
  formData.append('avatarUri', avatarFile);

  const { data } = await axios.post<SetAvatarSuccessResponse>(
    `/v1/dashboard/profile/set-avatar`,
    formData,
    {
      withCredentials: true,
      onUploadProgress
    }
  );

  return data;
}

async function deleteProfileAvatar() {
  const { data } = await axios.delete<DeleteAvatarSuccessResponse>(
    `/v1/dashboard/profile/delete-avatar`,
    {
      withCredentials: true
    }
  );

  return data;
}

export function useProfileAvatarMutation() {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadAvatarMutation = useMutation({
    mutationFn: (avatarFile: File) =>
      updateProfileAvatar(avatarFile, (event) => {
        if (event.progress) setUploadProgress(event.progress * 100);
      }),
    onSuccess: (data) => {
      queryClient.setQueryData<Session>([queryKeys.auth.session], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            user: { ...oldData.user, avatarUri: data.avatarUri }
          };
        }
        return oldData;
      });
    }
  });

  const deleteAvatarMutation = useMutation({
    mutationFn: deleteProfileAvatar,
    onSuccess: () => {
      queryClient.setQueryData<Session>([queryKeys.auth.session], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            user: { ...oldData.user, avatarUri: '' }
          };
        }
        return oldData;
      });
    }
  });

  return {
    uploadAvatar: uploadAvatarMutation,
    uploadAvatarProgress: uploadProgress,
    deleteAvatar: deleteAvatarMutation
  };
}
