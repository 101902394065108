import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/tailwind';

type Props = {
  section?: string;
  steps: {
    total: number;
    completed: number;
  };
};

export function ChecklistsProgress({ section, steps }: Props) {
  const [t] = useTranslation();
  const progress = Math.round((steps.completed / steps.total) * 100);

  return (
    <div
      className={cn('flex flex-row gap-2 py-2', {
        'gap-3': !!section,
        'items-end': !section
      })}
    >
      {/* Can't use the Progress component from Asterisk due to the need for an opacity gradient background */}
      <div className="relative my-auto h-2 flex-1 overflow-hidden rounded-full">
        <div className="absolute inset-0 bg-gradient-3 opacity-10" />
        <div
          className="absolute inset-0 rounded-full bg-gradient-2 opacity-100 transition-all"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="flex items-center justify-between">
        {section && (
          <div className="text-sm font-semibold">
            {t(`components.checklists.sections.${section}`)}
          </div>
        )}
        <div className="text-xs text-subtle">
          {section
            ? t('components.checklists.completion_steps', { ...steps })
            : t('components.checklists.completion', { progress })}
        </div>
      </div>
    </div>
  );
}
