import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
  AsteriskLogo,
  Button,
  Input,
  InputPassword,
  ThemeProviderContext
} from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { FormErrorBanner } from '@/components/forms';
import { FormControl } from '@/components/ui/FormControl';
import { useResetPasswordForm } from '@/pages/reset-password/useResetPasswordForm';
import { type SetPasswordPageParams } from '@/pages/set-password';

export function SetPasswordForm() {
  const [t] = useTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userEmailBase64 = params.get('email') || '';
  const userEmail = atob(userEmailBase64);
  const urlParams = useParams<SetPasswordPageParams>();
  const { token } = urlParams;

  if (!token) {
    throw new Error('Set password form can only be called within a route with a token parameter');
  }
  const { isDarkMode } = useContext(ThemeProviderContext);

  const { register, handleSubmit, errors, isSubmitting, onSubmitHandler, setValue } =
    useResetPasswordForm({ token, isSetPassword: true });

  return (
    <>
      <FormErrorBanner errors={errors} />
      <div
        className={cn(
          'mb-12 hidden h-14 w-16 text-white sm:block md:text-brand',
          isDarkMode && 'md:text-white'
        )}
      >
        <AsteriskLogo />
      </div>
      <h1 className="mb-4 self-start text-3xl sm:mb-6 sm:self-center sm:text-4xl">
        {t('components.set_password.title')}
      </h1>
      <p className="mb-10">{t('components.set_password.description')}</p>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full">
        <FormControl>
          <FormControl.Label htmlFor="email">{t('attributes.user.email')}</FormControl.Label>
          <Input
            title={t('attributes.user.email')}
            aria-label={t('attributes.user.email')}
            id="email"
            name="email"
            type="email"
            value={userEmail}
            disabled
          />
        </FormControl>
        <FormControl className="mt-4">
          <FormControl.Label htmlFor="password" intent={errors.newPassword && 'destructive'}>
            {t('attributes.user.password')}
          </FormControl.Label>
          <InputPassword
            data-private
            title={t('components.auth.new_password_title')}
            aria-label={t('components.auth.new_password_title')}
            id="newPassword"
            disabled={isSubmitting}
            placeholder={t('components.auth.new_password_placeholder')}
            {...register('newPassword', {
              onChange: (value) => {
                // In order to reuse the reset password form, we set the confirm password manually here
                setValue('confirmPassword', value.target.value, {
                  shouldTouch: true
                });
              }
            })}
          />
          {errors.newPassword && (
            <FormControl.Message type="error">{errors.newPassword.message}</FormControl.Message>
          )}
        </FormControl>
        <Button
          aria-label={t('components.account.create_account')}
          type="submit"
          isLoading={isSubmitting}
          className="mt-8 w-full"
        >
          {t('components.account.create_account')}
        </Button>
      </form>
    </>
  );
}
