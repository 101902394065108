import { ProfileAvatar } from '@/components/ui/ProfileAvatar';

interface ProfileCardProps {
  email: string;
  firstName?: string;
  lastName?: string;
  avatarUri?: string;
  size?: 'sm' | 'lg' | 'default' | null;
}

export function ProfileCard({
  email,
  firstName,
  lastName,
  avatarUri,
  size = 'default'
}: ProfileCardProps) {
  return (
    <div className="flex max-w-full items-center">
      <ProfileAvatar email={email} firstName={firstName} avatarUri={avatarUri} size={size} />
      <div className="ml-2 overflow-hidden">
        <div className="overflow-hidden text-ellipsis whitespace-nowrap text-left text-emphasis">
          {firstName} {lastName}
        </div>
        <div className="overflow-hidden text-ellipsis whitespace-nowrap text-xs">{email}</div>
      </div>
    </div>
  );
}
