import { useMemo, useState, type ChangeEvent, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog as AsteriskDialog, Button, Checkbox, Input, Label } from '@knack/asterisk-react';

import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { options } from './options';
import { type HearAboutUsOption } from './types';

type Props = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

type OptionData = {
  checked: boolean;
  inputValue?: string;
};

export function Dialog({ open, setIsOpen }: Props) {
  const [t] = useTranslation();
  const [optionData, setOptionData] = useState<{ [K in HearAboutUsOption['id']]: OptionData }>(
    Object.fromEntries(options.map((o) => [o.id, { checked: false }]))
  );
  const [isLoading, setIsLoading] = useState(false);

  const canSubmit = useMemo(
    () => Object.values(optionData).find((option) => option.checked) !== undefined,
    [optionData]
  );

  const onSubmit = () => {
    setIsLoading(true);

    const eventPayload = options.reduce((accumulator, { id, hasInput }) => {
      const optionKey = `option_${id}`;
      const currentOptionData = optionData[id];

      if (currentOptionData && currentOptionData.checked) {
        accumulator[optionKey] = currentOptionData.checked;

        if (hasInput) {
          accumulator[`${optionKey}_message`] = currentOptionData.inputValue;
        }
      }

      return accumulator;
    }, {});

    // If we're here we should already have analytics checked with shouldSendAnalytics
    window.analytics.track('Onboarding Hear About Us Sent', eventPayload);

    setIsLoading(false);
    setIsOpen(false);
  };

  const dialogFooter = () => (
    <AsteriskDialog.Footer intent="borderless" className="gap-2">
      <AsteriskDialog.Close asChild>
        <Button intent="minimal" disabled={isLoading} data-testid="hear-about-us-close-button">
          {t('actions.close')}
        </Button>
      </AsteriskDialog.Close>
      <Button
        type="submit"
        isLoading={isLoading}
        onClick={onSubmit}
        disabled={!canSubmit}
        data-testid="hear-about-us-submit-button"
      >
        {t('actions.submit')}
      </Button>
    </AsteriskDialog.Footer>
  );

  const onOptionChecked = (optionId: HearAboutUsOption['id'], e: boolean | 'indeterminate') => {
    if (e === 'indeterminate') return;

    setOptionData((old) => ({
      ...old,
      [optionId]: {
        checked: !old[optionId].checked
      }
    }));
  };

  const onOptionInputChange = (
    optionId: HearAboutUsOption['id'],
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (!optionData[optionId].checked) return;

    setOptionData((old) => ({
      ...old,
      [optionId]: {
        ...old[optionId],
        inputValue: e.target.value
      }
    }));
  };

  return (
    <AsteriskDialog open={open} onOpenChange={setIsOpen}>
      <DashboardDialogContent
        width="sm"
        headerTitle={t('components.billing.hear_about_us.title')}
        footer={dialogFooter()}
      >
        <div className="flex flex-col gap-3">
          <p className="text-sm">{t('components.billing.hear_about_us.subtitle')}</p>

          {options.map(({ id, hasInput }) => (
            <div key={id} className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-2">
                <Checkbox
                  id={`hear-about-us-option-${id}`}
                  checked={optionData[id].checked}
                  onCheckedChange={(e) => onOptionChecked(id, e)}
                  data-testid={`hear-about-us-option-${id}`}
                />
                <Label htmlFor={`hear-about-us-option-${id}`} className="text-sm text-emphasis">
                  {t(`components.billing.hear_about_us.options.${id}`)}
                </Label>
              </div>

              {hasInput && optionData[id].checked && (
                <div className="flex flex-col gap-2 px-6">
                  <Label htmlFor={`hear-about-us-input-${id}`} className="text-sm">
                    {t(`components.billing.hear_about_us.input_label`)}
                  </Label>
                  <Input
                    size="sm"
                    className="text-sm"
                    id={`hear-about-us-input-${id}`}
                    placeholder={t(`components.billing.hear_about_us.options.${id}_placeholder`)}
                    onChange={(e) => onOptionInputChange(id, e)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </DashboardDialogContent>
    </AsteriskDialog>
  );
}
