import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner } from '@knack/asterisk-react';

import type { KnackApplication } from '@/types/apps';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';
import { usePlans } from '@/hooks/usePlans';
import { cn } from '@/utils/tailwind';
import { AppsTable } from '@/pages/apps/apps-table';
import type { DisablingRestrictions } from '@/pages/apps/AppsPage';
import { APPS_SKELETON_NUMBER_OF_ROWS } from '@/pages/apps/create-app/constants';
import { GettingStarted } from '@/pages/apps/create-app/GettingStarted';
import { MyAppsSkeleton } from '@/pages/apps/MyAppsSkeleton';
import { CreateNewApp } from './create-app/CreateNewApp';

type MyAppsNewProps = {
  restrictions?: DisablingRestrictions;
  onOpenRestrictionModal: () => void;
  search: string;
};

export function MyApps({ restrictions, search, onOpenRestrictionModal }: MyAppsNewProps) {
  const [t] = useTranslation();
  const { data: applications, isLoading } = useAppsQuery();
  const { originalPlan } = usePlans();
  const isTrial = originalPlan?.id === 'trial';
  const cardsHidden =
    (!isTrial && applications?.length > 1) || applications?.length > 3 || isLoading;
  const [appCreationError, setAppCreationError] = useState('');

  const userApps = useMemo(() => {
    if (!isLoading || applications) {
      return search
        ? applications.filter((app: KnackApplication) =>
            app.name.toLowerCase().includes(search.toLowerCase())
          )
        : applications;
    }
    return [];
  }, [search, applications, isLoading]);

  return (
    <div className="flex h-full flex-col gap-12">
      {(!cardsHidden || appCreationError) && (
        <div>
          {appCreationError && (
            <Banner
              intent="destructive"
              icon={ExclamationIcon}
              title={t('components.create_app.create_app_error_title')}
              closeMode="text"
              className="mb-4"
            >
              <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
            </Banner>
          )}

          <CreateNewApp
            onOpenRestrictionModal={onOpenRestrictionModal}
            isDisabled={restrictions?.createApp}
            isDismissible={!isTrial && applications?.length > 0}
            isHidden={cardsHidden}
            setAppCreationError={setAppCreationError}
          />
        </div>
      )}

      <div className="flex-1">
        {isLoading ? (
          <MyAppsSkeleton rows={cardsHidden ? APPS_SKELETON_NUMBER_OF_ROWS : 3} />
        ) : (
          <div className={cn('flex flex-col gap-4', userApps?.length === 0 && 'hidden')}>
            <div className={cn('pl-4 text-xl font-medium text-emphasis', cardsHidden && 'hidden')}>
              <h3>{t('components.apps.my_apps')}</h3>
            </div>
            <div className={cn(cardsHidden && '-ml-9', !cardsHidden && '-ml-6')}>
              <AppsTable apps={userApps} />
            </div>
          </div>
        )}

        {userApps?.length === 0 && search && <p>{t('components.apps.no_apps_search')}</p>}
      </div>
      <GettingStarted isHidden={cardsHidden} isDismissible={applications?.length > 0} />
    </div>
  );
}
