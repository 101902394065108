import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiArrowRight as ArrowRightIcon,
  HiCheckCircle as CheckCircleIcon,
  HiArrowsPointingOut as FullScreenIcon,
  HiLockClosed as LockIcon,
  HiPlayCircle as PlayIcon,
  HiXMark as XIcon
} from 'react-icons/hi2';
import { MdOutlineCircle } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ThemeProviderContext, useToast } from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';
import { useCompleteChecklistStepMutation } from '@/hooks/api/mutations/useCompleteChecklistStepMutation';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';
import { useSession } from '@/hooks/useSession';
import { cn } from '@/utils/tailwind';
import { SelectAppChecklistModal } from '@/components/checklists/SelectAppChecklistModal';
import { ChecklistType, type ChecklistStep } from '@/components/checklists/types';
import { useChecklistsContext } from '@/contexts/ChecklistsContext';

export function ChecklistsStep({ step, isLocked }: { step: ChecklistStep; isLocked: boolean }) {
  const [t] = useTranslation();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const [shouldShowVideo, setShouldShowVideo] = useState(false);
  const completeChecklistStep = useCompleteChecklistStepMutation();
  const navigate = useNavigate();
  const { setHighlightedFeatures } = useChecklistsContext();
  const { presentToast } = useToast();
  const { data: appsData } = useAppsQuery();
  const [shouldShowAppSelectorModal, setShouldShowAppSelectorModal] = useState(false);
  const { search } = useLocation();
  const session = useSession();

  const isCompleted = !!step.completed_at;

  const actionComponent = useMemo(() => {
    if (
      step.definition.type === ChecklistType.InProduct ||
      step.definition.type === ChecklistType.Documentation
    ) {
      return <ArrowRightIcon className=" fill-[url(#svg-gradient-1)]" size={16} />;
    }

    if (step.definition.type === 'video') {
      return <PlayIcon className="fill-[url(#svg-gradient-1)]" size={16} />;
    }

    return null;
  }, [step.definition.type]);

  const onClick = () => {
    if (step.definition.type === ChecklistType.Video) {
      setShouldShowVideo(!shouldShowVideo);
    } else if (step.definition.type === ChecklistType.Documentation) {
      completeChecklistStep.mutate(step.definition.id);

      window.open(step.definition.extra.url!, '_blank');
    } else if (step.definition.type === ChecklistType.InProduct) {
      const [area, url, highlight] = step.definition.extra.link!.split('::');

      if (area === 'dashboard') {
        if (highlight) {
          setHighlightedFeatures(highlight.split(','));
        }

        navigate(`/${url}`);
      } else if (area === 'builder') {
        if (appsData.length === 0) {
          presentToast({
            title: t('components.checklists.errors.app_needed'),
            intent: 'destructive'
          });
        } else if (appsData.length > 1) {
          setShouldShowAppSelectorModal(true);
        } else {
          const app = appsData[0] as KnackApplication;

          const builderUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${
            session.account.slug
          }/${app.slug}`;

          const params = new URLSearchParams(search);
          params.append('checklist_step', step.definition.id);

          window.location.href = `${builderUrl}?${params.toString()}`;
        }
      }
    }
  };

  const onOpenVideo = async () => {
    await completeChecklistStep.mutateAsync(step.definition.id);
  };

  const stepIcon = useMemo(() => {
    if (isCompleted) {
      return <CheckCircleIcon size={20} className="fill-[url(#svg-gradient-2)]" />;
    }
    if (isLocked) {
      return <LockIcon className="text-muted" size={16} />;
    }
    return <MdOutlineCircle className="fill-[url(#svg-gradient-1)]" size={18} />;
  }, [isCompleted, isLocked]);

  return (
    <>
      <button
        type="button"
        disabled={isCompleted || isLocked}
        className={cn('group flex gap-3 rounded p-2 text-start transition-colors', {
          'items-center': !step.definition.description,
          'bg-default hover:bg-muted': !isCompleted && !isLocked,
          'bg-overlay': isDarkMode && !isLocked && !isCompleted
        })}
        onClick={onClick}
        data-step-id={step.definition.id}
      >
        {stepIcon}
        <div className="flex flex-1 flex-col gap-1">
          <div
            className={cn('text-sm text-emphasis', {
              'text-brand-500': isCompleted,
              'text-opacity-50': isCompleted && !isDarkMode,
              'text-brand-300': isCompleted && isDarkMode,
              'text-subtle': isLocked
            })}
          >
            {t(`components.checklists.steps.${step.definition.id}`)}
          </div>
          {(step.definition.description || isLocked) && !isCompleted && (
            <div
              className={cn('text-xs text-subtle', {
                'text-muted': isLocked
              })}
            >
              {isLocked
                ? t(`components.checklists.steps.${step.definition.blocked_by}_locked`)
                : t(`components.checklists.steps.${step.definition.id}_description`)}
            </div>
          )}
        </div>
        <div
          className={cn('self-center rounded p-1 transition-colors', {
            'group-hover:bg-subtle': !isCompleted && !isLocked && actionComponent,
            'opacity-50': isLocked
          })}
        >
          {isCompleted ? (
            <Button intent="link" size="xs" className="text-xs font-normal" asChild>
              <a tabIndex={-1} href={step.definition.article_url} target="_blank" rel="noreferrer">
                {t('components.checklists.step_learn_more')}
              </a>
            </Button>
          ) : (
            actionComponent
          )}
        </div>
      </button>

      {shouldShowVideo && (
        <button
          type="button"
          aria-label={t('components.checklists.open_video_label')}
          onClick={onOpenVideo}
          className="relative flex min-h-[220px] cursor-pointer items-center justify-center rounded bg-muted p-3 transition-colors hover:bg-subtle"
        >
          <div className="absolute right-2 top-2 flex gap-1 text-default">
            <FullScreenIcon />
            <XIcon
              onClick={(e) => {
                e.stopPropagation();

                setShouldShowVideo(false);
              }}
            />
          </div>
          <PlayIcon size={44} className="fill-[url(#svg-gradient-1)]" />
        </button>
      )}

      {shouldShowAppSelectorModal && (
        <SelectAppChecklistModal
          stepId={step.definition.id}
          open={shouldShowAppSelectorModal}
          setIsOpen={setShouldShowAppSelectorModal}
        />
      )}
    </>
  );
}
