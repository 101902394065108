import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type Coupon } from '@/hooks/api/mutations/useValidateCouponMutation';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

interface GetCouponFromSubscriptionResponse {
  coupon: Coupon | null;
}

async function getExistingCoupon(accountId: string) {
  const { data } = await axios.get<GetCouponFromSubscriptionResponse>(
    `/v1/accounts/${accountId}/billing/coupon/`,
    {
      withCredentials: true
    }
  );
  return data;
}

export function useExistingCouponQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.billing.coupon],
    queryFn: () => getExistingCoupon(session.account.id)
  });
}
