import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@knack/asterisk-react';

import { type ProductPlan } from '@/types/account';
import { PLAN_FREQUENCY } from '@/types/plans';

interface SelectPlanButtonProps {
  knackPlan: ProductPlan;
  currentPlan: ProductPlan;
  onPlanSelect: (plan: ProductPlan) => void;
  isDowngradeAllowed?: boolean;
}

export function SelectPlanButton({
  knackPlan,
  currentPlan,
  isDowngradeAllowed = true,
  onPlanSelect
}: SelectPlanButtonProps) {
  const [t] = useTranslation();
  const commonProps: Partial<React.ComponentProps<typeof Button>> = {
    className: 'mt-auto',
    intent: undefined,
    onClick: () => onPlanSelect(knackPlan)
  } as const;

  if (currentPlan.id === 'trial') {
    return (
      <Button {...commonProps} data-testid="select-plan-button">
        {t('actions.upgrade')}
      </Button>
    );
  }

  // Same plan
  // Note: The IDs for the plans with the new pricing (e.g.: 'starter_230901') are different than the IDs for the legacy plans (e.g.: 'starter_141121'),
  // so this is a way to check if the two plans are the "same" (e.g.: 'starter_141121' is equivalent to 'starter_230901')
  if (currentPlan.id.replace('141121', '230901') === knackPlan.id) {
    return (
      <Button {...commonProps} disabled intent="secondary" data-testid="select-plan-button">
        {t('components.billing.overview.current_plan')}
      </Button>
    );
  }

  // Same plan level, but current plan is monthly and we're showing yearly
  if (
    knackPlan.level === currentPlan.level &&
    knackPlan.frequency === PLAN_FREQUENCY.Yearly &&
    !currentPlan.frequency
  ) {
    return (
      <Button data-testid="select-plan-button" {...commonProps}>
        {t('components.billing.plans.switch_to_yearly')}
      </Button>
    );
  }

  // Same plan level, but current plan is yearly and we're showing monthly
  if (
    knackPlan.level === currentPlan.level &&
    !knackPlan.frequency &&
    currentPlan.frequency === PLAN_FREQUENCY.Yearly
  ) {
    return (
      <Button data-testid="select-plan-button" {...commonProps}>
        {t('components.billing.plans.switch_to_monthly')}
      </Button>
    );
  }

  // Plan is higher level so upgrade is allowed
  if (knackPlan.level > currentPlan.level) {
    return (
      <Button data-testid="select-plan-button" {...commonProps}>
        {t('actions.upgrade')}
      </Button>
    );
  }

  // Plan is lower level so downgrade is allowed
  if (knackPlan.level < currentPlan.level) {
    // Disable downgrade button if the user is on an Enterprise plan or if the user is on a HIPAA plan
    if (!isDowngradeAllowed) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <div>
              <Button
                data-testid="select-plan-button"
                {...commonProps}
                disabled
                className="w-full"
                intent="secondary"
              >
                {t('actions.downgrade')}
              </Button>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Content>{t('components.billing.plans.contact_sales_tooltip')}</Tooltip.Content>
        </Tooltip>
      );
    }

    return (
      <Button data-testid="select-plan-button" {...commonProps} intent="secondary">
        {t('actions.downgrade')}
      </Button>
    );
  }

  return (
    <Button data-testid="select-plan-button" {...commonProps}>
      {t('actions.select')}
    </Button>
  );
}
