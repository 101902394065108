import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type Builder } from '@/types/apps';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getBuilders(accountId: string, appId: string) {
  const { data } = await axios.get<{ builders: Builder[] }>(
    `/v1/accounts/${accountId}/applications/${appId}/builders`,
    {
      withCredentials: true
    }
  );
  return data.builders;
}

export function useBuildersQuery(appId: string) {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.apps.builders],
    queryFn: () => getBuilders(session.account.id, appId)
  });
}
