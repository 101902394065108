import { useTranslation } from 'react-i18next';

import { type SharedApplication } from '@/types/apps';
import { useSharedAppsQuery } from '@/hooks/api/queries/useSharedAppsQuery';
import { SharedAppsTable } from '@/pages/apps/apps-table/SharedAppsTable';
import { SharedAppsSkeleton } from '@/pages/apps/SharedAppsSkeleton';

interface SharedAppsProps {
  search: string;
}

export function SharedApps({ search }: SharedAppsProps) {
  const [t] = useTranslation();
  const { data, isLoading } = useSharedAppsQuery();

  const sharedApps: SharedApplication[] = search
    ? (data || [])
        .map(({ applications, ...rest }: SharedApplication) => ({
          ...rest,
          applications: applications.filter((app) =>
            app.name.toLowerCase().includes(search.toLowerCase())
          )
        }))
        .filter(({ applications }: SharedApplication) => applications.length > 0)
    : data || [];

  if (isLoading) {
    return <SharedAppsSkeleton />;
  }

  return (
    <div>
      <SharedAppsTable apps={sharedApps} />
      {sharedApps?.length === 0 && search && <p>{t('components.apps.no_apps_search')}</p>}
      {sharedApps?.length === 0 && !search && <p>{t('components.apps.no_shared_apps')}</p>}
    </div>
  );
}
