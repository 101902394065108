export const PLAN_TYPE = {
  Basic: 'basic',
  Enterprise: 'enterprise'
} as const;

export const PLAN_FREQUENCY = {
  Monthly: 'monthly',
  Yearly: 'yearly'
} as const;

export const TAX_EXEMPTION_STATUS = {
  None: 'none',
  Exempt: 'exempt'
} as const;

export type PlanType = (typeof PLAN_TYPE)[keyof typeof PLAN_TYPE];

export type PlanFrequency = (typeof PLAN_FREQUENCY)[keyof typeof PLAN_FREQUENCY];

export type TaxExemptionStatus = (typeof TAX_EXEMPTION_STATUS)[keyof typeof TAX_EXEMPTION_STATUS];
