import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface CrispVerifyPayload {
  email: string;
}

interface VerifyEmailResponse {
  email: string;
  signature: string;
}

const verifyEmail = async (email: string) => {
  const { data } = await axios.post<VerifyEmailResponse>('/v1/crisp-auth/identify', { email });
  return data.signature;
};

export function useCrispMutation() {
  return useMutation({
    mutationFn: ({ email }: CrispVerifyPayload) => verifyEmail(email),
    onSuccess(signature: string) {
      return signature;
    }
  });
}
