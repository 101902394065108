import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as CancelIcon, HiTrash as RemoveIcon } from 'react-icons/hi2';
import { Button, Spinner, Tooltip, useToast } from '@knack/asterisk-react';

import { type Builder as BuilderType } from '@/types/apps';
import { useBuilderMutation } from '@/hooks/api/mutations/useBuilderMutation';
import { useSession } from '@/hooks/useSession';
import { getErrorMessage } from '@/utils/errors';
import { ProfileCard } from '@/components/ui/ProfileCard';

interface BuilderProps {
  appId: string;
  builder: BuilderType;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export function Builder({ appId, builder, setErrorMessage }: BuilderProps) {
  const { deleteBuilder } = useBuilderMutation();
  const session = useSession();
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const [isConfirmingRemoval, setConfirmingRemoval] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, first_name, last_name, email, avatar_uri } = builder;
  const name = first_name && last_name ? `${first_name} ${last_name}` : '';

  const removeBuilder = () => {
    setConfirmingRemoval(false);
    deleteBuilder.mutate(
      { appId, accountId: session.account.id, builderId: id },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.manage_builders.user_removed', {
              user: name || email
            })
          });
          setErrorMessage('');
        },
        onError: (error) => {
          const message = getErrorMessage(
            error,
            t('components.manage_builders.error_removing_builder')
          );
          setErrorMessage(message);
        }
      }
    );
  };

  const removeBuilderButtons = isConfirmingRemoval ? undefined : (
    <Tooltip>
      <Tooltip.Trigger className="ml-1" type="button" onClick={() => setConfirmingRemoval(true)}>
        <RemoveIcon className="h-5 w-5 text-subtle" />
      </Tooltip.Trigger>
      <Tooltip.Content className="bg-gray-900 text-white">
        {t('components.manage_builders.remove_shared_builder')}
      </Tooltip.Content>
    </Tooltip>
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <ProfileCard
          firstName={first_name}
          lastName={last_name}
          email={email}
          avatarUri={avatar_uri}
        />
        {deleteBuilder.isPending ? <Spinner /> : removeBuilderButtons}
      </div>
      {isConfirmingRemoval && (
        <>
          <p className="text-sm text-destructive">
            {t('components.manage_builders.confirm_remove_builder', {
              user: name || email
            })}
          </p>
          <div className="flex items-center gap-2">
            <Button
              size="sm"
              intent="minimal"
              className="flex items-center gap-1"
              onClick={() => setConfirmingRemoval(false)}
            >
              <CancelIcon />
              {t('actions.cancel')}
            </Button>
            <Button
              size="sm"
              intent="destructiveSecondary"
              className="flex items-center gap-1 border-destructive text-destructive"
              onClick={() => removeBuilder()}
            >
              <RemoveIcon />
              {t('actions.remove')}
            </Button>
          </div>
        </>
      )}
    </>
  );
}
