import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiAcademicCap as AcademicCapIcon } from 'react-icons/hi2';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { ChecklistsExpandedSection } from '@/components/checklists/ChecklistsExpandedSection';
import { ChecklistsProgress } from '@/components/checklists/ChecklistsProgress';
import { ChecklistsSection } from '@/components/checklists/ChecklistsSection';
import { ChecklistsTitle } from '@/components/checklists/ChecklistsTitle';

type Props = {
  onSectionClick: (section: string) => void;
};

export function ChecklistsMainContent({ onSectionClick }: Props) {
  const [t] = useTranslation();
  const { totalCompleted, totalCount, sections } = useChecklists();

  const sectionIds = useMemo(() => Object.keys(sections), [sections]);

  return (
    <>
      <ChecklistsTitle />
      <ChecklistsProgress steps={{ total: totalCount, completed: totalCompleted }} />
      {sectionIds.length === 1 ? (
        <ChecklistsExpandedSection id={sectionIds[0]} canGoBack={false} />
      ) : (
        <div className="mt-2 flex flex-col gap-2">
          {sectionIds.map((sectionId) => (
            <ChecklistsSection
              key={sectionId}
              onClick={() => onSectionClick(sectionId)}
              sectionId={sectionId}
            />
          ))}
        </div>
      )}
      <a
        href="https://knack.navattic.com/3-main-areas"
        target="_blank"
        className="flex items-center gap-1 p-2 text-sm font-medium text-emphasis"
        rel="noreferrer"
      >
        <AcademicCapIcon />
        {t('components.checklists.areas')}
      </a>
    </>
  );
}
