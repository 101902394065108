import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Input, Textarea } from '@knack/asterisk-react';

import { FormControl } from '@/components/ui/FormControl';
import { type BillingInfoFormSchemaType } from './Overview';

interface BillingContactProps {
  billingName: string;
  billingEmail: string;
  billingCustomInfo?: string;
  cardTitleClasses?: string;
  errors?: FieldErrors<BillingInfoFormSchemaType>;
  register: UseFormRegister<BillingInfoFormSchemaType>;
}

export function BillingContact({
  billingName,
  billingEmail,
  billingCustomInfo,
  cardTitleClasses,
  errors,
  register
}: BillingContactProps) {
  const [t] = useTranslation();
  return (
    <Card className="p-6 sm:p-6">
      <h2 className={cardTitleClasses}>{t('components.billing.overview.billing_contact')}</h2>
      <div className="mb-6">
        <FormControl>
          <FormControl.Label htmlFor="billing-email" isRequired>
            {t('components.billing.overview.billing_email')}
          </FormControl.Label>
          <Input
            id="billing-email"
            title="Email"
            type="email"
            size="sm"
            placeholder="Billing email"
            intent={errors?.billingEmail && 'destructive'}
            {...register('billingEmail', { value: billingEmail })}
          />
          {errors?.billingEmail && (
            <FormControl.Message type="error">{errors.billingEmail.message}</FormControl.Message>
          )}
        </FormControl>
      </div>
      <div>
        <FormControl>
          <FormControl.Label htmlFor="billing-info" isRequired>
            {t('components.billing.overview.billing_info')}
          </FormControl.Label>
          <Textarea
            id="billing-info"
            maxLength={500}
            title="Billing Info"
            intent={errors?.billingInfo && 'destructive'}
            required
            {...register('billingInfo', {
              value:
                billingCustomInfo ||
                (billingName.trim() ? `${billingName}\n${billingEmail}` : billingEmail)
            })}
          />
          {errors?.billingInfo && (
            <FormControl.Message type="error">{errors.billingInfo.message}</FormControl.Message>
          )}
          <FormControl.Message className="text-subtle">
            {t('components.billing.overview.to_be_displayed')}
          </FormControl.Message>
        </FormControl>
      </div>
    </Card>
  );
}
