import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type User } from '@/types/user';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

type UserRespData = {
  _id: string;
  account_id: string;
  first_name: string;
  last_name: string;
  email: string;
  isGoogleUser?: boolean;
};

function translateToUser(userData: UserRespData) {
  return {
    // eslint-disable-next-line
    id: userData._id,
    accountId: userData.account_id,
    firstName: userData.first_name,
    lastName: userData.last_name,
    email: userData.email,
    isGoogleUser: !!userData.isGoogleUser
  };
}

// We should make new dashboard endpoints for account and user puts so we do not have to do this on the client.
function translateToPayload(user: User, password?: string) {
  return {
    email: { email: user.email },
    name: { first: user.firstName, last: user.lastName },
    old_password: password ? { password } : undefined,
    is_google_user: user.isGoogleUser,
    crumbtrail: {},
    url: window?.location.href
  };
}

export async function updateUser({
  accountId,
  user,
  password
}: {
  accountId: string;
  user: User;
  password?: string;
}): Promise<User> {
  const payload = translateToPayload(user, password);
  const url = `/v1/accounts/${accountId ? `${accountId}/` : ''}session/${user.id}`;

  return axios
    .put(url, payload, { withCredentials: true })
    .then((resp) => translateToUser(resp?.data?.user || {}));
}

export function useUserMutation() {
  const session = useSession();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ user, password }: { user: User; password?: string }) =>
      updateUser({ accountId: session.account.id, user, password }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.auth.session] });
    }
  });
}
