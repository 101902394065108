import formatHelper from '@knack/format-helper';

/**
 * Formats bytes (using binary base 2) into a string with the correct unit as suffix. Will use KB as the unit if value is zero.
 * @param {number} valueInBytes - The value (in bytes) to be formatted
 * @return {string} Formatted string with its corresponding unit suffix
 */
export function formatNumberForStorage(valueInBytes: number): string {
  if (valueInBytes <= 0) return '0 KB';

  const kilobytes = valueInBytes / 1024;

  if (kilobytes >= 1024) {
    const megabytes = kilobytes / 1024;

    if (megabytes >= 1024) {
      return `${formatHelper.formatNumber(megabytes / 1024, {
        precision: 1
      })} GB`;
    }

    return `${formatHelper.formatNumber(megabytes, {
      precision: 1
    })} MB`;
  }

  return `${formatHelper.formatNumber(kilobytes, {
    precision: 1
  })} KB`;
}

/**
 * Formats a slug by removing invalid characters and replacing consecutive hyphens or underscores with a single hyphen.
 * Also removes preceding hyphens from the start of the string.
 * @param {string} slug - The original slug to be formatted.
 * @returns {string} The formatted slug.
 */
export function formatSlug(slug: string): string {
  const trimmedSlug = slug.trim().toLowerCase();

  const sanitizedSlug = trimmedSlug
    .replace(/[^a-z0-9-\s]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+/, '');

  return sanitizedSlug;
}

export function capitalize(value: string) {
  return `${value[0].toUpperCase()}${value.slice(1).toLowerCase()}`;
}

/**
 * Retrieve an English ordinal for a number, e.g. "2nd" for 2, "11th" for 11, etc.
 * @param {number} number - The number to obtain the ordinal from
 * @return {string} Formatted string with the number and its corresponding ordinal as suffix, or the number as-is (as a string) if the locale is not English
 */
export function getOrdinalNumber(number: number): string {
  // Since we only support English language in our app for now, we will not be checking for the user locale
  // Once we support other languages, we can uncomment the code below

  // If the locale language is not English, return the number as-is
  // if (!i18n.language.toLowerCase().startsWith('en')) {
  //   return number.toString();
  // }

  const ordinal =
    number > 0
      ? ['th', 'st', 'nd', 'rd'][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10]
      : '';

  return `${number}${ordinal}`;
}
