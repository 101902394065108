export function PortalIllustration() {
  return (
    <svg fill="none" viewBox="0 0 139 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g clipPath="url(#b)">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
          <rect x="12.5" y="13.009" width="114" height="15.546" fill="#3C44CD" />
          <path
            d="m19.017 22.749c1.5928-0.8476 5.4282-2.5688 8.0266-2.6721 3.0654-0.122 2.6394 3.0385 6.638 2.2441 0.5009-0.0996 0.9301-0.4078 1.3114-0.7476 3.1615-2.8177 7.1668 0.4912 8.6479 1.0844 2.9443 1.1794 7.6626-1.6013 9.418-1.2629"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="1.0364"
          />
          <rect x="53.955" y="69.486" width="31.091" height="5.1818" rx="2.5909" fill="#827FFF" />
          <rect x="53.955" y="61.71" width="31.091" height="2.5909" rx="1.2954" fill="#DFDFE2" />
          <rect x="53.955" y="50.055" width="31.091" height="2.5909" rx="1.2954" fill="#DFDFE2" />
          <path
            d="m55.25 48.262c0.4836-0.335 1.7877-0.3486 3.2386-0.1476v0c1.7619 0.2441 3.8071 0.4772 5.5626 0.1911l0.267-0.0435"
            stroke="#A2A2A2"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
          <path
            d="m55.25 59.587c0.76-0.5552 2.8093-0.5777 5.0893-0.2446v0c2.7621 0.4035 6.001 0.7877 8.7521 0.3148l0.4086-0.0702"
            stroke="#A2A2A2"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
          <path
            d="m55.25 41.132c1.1745-1.1104 4.3416-1.1554 7.8653-0.4892v0c4.2203 0.7979 9.4387 1.5389 13.631 0.6062l0.5261-0.117"
            stroke="#626262"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
