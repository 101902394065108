// The conditions to determine what an internal email is, come from the
// definition of the `is_knack` property in `data-etl-pipelines`
import { type Session } from '@/types/session';
import { dateCreatedFromMongoId } from '@/utils/dateCreatedFromMongoId';
import { getEmbeddedId, getReferral } from '@/utils/querystrings';

export const isInternalEmail = (email: string) => {
  if (typeof email !== 'string') {
    return false;
  }

  // By default we don't want to track knack emails
  if (import.meta.env.PUBLIC_SEGMENT_TRACK_KNACK_EMAILS !== 'true') {
    const internalDomains = ['@knack.com', '@knackhq.com', '@appnowgo.com'];

    // Check for domain endings
    if (internalDomains.some((domain) => email.endsWith(domain))) {
      return true;
    }
  }

  const emailPatterns = [
    /@ziggystar/,
    /@resurgenstech\.com/,
    /dev\+playwright/,
    /kn\.test\.internal/
  ];

  return emailPatterns.some((pattern) => pattern.test(email));
};

export const shouldSendAnalytics = (email: string): boolean =>
  !!window.analytics && !!email && !isInternalEmail(email);

export const pushToDataLayer = (event: string, props?: { [key: string]: unknown }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...props
  });
};

export const getSegmentUserTraits = (session: Session) => ({
  email: session.user.email,
  referral: getReferral(),
  embeddedId: getEmbeddedId(),
  user_created: dateCreatedFromMongoId(session.user.id)
});

type Experiment = {
  experimentId: string;
  experimentName: string;
  variationId: string;
  variationName: string;
};

export const trackExperiment = async (
  { experimentId, experimentName, variationId, variationName }: Experiment,
  accountId: string,
  userId?: string
) => {
  if (userId) {
    await window.analytics.identify(userId);
  }

  await window.analytics.track('Experiment Viewed', {
    groupId: accountId,
    experiment_id: experimentId,
    experiment_name: experimentName,
    variation_id: variationId,
    variation_name: variationName
  });
};
