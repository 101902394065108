import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

async function getSampleApps() {
  const response = await axios.get(`/v1/accounts/applications/examples`, {
    withCredentials: true
  });

  return response.data.apps;
}

export function useSampleAppsQuery() {
  return useQuery({
    queryKey: [queryKeys.apps.sampleApplications],
    queryFn: () => getSampleApps(),
    refetchOnWindowFocus: false
  });
}
