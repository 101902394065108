import { useEffect } from 'react';
import {
  useForm,
  type FieldErrors,
  type UseFormRegister,
  type UseFormUnregister
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as DeleteIcon } from 'react-icons/hi2';
import { Button, Input, Select } from '@knack/asterisk-react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { FormControl } from '@/components/ui/FormControl';
import { COUNTRIES, STRIPE_TAX_DATA } from './constants';
import { type TaxesFormSchemaType } from './Payment';

const countryList = STRIPE_TAX_DATA;
const countryCodeList = COUNTRIES;

interface TaxId {
  type: string;
  value: string;
}
interface TaxIDCollectionFormProps {
  taxes: TaxId[];
  setTaxes: React.Dispatch<React.SetStateAction<TaxesFormSchemaType>>;
  errors?: FieldErrors<TaxesFormSchemaType>;
  register: UseFormRegister<TaxesFormSchemaType>;
  unregister: UseFormUnregister<TaxesFormSchemaType>;
  onTaxFormStateChange: (formState: TaxFormState) => void;
  onSubmitHandler: (data: TaxesFormSchemaType) => void;
  formId: string;
}

export interface TaxFormState {
  isFormSubmitting: boolean;
  isTaxFormDirty: boolean;
}

export function TaxIDCollectionForm({
  formId,
  taxes,
  errors,
  register,
  unregister,
  setTaxes,
  onTaxFormStateChange,
  onSubmitHandler
}: TaxIDCollectionFormProps) {
  const [t] = useTranslation();

  const { handleSubmit, setValue, formState } = useForm<TaxesFormSchemaType>();
  const { isDirty, isSubmitting } = formState;
  useEffect(() => {
    onTaxFormStateChange({ isTaxFormDirty: isDirty, isFormSubmitting: isSubmitting });
  }, [isDirty, isSubmitting, onTaxFormStateChange]);

  const getCountryCode = (countryName: string) =>
    Object.keys(countryCodeList).find((key) => countryCodeList[key] === countryName);

  const getCountryFlag = (countryName: string) =>
    getUnicodeFlagIcon(getCountryCode(countryName) ?? 'US');

  const onDelete = (index: number) => {
    // Remove this item from the taxes array
    setTaxes(taxes.filter((tax, i) => i !== index));
    unregister(`${index}.type`);
    unregister(`${index}.value`);
    setValue(`${index}.type`, '', { shouldDirty: true });
    setValue(`${index}.value`, '', { shouldDirty: true });
  };
  const createBlankTax = () => {
    // Add a new item to the taxes array
    setTaxes([...taxes, { type: '', value: '' }]);
    setValue(`${taxes.length - 1}.type`, '', { shouldDirty: true });
    setValue(`${taxes.length - 1}.value`, '', { shouldDirty: true });
  };

  const setSelectedCountry = (index: number, country: string) => {
    const value = country.split('-')[0];
    register(`${index}.type`, { value });
    const clonedTaxes = [...taxes];
    if (clonedTaxes[index]) {
      clonedTaxes[index].type = value;
    } else {
      clonedTaxes.push({ type: value, value: '' });
    }
    setTaxes(clonedTaxes);
    setValue(`${index}.type`, value, { shouldDirty: true });
  };

  const setTaxIdValue = (index: number, value: string) => {
    register(`${index}.value`, { value });
    const clonedTaxes = [...taxes];
    if (clonedTaxes[index]) {
      clonedTaxes[index].value = value;
    } else {
      clonedTaxes.push({ type: '', value });
    }

    setTaxes(clonedTaxes);
    setValue(`${index}.value`, value, { shouldDirty: true });
  };

  const getDefaultValueSelect = (index: number) => {
    if (taxes[index]) {
      return `${taxes[index].type}-${
        countryList.find((country) => country.value === taxes[index].type)?.name
      }`;
    }
    return '';
  };

  const getDefaultValueInput = (index: number) => {
    if (taxes[index]) {
      return taxes[index].value;
    }
    return '';
  };

  return (
    <>
      <h2 className="mb-6 text-xl font-medium text-emphasis">
        {t('components.billing.payment.tax_id')}
      </h2>
      <form id={formId} onSubmit={handleSubmit(onSubmitHandler)} data-private>
        <FormControl>
          <FormControl.Label htmlFor="taxes">
            {t('components.billing.payment.tax_id_message')}
          </FormControl.Label>
          <div>
            <p className="mb-3">{t('components.billing.payment.tax_id')}</p>
            {taxes.map((tax, index) => (
              <div key={tax.value} className="mb-5 mt-2 flex gap-2">
                <Select
                  defaultValue={getDefaultValueSelect(index)}
                  onValueChange={(e) => {
                    setSelectedCountry(index, e);
                  }}
                >
                  <Select.Trigger
                    id={`taxes[${index}].country`}
                    placeholder={t('components.billing.payment.country')}
                    className="w-2/5"
                  />
                  <Select.Content>
                    {countryList.map((country) => (
                      <Select.Item
                        key={`${country.value}-${country.name}`}
                        value={`${country.value}-${country.name}`}
                      >
                        {`${getCountryFlag(country.name)} ${country.description}`}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
                {errors?.[index]?.type && (
                  <FormControl.Message type="error">
                    {t('components.billing.payment.value_required')}
                  </FormControl.Message>
                )}
                <Input
                  title="Value"
                  autoFocus={taxes[index].value !== ''}
                  defaultValue={getDefaultValueInput(index)}
                  type="text"
                  className="w-3/5"
                  onChange={(e) => {
                    setTaxIdValue(index, e.currentTarget.value);
                  }}
                />
                <Button
                  intent="minimal"
                  aria-label={t('components.billing.payment.delete_tax_id_label')}
                  className="ml-7"
                  data-testid="delete-tax"
                  onClick={() => {
                    onDelete(index);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </div>
            ))}
          </div>
        </FormControl>
      </form>
      <div>
        <Button
          aria-label={t('components.billing.payment.add_tax_id_label')}
          intent="link"
          data-testid="add-tax"
          onClick={createBlankTax}
        >
          {t('components.billing.payment.add_tax')}
        </Button>
      </div>
    </>
  );
}
