import React from 'react';
import { Dialog } from '@knack/asterisk-react';
import { type VariantProps } from 'class-variance-authority';

interface DashboardDialogContentProps extends VariantProps<typeof Dialog.Content> {
  headerTitle?: string;
  headerDescription?: string;
  footer?: React.ReactNode;
}

export function DashboardDialogContent({
  width,
  headerTitle,
  headerDescription,
  footer,
  children,
  ...props
}: DashboardDialogContentProps) {
  return (
    <Dialog.Content width={width || 'sm'} className="bg-default" {...props}>
      <Dialog.MainContent>
        {headerTitle && (
          <Dialog.Header>
            <Dialog.Title>{headerTitle}</Dialog.Title>
            {headerDescription && <Dialog.Description>{headerDescription}</Dialog.Description>}
          </Dialog.Header>
        )}
        {children && <div className="pt-6">{children}</div>}
      </Dialog.MainContent>
      {footer && footer}
    </Dialog.Content>
  );
}
