import { type Area } from 'react-easy-crop/types';

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

export async function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const file = new File([buffer], filename, {
    type: mimeType,
    lastModified: Date.now()
  });
  return file;
}

export async function getCroppedImg(imageSrc: string, pixelCrop: Area, type: string) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const DESIRED_SIZE = 512;

  const maxSize = Math.max(pixelCrop.width, pixelCrop.height);
  const scaleFactor = DESIRED_SIZE / maxSize;

  const scaledWidth = pixelCrop.width * scaleFactor;
  const scaledHeight = pixelCrop.height * scaleFactor;
  const xOffset = (DESIRED_SIZE - scaledWidth) / 2;
  const yOffset = (DESIRED_SIZE - scaledHeight) / 2;

  canvas.width = DESIRED_SIZE;
  canvas.height = DESIRED_SIZE;

  ctx?.drawImage(
    image as CanvasImageSource,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    xOffset,
    yOffset,
    scaledWidth,
    scaledHeight
  );

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      if (file !== null) {
        resolve(URL.createObjectURL(file));
      }
    }, type);
  });
}
