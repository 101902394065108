import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type BillingPlan } from '@/types/account';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getApiPlan() {
  const { data } = await axios.get<BillingPlan>('/v1/dashboard/billing/api-plan', {
    withCredentials: true
  });

  return data;
}

export function useApiPlanQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.billing.apiPlan, session.account.id],
    queryFn: getApiPlan,
    staleTime: 1000 * 30
  });
}
