import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { PageLayout } from '@/components/layout';
import { Security as SecurityPage } from '@/pages/settings/security/Security';

export function Security() {
  const [t, { language }] = useTranslation();

  return (
    <PageLayout>
      <h1 className="mb-6 mr-12 text-3xl font-semibold text-emphasis">
        {t('navigation.security')}
      </h1>
      <SecurityPage />
      <Helmet>
        <title lang={language}>{`${t('navigation.security')} ${t('navigation.settings')} - ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </PageLayout>
  );
}
