import { useTranslation } from 'react-i18next';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import { useEnableTwoFAMutation } from '@/hooks/api/mutations/useEnableTwoFAMutation';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { useServerErrors } from '@/hooks/api/useServerErrors';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';

export function EnableConfirm({
  open,
  code,
  onClose,
  onError
}: {
  open: boolean;
  code: string;
  onClose: () => void;
  onError: (error: string) => void;
}) {
  const [t] = useTranslation();
  const { getError } = useServerErrors();
  const { presentToast } = useToast();
  const { refetch } = useSessionQuery();
  const { mutate: postTwoFAEnable, isPending: isSaving } = useEnableTwoFAMutation();

  const onConfirmHandler = () => {
    postTwoFAEnable(code, {
      onSuccess: () => {
        presentToast({
          title: t('components.two_factor_authentication.enabled_success')
        });
        void refetch();
        onClose();
      },
      onError: (err: any) => {
        const errorCode = err.response?.data?.errors[0]?.message;
        const error = getError(errorCode);

        if (error?.key === 'invalid_code') {
          onError(error.message);
        } else {
          presentToast({
            title: t('errors.generic_error')
          });
        }
      }
    });
  };

  const Footer = (
    <Dialog.Footer>
      <div className="flex justify-end gap-2">
        <Button intent="minimal" onClick={onClose} disabled={isSaving}>
          {t('actions.cancel')}
        </Button>
        <Button isLoading={isSaving} onClick={onConfirmHandler}>
          {t('actions.enable')}
        </Button>
      </div>
    </Dialog.Footer>
  );

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose}>
      <DashboardDialogContent
        headerTitle={t('components.two_factor_authentication.enable_2fa_title')}
        footer={Footer}
      >
        <div>
          <p>{t('components.two_factor_authentication.enable_confirm')}</p>
        </div>
      </DashboardDialogContent>
    </Dialog>
  );
}
