import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type Account } from '@/types/account';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

export type AccountPayload = {
  id: string;
  name: string;
  slug: string;
  sttus: string;
  billing_email: {
    email: string;
  };
  billing_info: string;
};

async function updateAccount(
  accountData: Partial<AccountPayload>,
  accountId: string
): Promise<Account> {
  const endpoint = `/v1/accounts/${accountId}`;
  const { data } = await axios.put(endpoint, accountData, {
    withCredentials: true
  });

  return data.account;
}

export function useAccountMutation() {
  const session = useSession();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accountData: Partial<AccountPayload>) =>
      updateAccount(accountData, session.account.id),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.billing.overview]
      });
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.account]
      });
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.auth.session]
      });
    }
  });
}
