import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { type Credentials } from '@/types/auth';
import { useSignInMutation } from '@/hooks/api/mutations/useSignInMutation';

export const useSignIn2faForm = ({ credentials }: { credentials: Credentials }) => {
  const [t] = useTranslation();

  const SignInWith2faSchema = z.object({
    code2fa: z
      .string()
      .regex(/^[0-9]{6,6}$/g, t('components.sign_in.not_6_digits'))
      .max(6, t('components.sign_in.not_6_digits'))
  });

  type SignInWith2faSchemaType = z.infer<typeof SignInWith2faSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful, isValid },
    setError
  } = useForm<SignInWith2faSchemaType>({
    resolver: zodResolver(SignInWith2faSchema)
  });

  const signIn = useSignInMutation();

  const onSubmitHandler: SubmitHandler<SignInWith2faSchemaType> = async ({ code2fa }) => {
    try {
      await signIn.mutateAsync(
        { ...credentials, totp_token: code2fa },
        {
          onError: (err: any) => {
            const errorCode = err.response?.data?.errors[0]?.message;

            const errorMessage =
              !errorCode || errorCode === 'generic_error'
                ? 'errors.generic_error'
                : `components.sign_in.${errorCode}`;

            setError('code2fa', {
              type: t(errorMessage),
              message: t(errorMessage)
            });
          }
        }
      );
    } catch (error) {
      // Without a try/catch here, isSubmitting is not set to false after an error
    }
  };

  return {
    signIn,
    register,
    handleSubmit,
    errors,
    isSubmitting,
    isSubmitSuccessful,
    isValid,
    onSubmitHandler
  };
};
