export function DirectoryIllustration() {
  return (
    <svg fill="none" viewBox="0 0 139 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g clipPath="url(#b)">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
          <rect x="12.5" y="13.008" width="114" height="15.546" fill="#2D9A69" />
          <path
            d="m50.5 21.071c1.9733-1.2857 7.2943-1.3378 13.214-0.5664 1.2495 0.1628 2.4158 0.3108 3.4899 0.455 5.018 0.6737 10.694 1.5834 15.699 0.8161l4.5969-0.7047"
            stroke="#D3F2E4"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
          <path
            d="m64.5 75.714c-1.3684-0.3735-1.8535-0.381-3.5714-0.3776v0c-2.013 0.0039-4.5457 1.0896-6.4286 0.3776v0"
            stroke="#E3E3E3"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
          <path
            d="m116.5 75.455c-2.326-0.449-3.151-0.458-6.071-0.4539v0c-3.38 0.0046-7.443 1.3859-10.708 0.513l-0.2211-0.0591"
            stroke="#E3E3E3"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
          <rect x="18.5" y="34" width="15" height="2" rx="1" fill="#D3F2E4" />
          <rect x="18.5" y="74" width="15" height="4" rx="2" fill="#2D9A69" />
          <rect x="69.5" y="74" width="15" height="4" rx="2" fill="#2D9A69" />
          <rect x="36.5" y="34" width="12" height="2" rx="1" fill="#DADADA" />
          <rect x="52.5" y="34" width="15" height="2" rx="1" fill="#DADADA" />
          <rect x="18.5" y="41" width="47" height="31" fill="#F5F5F5" />
          <rect x="69.5" y="41" width="47" height="31" fill="#F5F5F5" />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
