import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Input, Label } from '@knack/asterisk-react';
import { z } from 'zod';

import { useQRGenQuery } from '@/hooks/api/queries/useQRGenQuery';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { FormControl } from '@/components/ui/FormControl';
import { EnableConfirm } from '@/pages/settings/security/two-factor-auth/EnableConfirm';

export function EnableTwoFactorAuth() {
  const [t] = useTranslation();
  const { data: authData } = useQRGenQuery();
  const [formData, setFormData] = useState({ code: '' });

  const schema = z.object({
    code: z
      .string()
      .regex(/^[0-9]{6,6}$/g, t('components.two_factor_authentication.error_code_numbers_only'))
      .max(6, t('components.two_factor_authentication.error_max_code'))
  });

  type FormSchemaType = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid }
  } = useForm<FormSchemaType>({
    resolver: zodResolver(schema)
  });

  const onCloseHandler = () => {
    reset();
  };

  const onErrorHandler = (error: string) => {
    setError('code', {
      message: error
    });
  };

  return (
    <Dialog>
      <div className="flex h-full flex-col justify-end">
        <Dialog.Trigger asChild>
          <Button intent="secondary" data-testid="security-enable-two-factor-auth-button">
            {t('components.two_factor_authentication.enable_2fa')}
          </Button>
        </Dialog.Trigger>
      </div>
      <DashboardDialogContent
        headerTitle={t('components.two_factor_authentication.enable_2fa_title')}
      >
        <div className="mb-[136px] flex flex-col gap-6 bg-default">
          <div className="flex flex-col gap-2">
            <Label className="text-sm">{t('components.two_factor_authentication.step_one')}</Label>
            <p>{t('components.two_factor_authentication.recommended')}</p>
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-sm">{t('components.two_factor_authentication.step_two')}</Label>
            <p>{t('components.two_factor_authentication.step_two_details')}</p>
          </div>
          <div className="flex gap-6">
            <img
              src={authData?.data_uri}
              alt="qr-code"
              className="h-[120px] w-[120px] flex-none bg-gray-500"
            />
            <div className="flex flex-1 flex-col flex-wrap gap-2 overflow-hidden">
              <Label className="text-sm">
                {t('components.two_factor_authentication.secret_key')}
              </Label>
              <p className="break-all">{authData?.secret}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label className="text-sm">{t('components.two_factor_authentication.step_3')}</Label>
            <p>{t('components.two_factor_authentication.step_3_details')}</p>
            <form onChange={handleSubmit(setFormData)}>
              <FormControl>
                <FormControl.Label
                  htmlFor="two-fa-confirm-code"
                  intent={errors?.code ? 'destructive' : undefined}
                  className="text-subtle"
                >
                  {t('components.two_factor_authentication.six_digit_code')}
                </FormControl.Label>
                <Input
                  id="two-fa-confirm-code"
                  className="form-input appearance-none"
                  type="string"
                  maxLength={6}
                  minLength={6}
                  placeholder={t('components.two_factor_authentication.six_digit_code')}
                  inputMode="numeric"
                  title={t('components.two_factor_authentication.six_digit_code_title')}
                  intent={errors?.code ? 'destructive' : 'default'}
                  {...register('code')}
                />
                {errors?.code && (
                  <FormControl.Message type="error">{errors?.code?.message}</FormControl.Message>
                )}
              </FormControl>
            </form>
            <EnableConfirm
              open={isValid}
              code={formData.code}
              onClose={onCloseHandler}
              onError={onErrorHandler}
            />
          </div>
        </div>
      </DashboardDialogContent>
    </Dialog>
  );
}
