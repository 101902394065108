import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getInvoices(accountId: string) {
  const { data } = await axios.get(`/v1/accounts/${accountId}/billing/invoices`, {
    withCredentials: true
  });
  return data.invoices;
}

export function useInvoicesQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.billing.invoices],
    queryFn: () => getInvoices(session.account.id)
  });
}
