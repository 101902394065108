import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';

export function TasksQuickViewForm({ app }: { app: KnackApplication }) {
  const [t] = useTranslation();
  const { tasks } = app;

  if (!tasks) return null;

  return (
    <div className="flex flex-1 flex-col">
      <Dialog.MainContent>
        <div className="space-y-6">
          <p>
            {t('components.task_quick_view_modal.total')}: <strong>{tasks.total}</strong>
          </p>
          <p>
            {t('components.task_quick_view_modal.tasks_running')}: <strong>{tasks.running}</strong>
          </p>
          <p>
            {t('components.task_quick_view_modal.tasks_paused')}: <strong>{tasks.paused}</strong>
          </p>
        </div>
      </Dialog.MainContent>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button intent="minimal" size="sm">
            {t('actions.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Footer>
    </div>
  );
}
