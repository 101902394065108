import axios from 'axios';
import Cookies from 'js-cookie';

import { shouldSendAnalytics } from './analytics';

export const addNewUserToHubspot = (email: string) => {
  if (shouldSendAnalytics(email)) {
    const hubspotTrackingCookie = Cookies.get('hubspotutk');

    if (
      hubspotTrackingCookie &&
      import.meta.env.PUBLIC_HUBSPOT_APP_ID !== '' &&
      import.meta.env.PUBLIC_HUBSPOT_FORM_ID !== ''
    ) {
      const hubspotAppID = import.meta.env.PUBLIC_HUBSPOT_APP_ID;
      const hubspotFormID = import.meta.env.PUBLIC_HUBSPOT_FORM_ID;

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotAppID}/${hubspotFormID}`;
      const data = {
        fields: [
          {
            name: 'email',
            value: email
          }
        ],
        context: {
          hutk: hubspotTrackingCookie
        }
      };

      axios.post(url, data).catch(() => console.log('Error adding the user to hubspot'));
    }
  }
};
