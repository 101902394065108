import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getLoaderApiUrl } from '@/utils/axiosConfig';

async function getSharedApps() {
  const domain = getLoaderApiUrl();
  const url = `${domain}/v1/dashboard/users/apps/shared`;
  const { data } = await axios.get(url, {
    withCredentials: true
  });
  return data;
}

export function useSharedAppsQuery() {
  return useQuery({
    queryKey: [queryKeys.apps.sharedApplications],
    queryFn: getSharedApps,
    staleTime: 1000 * 30
  });
}
