import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useSession } from '@/hooks/useSession';

async function emailInvoice(invoice: any, accountId: string) {
  const { data } = await axios.post(
    `/v1/accounts/${accountId}/billing/invoices/email`,
    { invoice },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useInvoiceEmailMutation() {
  const session = useSession();

  return useMutation({
    mutationFn: (invoice: any) => emailInvoice(invoice, session.account.id)
  });
}
