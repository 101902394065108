import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

type UserHubSpotTokenRespData = {
  hubspotToken: string;
};

type UserHubSpotIdentifyPayload = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

async function identifyUserInHubspot({ email, firstName, lastName }: UserHubSpotIdentifyPayload) {
  const url = '/v1/hubspot-auth/identify';
  const payload = {
    email,
    firstName,
    lastName
  };

  const { data } = await axios.post<UserHubSpotTokenRespData>(url, payload, {
    withCredentials: true
  });
  return data.hubspotToken;
}

export function useHubspotMutation() {
  return useMutation({
    mutationFn: ({ email, firstName, lastName }: UserHubSpotIdentifyPayload) =>
      identifyUserInHubspot({
        email,
        firstName,
        lastName
      }),
    onSuccess(hubspotToken: string) {
      return hubspotToken;
    }
  });
}
