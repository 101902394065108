import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Badge, Button, Card, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { type Account } from '@/types/account';
import {
  useTaxIDsMutation,
  type TaxFormObjectPayload,
  type UpdateTaxIdPayload
} from '@/hooks/api/mutations/useTaxIDsMutation';
import { useTaxIDsQuery } from '@/hooks/api/queries/useTaxIDsQuery';
import { useIsTaxExempt } from '@/hooks/billing/useIsTaxExempt';
import i18n from '@/i18n';
import { PaymentForm, type PaymentFormState, type PaymentFormValues } from './PaymentForm';
import { TaxExemptionMessage } from './TaxExemptionMessage';
import { TaxIDCollectionForm, type TaxFormState } from './TaxIDCollectionForm';

interface PaymentProps {
  accountData: Account;
}
const taxesFormSchema = z.array(
  z.object({
    type: z.string().min(1, i18n.t('components.billing.payment.tax_type_required')),
    value: z.string().min(1, i18n.t('components.billing.payment.tax_id_required'))
  })
);

export type TaxesFormSchemaType = z.infer<typeof taxesFormSchema>;

export function Payment({ accountData }: PaymentProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const isTaxExempt = useIsTaxExempt();
  const updateTaxIds = useTaxIDsMutation();
  const { data: taxIds } = useTaxIDsQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isTaxFormDirty, setIsTaxFormDirty] = useState(false);
  const [taxes, setTaxes] = useState<TaxesFormSchemaType>([{ type: '', value: '' }]); // [ {type: 'US-United States', value: '123'}
  const billingAddress = accountData.billing.billing_address;
  const saveChangeButton = useRef<HTMLButtonElement>(null);
  const {
    register: registerTaxForm,
    unregister: unregisterTaxForm,
    getValues: getTaxFormValues,
    reset: resetTaxForm,
    formState: taxFormState
  } = useForm<TaxesFormSchemaType>({
    resolver: zodResolver(taxesFormSchema),
    defaultValues: [{ type: '', value: '' }]
  });
  const paymentFormDefaultValues: PaymentFormValues = {
    name: billingAddress?.name,
    address: {
      line1: billingAddress?.street ?? '',
      line2: billingAddress?.street2 ?? null,
      city: billingAddress?.city ?? '',
      postal_code: billingAddress?.zip ?? '',
      country: billingAddress?.country ?? '',
      state: billingAddress?.state ?? ''
    }
  };

  useEffect(() => {
    const result = taxIds?.tax_ids ?? [];
    const previousTaxIds: TaxesFormSchemaType = [];
    result.forEach((tax: TaxFormObjectPayload) => {
      previousTaxIds.push({
        type: tax.type,
        value: tax.value
      });
    });
    resetTaxForm(previousTaxIds);
    setTaxes(result);
  }, [resetTaxForm, taxIds]);

  const onFormSuccess = (
    message: string = 'components.billing.payment.payment_information_updated_successfully'
  ) => {
    presentToast({
      title: t(message)
    });
  };

  const onSubmitHandler = async (data: TaxesFormSchemaType) => {
    if (!saveChangeButton.current) return;
    saveChangeButton.current.disabled = true;
    const taxData = Object.values(data).map((tax) => ({
      type: tax.type,
      value: tax.value
    }));
    const payload: UpdateTaxIdPayload = {
      taxes: taxData
    };
    try {
      await updateTaxIds.mutateAsync(payload);
      onFormSuccess('components.billing.payment.tax_id_update_success');
    } catch (error) {
      presentToast({
        title: t('components.billing.payment.tax_id_update_failed')
      });
    }
    saveChangeButton.current.disabled = false;
    resetTaxForm();
  };

  const onTaxFormStateChange = (formState: TaxFormState) => {
    setIsSubmitting(formState.isFormSubmitting);
    setIsTaxFormDirty(formState.isTaxFormDirty);
  };

  const onFormStateChange = (formState: PaymentFormState) => {
    setIsSubmitting(formState.isFormSubmitting);
    setIsFormDirty(formState.isFormDirty);
  };

  return (
    <div className="grid gap-6 lg:grid-cols-2">
      <Card className="flex flex-col p-6 sm:p-6">
        <h2 className="mb-6 text-xl font-medium text-emphasis">
          {t('components.billing.payment.update_payment_method')}
        </h2>
        <PaymentForm
          formId="payment-form"
          formDefaultValues={paymentFormDefaultValues}
          onFormStateChange={onFormStateChange}
          onFormSuccess={onFormSuccess}
        />
        <Button
          ref={saveChangeButton}
          type="submit"
          form="payment-form"
          isLoading={isSubmitting}
          className="mt-6"
          disabled={isSubmitting || !isFormDirty}
          data-testid="payment-save-changes-button"
        >
          {t('actions.save_changes')}
        </Button>
      </Card>

      <Card className="p-6 sm:p-6" data-testid="payment-tex-exemption">
        <div className="mb-6 flex flex-wrap justify-between gap-2">
          <h2 className="text-xl font-medium text-emphasis">
            {t('components.billing.payment.tax_exemption')}
          </h2>
          {isTaxExempt && (
            <Badge className="self-start font-medium normal-case">
              {t('components.billing.payment.tax_exempt')}
            </Badge>
          )}
        </div>
        <TaxExemptionMessage isTaxExempt={isTaxExempt} />
      </Card>
      <Card className="flex flex-col p-6 sm:p-6">
        <TaxIDCollectionForm
          taxes={taxes}
          formId="tax-form"
          unregister={unregisterTaxForm}
          setTaxes={setTaxes}
          register={registerTaxForm}
          errors={taxFormState.errors}
          onTaxFormStateChange={onTaxFormStateChange}
          onSubmitHandler={onSubmitHandler}
        />
        <Button
          ref={saveChangeButton}
          type="submit"
          form="tax-form"
          isLoading={isSubmitting}
          className="mt-6"
          disabled={isSubmitting || !isTaxFormDirty}
          data-testid="taxes-save-changes-button"
          onClick={() => {
            void onSubmitHandler(getTaxFormValues());
          }}
        >
          {t('actions.save_changes')}
        </Button>
      </Card>
    </div>
  );
}
