import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Card } from '@knack/asterisk-react';

import { useApiPlanQuery } from '@/hooks/api/queries/useApiPlanQuery';
import { ConfirmApiPlanDialog } from './ConfirmApiPlanDialog';

export type ApiAddonItem = {
  calls: number;
  price: number;
  quantity: number;
};

export type ApiAddonItemAction = 'upgrade' | 'downgrade' | 'remove';

export const legacyApiAddonItems: ApiAddonItem[] = [
  {
    calls: 25000,
    price: 25,
    quantity: 1
  },
  {
    calls: 50000,
    price: 50,
    quantity: 2
  },
  {
    calls: 75000,
    price: 75,
    quantity: 3
  },
  {
    calls: 100000,
    price: 100,
    quantity: 4
  }
];

export const currentApiAddonItems: ApiAddonItem[] = [
  {
    calls: 25000,
    price: 30,
    quantity: 1
  },
  {
    calls: 50000,
    price: 60,
    quantity: 2
  },
  {
    calls: 75000,
    price: 90,
    quantity: 3
  },
  {
    calls: 100000,
    price: 120,
    quantity: 4
  }
];

export function AddOns() {
  const [t] = useTranslation();
  const { data: currentApiPlan } = useApiPlanQuery();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ApiAddonItem | undefined>(undefined);
  const [selectedAction, setSelectedAction] = useState<ApiAddonItemAction | undefined>(undefined);

  const hasApiPlan = !!currentApiPlan;

  const apiAddonItems = currentApiAddonItems;

  const onApiItemSelect = (item: ApiAddonItem, action: ApiAddonItemAction) => {
    setSelectedItem(item);
    setSelectedAction(action);
    setIsConfirmDialogOpen(true);
  };

  return (
    <div className="grid gap-6 xl:grid-cols-2">
      <Card className="flex flex-col p-6 sm:p-6">
        <h2 className="mb-1 text-xl font-medium text-emphasis">
          {t('components.billing.addons.api_calls_title')}
        </h2>
        <p className="mb-6">{t('components.billing.addons.api_calls_description')}</p>
        <div className="mb-6">
          {apiAddonItems.map((item) => {
            const isCurrentApiLimit = hasApiPlan
              ? currentApiPlan?.quantity === item.quantity
              : false;
            const isHigherApiLimit = hasApiPlan ? item.quantity > currentApiPlan?.quantity : false;
            const isLowerApiLimit = hasApiPlan ? item.quantity < currentApiPlan?.quantity : false;

            return (
              <div
                key={item.quantity}
                className="flex flex-wrap justify-between gap-4 rounded-lg border border-default px-4 py-3.5 [&:not(:last-child)]:mb-6"
              >
                <div>
                  <div className="mb-2 text-emphasis">
                    <span>
                      {t('components.billing.addons.api_calls_item_limit', {
                        numberOfCalls: item.calls.toLocaleString()
                      })}
                    </span>
                    {isCurrentApiLimit && (
                      <Badge className="ml-2 font-normal">
                        {t('components.billing.addons.api_calls_current_limit')}
                      </Badge>
                    )}
                  </div>
                  <p className="text-xs">
                    {t('components.billing.addons.api_calls_item_price', {
                      price: item.price.toLocaleString()
                    })}
                  </p>
                </div>
                {(!hasApiPlan || isHigherApiLimit) && (
                  <Button onClick={() => onApiItemSelect(item, 'upgrade')}>
                    {t('actions.select')}
                  </Button>
                )}
                {hasApiPlan && isLowerApiLimit && (
                  <Button intent="minimal" onClick={() => onApiItemSelect(item, 'downgrade')}>
                    {t('actions.select')}
                  </Button>
                )}
                {hasApiPlan && isCurrentApiLimit && (
                  <Button intent="minimal" onClick={() => onApiItemSelect(item, 'remove')}>
                    {t('actions.remove')}
                  </Button>
                )}
              </div>
            );
          })}
        </div>
        <p className="text-subtle">{t('components.billing.addons.api_calls_more_info')}</p>
      </Card>

      {selectedItem && selectedAction && (
        <ConfirmApiPlanDialog
          open={isConfirmDialogOpen}
          setIsOpen={setIsConfirmDialogOpen}
          selectedItem={selectedItem}
          selectedAction={selectedAction}
          hasApiPlan={hasApiPlan}
        />
      )}
    </div>
  );
}
