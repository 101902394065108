import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useTranslationArray(i18nKey: string) {
  const { t } = useTranslation();

  return useMemo(
    () => Object.keys(t(i18nKey, { returnObjects: true })).map((k) => t(`${i18nKey}.${k}`)),
    [i18nKey, t]
  );
}
