import { Route } from '@/enums';

export const oldToNewDashboardUrlMapping: Record<string, string> = {
  welcome: `/${Route.SignUp}`,
  register: `/${Route.SignUp}`,
  register2: `/${Route.SignUp}`,
  'knack-password/forgot': `/${Route.ForgotPassword}`,
  apps: `/${Route.Apps}`,
  'apps/shared': `/${Route.Apps}/${Route.SharedApps}`,
  user: `/${Route.Settings}/${Route.Security}`,
  account: `/${Route.Settings}/${Route.Account}`,
  billing: `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
  'billing/plan': `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
  'billing/payment': `/${Route.Settings}/${Route.Billing}/${Route.Payment}`,
  'billing/invoices': `/${Route.Settings}/${Route.Billing}/${Route.Invoices}`,
  'billing/settings': `/${Route.Settings}/${Route.Billing}`
};
