import { useTranslation } from 'react-i18next';
import { HiArrowSmallLeft as ArrowIcon } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { PageError } from '@/components/errors/PageError';

export function EmailNotFound() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="full-page-wrapper">
      <PageError
        title={t('components.set_password.error_email_not_found.title')}
        message={t('components.set_password.error_email_not_found.message')}
        button={
          <Button
            className="gap-1"
            onClick={() => {
              navigate({
                pathname: `/${Route.SignUp}`
              });
            }}
          >
            <ArrowIcon size={14} />
            {t('actions.back')}
          </Button>
        }
      />
    </div>
  );
}
