import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

export type VideoResource = {
  title: string;
  description: string;
  thumbnail: string;
  url: string;
  skill_level: string;
};

async function getVideoResources(skill?: string, limit?: number) {
  const response = await axios.get<{ videos: VideoResource[] }>(`/v1/videos`, {
    params: { skill_level: skill, limit }
  });

  return response.data.videos;
}

export function useVideoResourcesQuery(skill?: string, limit?: number) {
  return useQuery({
    queryKey: [queryKeys.videoResources],
    queryFn: () => getVideoResources(skill, limit)
  });
}
