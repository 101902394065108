import { useEffect, useState } from 'react';
import { HiChevronDown as ChevronIcon } from 'react-icons/hi2';
import { Collapsible } from '@knack/asterisk-react';

import { type SharedApplication } from '@/types/apps';
import { cn } from '@/utils/tailwind';
import { ProfileCard } from '@/components/ui/ProfileCard';
import { AppsTable } from '@/pages/apps/apps-table';

interface SharedAppTableProps {
  apps: SharedApplication[];
}

export function SharedAppsTable({ apps }: SharedAppTableProps) {
  const [sharedApps, setSharedApps] = useState<SharedApplication[]>();
  const [openTables, setOpenTables] = useState<string[]>([]);

  useEffect(() => {
    setSharedApps(apps);
  }, [apps]);

  function addTableId(id: string) {
    const hasSameId = openTables.find((tableId) => tableId === id);
    if (!hasSameId) {
      setOpenTables((prev) => [...prev, id]);
    } else {
      setOpenTables((prev) => prev.filter((tableId) => tableId !== id));
    }
  }

  return (
    <div>
      {sharedApps?.map((app) => {
        const isThisTableOpen = openTables.includes(app.accountId);

        return (
          <div key={app.accountId} className="mb-12">
            <Collapsible
              id={app.accountId}
              defaultOpen
              onOpenChange={() => addTableId(app.accountId)}
            >
              <div className="flex items-center justify-between">
                <Collapsible.Trigger>
                  <ProfileCard
                    email={app.email}
                    firstName={app.firstName}
                    lastName={app.lastName}
                    avatarUri={app.avatarUri}
                    size="lg"
                  />
                </Collapsible.Trigger>
                <Collapsible.Trigger>
                  <ChevronIcon
                    size={24}
                    className={cn('transition-transform duration-200', {
                      'rotate-180': isThisTableOpen
                    })}
                  />
                </Collapsible.Trigger>
              </div>
              <Collapsible.Content className="collapsible-animation mt-4">
                <AppsTable
                  apps={app.applications}
                  sharedAccountSlug={app.accountSlug}
                  isSharedAppsTable
                />
              </Collapsible.Content>
            </Collapsible>
          </div>
        );
      })}
    </div>
  );
}
