import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { type Area, type Point } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import { HiArrowUpTray as UploadIcon } from 'react-icons/hi2';
import { Avatar, Button, Card, Dialog, Slider } from '@knack/asterisk-react';

import { useProfileAvatarMutation } from '@/hooks/api/mutations/useProfileAvatarMutation';
import { useGravatarQuery } from '@/hooks/api/queries/useGravatarQuery';
import { useSession } from '@/hooks/useSession';
import { getCroppedImg, urlToFile } from '@/utils/profileAvatar';
import { AxiosErrorBanner } from '@/components/errors/AxiosErrorBanner';

export function ProfileAvatar() {
  const [t] = useTranslation();
  const session = useSession();
  const { data: gravatarData } = useGravatarQuery(session.user.avatarUri);
  const avatarSrc = session.user.avatarUri || gravatarData?.gravatarUrl;

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });
  const [cropperFile, setCropperFile] = useState<File | null>(null);
  const [cropperSrc, setCropperSrc] = useState('');

  const onCropComplete = useCallback((area: Area, areaPixels: Area) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const { uploadAvatar, deleteAvatar } = useProfileAvatarMutation();
  const isLoading = deleteAvatar.isPending || uploadAvatar.isPending;
  const error = deleteAvatar.error || uploadAvatar.error;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length !== 0) {
        setCropperFile(acceptedFiles[0]);
      }
    },
    [setCropperFile]
  );

  useEffect(() => {
    let cropperImageUrl = '';
    if (cropperFile) {
      cropperImageUrl = URL.createObjectURL(cropperFile);
    }
    setCropperSrc(cropperImageUrl);

    return () => URL.revokeObjectURL(cropperImageUrl);
  }, [cropperFile]);

  const handleSaveCroppedImage = useCallback(async () => {
    if (cropperFile && cropperSrc) {
      const croppedImageUrl = await getCroppedImg(cropperSrc, croppedAreaPixels, cropperFile.type);
      const file = await urlToFile(croppedImageUrl as string, cropperFile.name, cropperFile.type);

      uploadAvatar.mutate(file, {
        onSuccess: () => {
          setCropperFile(null);
          setZoom(1);
        }
      });
    }
  }, [cropperFile, cropperSrc, croppedAreaPixels, uploadAvatar]);

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled: isLoading,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    onDrop
  });

  let dragNDropBorderClasses = 'border-2 border-transparent';
  if (isDragActive) {
    dragNDropBorderClasses = 'border-2 border-dashed border-emphasis ';
    if (isDragReject) {
      dragNDropBorderClasses += 'border-destructive bg-destructive/5';
    }
  }
  return (
    <>
      <Card className={`flex flex-col p-6 sm:p-6 ${dragNDropBorderClasses}`} {...getRootProps()}>
        <h2 className="mb-6 text-xl text-emphasis">{t('components.profile.profile_photo')}</h2>
        <div className="mb-6 flex items-start space-x-6">
          <Avatar className="h-16 w-16">
            <Avatar.Image src={avatarSrc} alt="avatar" />
            <Avatar.Fallback className="text-lg" delayMs={200}>
              {session.user.firstName?.[0]?.toUpperCase() || session.user.email?.[0]?.toUpperCase()}
            </Avatar.Fallback>
          </Avatar>

          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <Button
                data-testid="account-overview-photo"
                className="w-[111px] gap-2"
                intent="secondary"
                onClick={open}
                disabled={isLoading || session.user.isGoogleUser}
              >
                <input {...getInputProps()} />

                <UploadIcon size={16} />
                {t('actions.upload')}
              </Button>
              {!session.user.isGoogleUser && session.user.avatarUri && (
                <Button
                  intent="secondary"
                  onClick={() => deleteAvatar.mutate()}
                  disabled={isLoading || !session.user.avatarUri}
                >
                  {t('components.profile.remove_photo')}
                </Button>
              )}
            </div>
            <p className="text-sm text-subtle">
              {t('components.profile.profile_photo_description')}
            </p>
          </div>
        </div>
      </Card>
      <Dialog
        open={!!cropperSrc}
        onOpenChange={() => {
          setCropperFile(null);
          setZoom(1);
          uploadAvatar.reset();
        }}
      >
        <Dialog.Content className="bg-default">
          <Dialog.MainContent>
            <Dialog.Header className="mb-12">
              <Dialog.Title>{t('components.profile.crop_dialog_title')}</Dialog.Title>
            </Dialog.Header>
            <div className="space-y-6 overflow-y-auto px-1 pb-6">
              <div className="relative h-64 md:h-96">
                <Cropper
                  image={cropperSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                  showGrid={false}
                  classes={{ containerClassName: 'rounded-md' }}
                />
              </div>
              <Slider
                value={[zoom]}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby={t('components.profile.zoom')}
                onValueChange={(value) => setZoom(value[0])}
                disabled={uploadAvatar.isPending}
              />
              <AxiosErrorBanner translationPrefix="components.profile.errors" errors={error} />
            </div>
          </Dialog.MainContent>
          <Dialog.Footer>
            <div className="flex gap-4">
              <Dialog.Close asChild>
                <Button intent="secondary">{t('actions.cancel')}</Button>
              </Dialog.Close>
              <Button
                type="button"
                onClick={handleSaveCroppedImage}
                isLoading={uploadAvatar.isPending}
              >
                Save
              </Button>
            </div>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
