import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface GeneratePdfInvoiceParams {
  invoiceStaticMarkup: string;
  invoiceId: string;
}

async function generatePdfInvoice({ invoiceStaticMarkup, invoiceId }: GeneratePdfInvoiceParams) {
  const payload = {
    html: encodeURIComponent(invoiceStaticMarkup),
    key: '2012kpg',
    filename: `invoice_${invoiceId}.pdf`
  };

  const { data } = await axios.post(
    'https://pdfgen.knack.com/',
    { ...payload },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }
  );

  return data;
}

export function useInvoicePdfMutation() {
  return useMutation({
    mutationFn: (data: GeneratePdfInvoiceParams) => generatePdfInvoice(data)
  });
}
