import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { type PageTabs } from '@/hooks/usePageTabs';
import { cn } from '@/utils/tailwind';

interface PageHeaderProps {
  heading: string;
  tabConfig?: PageTabs;
  activeTab?: string;
  disabledTabs?: string[];
}

export function PageHeader({ heading, tabConfig, activeTab, disabledTabs = [] }: PageHeaderProps) {
  const [t] = useTranslation();
  const { search } = useLocation();

  return (
    <div className="mb-6 flex flex-wrap">
      <h1 className="mb-6 mr-12 text-3xl font-semibold text-emphasis">{heading}</h1>
      {tabConfig && (
        <div className="flex flex-wrap items-start gap-2">
          {Object.entries(tabConfig.tabs).map(([key, value]) => {
            const { label, path, dataTestId } = value;
            const isDisabled = disabledTabs.includes(path);
            return (
              <Button
                asChild
                intent="minimal"
                data-testid={dataTestId}
                className={cn('rounded-lg px-3 py-1.5 text-emphasis hover:bg-subtle', {
                  'pointer-events-none cursor-default bg-emphasis hover:bg-emphasis':
                    activeTab === path,
                  'pointer-events-none text-subtle hover:bg-transparent': isDisabled
                })}
                key={key}
                tabIndex={isDisabled ? -1 : undefined}
              >
                <Link to={{ pathname: `${tabConfig.baseUrl}/${path}`, search }}>{t(label)}</Link>
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
}
