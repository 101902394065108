import { useMemo } from 'react';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { usePlansQuery } from '@/hooks/api/queries/usePlansQuery';

export function usePlans() {
  const { data: account } = useAccountQuery();
  const { data: knackPlans } = usePlansQuery();
  const currentPlan = account?.product_plan;

  /**
   * @method originalPlan
   * @description When an account is frozen, the plan changes to a frozen_... plan.
   * @description originalPlan is the plan they were on before freezing.
   */
  const originalPlan = useMemo(
    () =>
      knackPlans?.find((plan) => plan.id === currentPlan?.id.replace('frozen_', '')) || currentPlan,
    [knackPlans, currentPlan]
  );

  return {
    originalPlan,
    currentPlan
  };
}
