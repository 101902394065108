import { useTranslation } from 'react-i18next';
import { Banner } from '@knack/asterisk-react';

import { type ProductPlan } from '@/types/account';
import { usePlanDowngradeInfoQuery } from '@/hooks/api/queries/usePlanDowngradeInfoQuery';

interface PlanDowngradeInfoProps {
  currentPlanName: string;
  selectedPlan: ProductPlan;
}

export function PlanDowngradeInfo({ currentPlanName, selectedPlan }: PlanDowngradeInfoProps) {
  const { data: downgradeInfo, isLoading } = usePlanDowngradeInfoQuery(selectedPlan.id);

  let downgrades = downgradeInfo?.downgrades ?? [];
  const [t] = useTranslation();

  // If selected plan is Pro we only care about SSO downgrades
  if (selectedPlan.level === 2) {
    downgrades = downgrades.filter((downgrade: any) => downgrade.type === 'sso');
  }

  const getDowngradeItem = (
    downgradeType: string,
    downgradeName: string,
    applicationName: string
  ) => {
    let formattedDowngradeType;
    switch (downgradeType) {
      case 'task':
        formattedDowngradeType = 'Task: ';
        break;
      case 'map':
        formattedDowngradeType = 'Map: ';
        break;
      case 'sso':
      case 'sso_domain_restriction':
      case 'custom_sso':
        formattedDowngradeType = 'SSO: ';
        break;
      case 'ecommerce':
        formattedDowngradeType = 'E-Commerce: ';
        break;
      default:
        formattedDowngradeType = '';
    }

    return (
      <>
        {applicationName} -&gt; {formattedDowngradeType}
        {downgradeName}
      </>
    );
  };

  if (isLoading) {
    return <p className="mt-6">{t('keywords.loading')}</p>;
  }

  if (!downgrades?.length) {
    return null;
  }

  return (
    <div className="mt-6">
      <p className="mb-6">
        {t('components.billing.plans.confirm_downgrade', {
          selectedPlanName: selectedPlan.name,
          currentPlanName
        })}
      </p>
      <ul className="mb-6 list-disc pl-6">
        {downgrades.map((downgrade: any) => (
          <li key={`${downgrade.name}-${downgrade.application_id}`} className="mb-2">
            {getDowngradeItem(downgrade.type, downgrade.name, downgrade.application_name)}
          </li>
        ))}
      </ul>
      <Banner intent="warning">{t('components.billing.plans.this_cant_be_undone')}</Banner>
    </div>
  );
}
