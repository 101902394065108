import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProviderContext } from '@knack/asterisk-react';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { cn } from '@/utils/tailwind';
import CarouselAvatar0Webp from '@/assets/img/sign-up-carousel-0-avatar.jpeg?w=64&h=64&format=webp&imagetools';
import CarouselAvatar0 from '@/assets/img/sign-up-carousel-0-avatar.jpeg?w=64&h=64&imagetools';
import CarouselAvatar1Webp from '@/assets/img/sign-up-carousel-1-avatar.jpeg?w=64&h=64&format=webp&imagetools';
import CarouselAvatar1 from '@/assets/img/sign-up-carousel-1-avatar.jpeg?w=64&h=64&imagetools';
import CarouselAvatar2Webp from '@/assets/img/sign-up-carousel-2-avatar.jpeg?w=64&h=64&format=webp&imagetools';
import CarouselAvatar2 from '@/assets/img/sign-up-carousel-2-avatar.jpeg?w=64&h=64&imagetools';
import Badge0 from '@/assets/svg/badge-0.svg';
import Badge1 from '@/assets/svg/badge-1.svg';
import Badge2 from '@/assets/svg/badge-2.svg';
import Badge3 from '@/assets/svg/badge-3.svg';
import Badge4 from '@/assets/svg/badge-4.svg';
import Badge5 from '@/assets/svg/badge-5.svg';
import Badge6 from '@/assets/svg/badge-6.svg';
import G2Rating from '@/assets/svg/g2-rating.svg';

const carouselAvatars: [string, string][] = [
  [CarouselAvatar0Webp, CarouselAvatar0],
  [CarouselAvatar1Webp, CarouselAvatar1],
  [CarouselAvatar2Webp, CarouselAvatar2]
];

type CarouselItemProps = {
  title: string;
  subtitle: string;
  avatars: [string, string];
  name: string;
  position: string;
  isActive: boolean;
  shouldAnimate: boolean;
};

function CarouselItem({
  title,
  subtitle,
  avatars,
  name,
  position,
  isActive,
  shouldAnimate
}: CarouselItemProps) {
  const { isDarkMode } = useContext(ThemeProviderContext);

  return (
    <div
      className={cn(
        'w-full md:absolute md:top-1/2 md:w-auto md:-translate-y-1/2 md:transform',
        !isDarkMode && 'text-inverted'
      )}
    >
      <div
        className={cn('bg-gradient-3 p-6 shadow md:rounded-br-[40px] md:rounded-tl-[40px]', {
          'carousel-slide-in-animation': isActive && shouldAnimate,
          'carousel-slide-out-animation': !isActive && shouldAnimate
        })}
      >
        <div className="md:max-w-auto mx-auto max-w-[500px]">
          <h3 className="mb-3 text-xl font-medium">{title}</h3>
          <p className="mb-5">“{subtitle}”</p>
          <div className="flex gap-3">
            <picture>
              <source srcSet={avatars[0]} type="image/webp" />
              <source srcSet={avatars[1]} type="image/jpeg" />
              <img className="size-8 rounded-full" src={avatars[1]} alt={name} />
            </picture>
            <div className="flex flex-col">
              <p className="text-xs font-bold">{name}</p>
              <p className="text-xs font-medium">{position}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SignUpRightSection() {
  const [t] = useTranslation();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const shouldAnimateCarousel = useMediaQuery('(min-width: 768px)');
  const { isDarkMode } = useContext(ThemeProviderContext);

  useEffect(() => {
    if (!shouldAnimateCarousel) return;

    const interval = setInterval(() => {
      setCarouselIndex((prev) => (prev + 1) % carouselAvatars.length);
    }, 10000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [carouselIndex, shouldAnimateCarousel]);

  return (
    <div
      className={cn(
        'relative flex flex-1 overflow-hidden',
        isDarkMode && 'border-l-emphasis border-opacity-50 md:border-l-[1px]'
      )}
    >
      <div
        aria-hidden="true"
        className={cn(
          'absolute inset-0 -z-10 -scale-x-100 transform bg-gradient-3 opacity-15',
          isDarkMode && 'hidden'
        )}
      />
      <div className="flex flex-1 items-center justify-center md:px-10">
        <div className="flex flex-1 flex-col md:max-w-[500px] md:gap-14">
          <div className="hidden flex-col gap-6 md:flex">
            <img src={G2Rating} alt="G2 Rating" className="mx-auto h-11" />
            <div className="flex flex-col gap-2 text-center">
              <h2 className="text-2xl font-medium">{t('components.sign_up.right.title')}</h2>
              <p className="text-xs tracking-wide text-subtle">
                {t('components.sign_up.right.subtitle')}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="relative flex md:h-[320px] lg:h-[260px]">
              {shouldAnimateCarousel ? (
                carouselAvatars.map((avatars, i) => (
                  <CarouselItem
                    key={avatars[0]}
                    title={t(`components.sign_up.right.carousel_${i}_title`)}
                    subtitle={t(`components.sign_up.right.carousel_${i}_subtitle`)}
                    avatars={avatars}
                    name={t(`components.sign_up.right.carousel_${i}_name`)}
                    position={t(`components.sign_up.right.carousel_${i}_position`)}
                    isActive={carouselIndex === i}
                    shouldAnimate={shouldAnimateCarousel}
                  />
                ))
              ) : (
                <CarouselItem
                  title={t(`components.sign_up.right.carousel_0_title`)}
                  subtitle={t(`components.sign_up.right.carousel_0_subtitle`)}
                  avatars={carouselAvatars[0]}
                  name={t(`components.sign_up.right.carousel_0_name`)}
                  position={t(`components.sign_up.right.carousel_0_position`)}
                  isActive
                  shouldAnimate={false}
                />
              )}
            </div>
            <div className="hidden justify-center gap-3 md:flex">
              {carouselAvatars.map((avatars, i) => (
                <button
                  key={avatars[0]}
                  onClick={() => setCarouselIndex(i)}
                  className={cn('size-3 rounded-full bg-brand-200 transition-colors duration-200', {
                    'bg-brand-600': carouselIndex === i,
                    'hover:bg-brand-400': carouselIndex !== i
                  })}
                  aria-label={t('components.sign_up.right.carousel_control_label', { position: i })}
                  type="button"
                />
              ))}
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-x-2 gap-y-4 p-4 md:gap-x-4 md:px-0 md:pt-8">
            <img src={G2Rating} alt="G2 Rating" className="block h-9 md:hidden md:h-11" />

            <div className="flex flex-wrap items-center justify-center gap-2 md:gap-4">
              {[Badge0, Badge1, Badge2, Badge3, Badge4, Badge5, Badge6].map((badge, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <img key={i} src={badge} alt={`Badge ${i}`} className="w-11 md:w-14" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
