import { useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackApplication } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { MAX_APP_NAME_AND_SLUG_CHARS } from '@/utils/constants';
import { getErrorMessage } from '@/utils/errors';
import { formatSlug } from '@/utils/formatters';
import { AppModalDetailsInputs } from '@/pages/apps/app-modals/AppModalDetailsInputs';
import { AppModalFormButtons } from '@/pages/apps/app-modals/AppModalFormButtons';
import { useUniqueAppCheck } from '@/pages/apps/useUniqueAppCheck';

interface AppSettingsFormProps {
  handleDialogClose: () => void;
  app: KnackApplication;
}

export function AppSettingsForm({ handleDialogClose, app }: AppSettingsFormProps) {
  const [t] = useTranslation();
  const { updateApp } = useAppMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const { isUniqueAppName, isUniqueAppSlug } = useUniqueAppCheck(app.id);

  const appSettingsFormSchema = z
    .object({
      appName: z
        .string()
        .min(1, t('components.create_app.name_required'))
        .max(MAX_APP_NAME_AND_SLUG_CHARS, t('components.create_app.name_max_length')),
      appSlug: z
        .string()
        .min(1, t('components.create_app.slug_required'))
        .max(MAX_APP_NAME_AND_SLUG_CHARS, t('components.create_app.slug_max_length')),
      appDescription: z.string()
    })
    .refine(
      ({ appName }) => isUniqueAppName(appName),
      ({ appName }) => ({
        path: ['appName'],
        message: t('components.create_app.name_is_already_used', {
          appName
        })
      })
    )
    .refine(
      ({ appSlug }) => isUniqueAppSlug(appSlug),
      ({ appSlug }) => ({
        path: ['appSlug'],
        message: t('components.create_app.slug_is_already_used', {
          appSlug
        })
      })
    )
    .refine(({ appSlug }) => !formatSlug(appSlug).endsWith('-'), {
      path: ['appSlug'],
      message: t('components.create_app.app_slug_ends_with_hyphen')
    });

  type AppSettingsFormSchemaType = z.infer<typeof appSettingsFormSchema>;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    control
  } = useForm<AppSettingsFormSchemaType>({
    resolver: zodResolver(appSettingsFormSchema),
    defaultValues: {
      appName: app.name,
      appSlug: app.slug,
      appDescription: app.description
    }
  });

  const onSubmitHandler: SubmitHandler<AppSettingsFormSchemaType> = (data) => {
    updateApp.mutate(
      {
        ...data,
        id: app.id
      },
      {
        onSuccess: () => {
          handleDialogClose();
        },
        onError: (error) => {
          const message = getErrorMessage(error, t('components.app_settings.error_updating'));
          setErrorMessage(message);
        }
      }
    );
  };

  return (
    <form
      className="flex flex-1 flex-col"
      onSubmit={handleSubmit(onSubmitHandler)}
      data-testid="app-settings-form"
    >
      <Dialog.MainContent>
        <div className="space-y-6">
          <AppModalDetailsInputs
            register={register}
            isSubmitting={isSubmitting}
            errors={errors}
            control={control}
            appId={app.id}
            shouldRenderDescriptionInput
          />
          {errorMessage && (
            <Banner intent="destructive" icon={ExclamationIcon}>
              <Banner.Message>{errorMessage}</Banner.Message>
            </Banner>
          )}
        </div>
      </Dialog.MainContent>
      <AppModalFormButtons
        isSubmitButtonDisabled={isSubmitting || !isDirty}
        submitButtonI18nKey="actions.save"
        isLoading={updateApp.isPending || isSubmitting}
      />
    </form>
  );
}
