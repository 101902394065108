import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

async function getCustomerTaxIDs(accountId: string) {
  const { data } = await axios.get(`/v1/accounts/${accountId}/billing/tax_ids`, {
    withCredentials: true
  });
  return data;
}

export function useTaxIDsQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.tax_ids],
    queryFn: () => getCustomerTaxIDs(session.account.id)
  });
}
