export function ListIllustration() {
  return (
    <svg fill="none" viewBox="0 0 139 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g clipPath="url(#b)">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
          <rect x="12.5" y="13.008" width="114" height="15.546" fill="#E49981" />
          <path
            d="m18.527 23.013c2.6635-0.6553 9.0647-2.0089 13.361-2.1798 5.2727-0.2097 4.1695 2.2172 11.719 1.2241 0.2808-0.037 0.5586-0.127 0.8162-0.2447 5.4229-2.4775 12.279-0.1683 14.786 0.2045"
            stroke="#F4E6E1"
            strokeLinecap="round"
            strokeWidth="1.0364"
          />
          <rect x="18.5" y="38.5" width="12" height="2" rx="1" fill="#EDBBAB" />
          <rect
            transform="rotate(-90 34.5 45)"
            x="34.5"
            y="45"
            width="11"
            height="86"
            rx="2"
            fill="#F6DDD5"
          />
          <rect x="18.5" y="55.5" width="12" height="2" rx="1" fill="#E0E0E0" />
          <rect
            transform="rotate(-90 34.5 62)"
            x="34.5"
            y="62"
            width="11"
            height="86"
            rx="2"
            fill="#E0E0E0"
          />
          <rect x="18.5" y="72.5" width="12" height="2" rx="1" fill="#E0E0E0" />
          <rect
            transform="rotate(-90 34.5 79)"
            x="34.5"
            y="79"
            width="11"
            height="86"
            rx="2"
            fill="#E0E0E0"
          />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
