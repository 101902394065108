export const COUNTRIES: Record<string, string> = {
  AF: 'Afghanistan',
  AX: 'Land Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia Plurinational State Of',
  BQ: 'Bonaire Sint Eustatius And Saba',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos Keeling Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo The Democratic Republic Of The',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: 'C Te D Ivoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Cura Ao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands Malvinas',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island And Mcdonald Islands',
  VA: 'Holy See Vatican City State',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'Korea Democratic People S Republic Of',
  KR: 'Korea Republic Of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Lao People S Democratic Republic',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia The Former Yugoslav Republic Of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia Federated States Of',
  MD: 'Moldova Republic Of',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine State Of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'R Union',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barth Lemy',
  SH: 'Saint Helena Ascension And Tristan Da Cunha',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin French Part',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And The Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten Dutch Part',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And The South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania United Republic Of',
  TH: 'Thailand',
  TL: 'Timor Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela Bolivarian Republic Of',
  VN: 'Viet Nam',
  VG: 'Virgin Islands British',
  VI: 'Virgin Islands U S',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};

export const STRIPE_TAX_DATA = [
  {
    value: 'ad_nrt',
    name: 'Andorra',
    placeholder: 'A-123456-Z',
    description: 'NRT '
  },
  {
    value: 'ar_cuit',
    name: 'Argentina',
    placeholder: '12-3456789-01',
    description: 'ID'
  },
  {
    value: 'au_abn',
    name: 'Australia',
    placeholder: '12345678912',
    description: ' ABN'
  },
  {
    value: 'au_arn',
    name: 'Australia',
    placeholder: '123456789123',
    description: ' ARN'
  },
  {
    value: 'eu_vat',
    name: 'Austria',
    placeholder: 'ATU12345678',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Belgium',
    placeholder: 'BE0123456789',
    description: 'VAT'
  },
  {
    value: 'bo_tin',
    name: 'Bolivia',
    placeholder: '123456789',
    description: 'Tax ID'
  },
  {
    value: 'br_cnpj',
    name: 'Brazil',
    placeholder: '01.234.456/5432-10',
    description: 'CNPJ'
  },
  {
    value: 'br_cpf',
    name: 'Brazil',
    placeholder: '123.456.789-87',
    description: 'CPF'
  },
  {
    value: 'bg_uic',
    name: 'Bulgaria',
    placeholder: '123456789',
    description: 'UIC'
  },
  {
    value: 'eu_vat',
    name: 'Bulgaria',
    placeholder: 'BG0123456789',
    description: 'VAT'
  },
  {
    value: 'ca_bn',
    name: 'Canada',
    placeholder: '123456789',
    description: 'BN'
  },
  {
    value: 'ca_gst_hst',
    name: 'Canada',
    placeholder: '123456789RT0002',
    description: 'GST/HST'
  },
  {
    value: 'ca_pst_bc',
    name: 'Canada',
    placeholder: 'PST-1234-5678',
    description: 'PST (BC)'
  },
  {
    value: 'ca_pst_mb',
    name: 'Canada',
    placeholder: '123456-7',
    description: 'PST (MB)'
  },
  {
    value: 'ca_pst_sk',
    name: 'Canada',
    placeholder: '1234567',
    description: 'PST (SK)'
  },
  {
    value: 'ca_qst',
    name: 'Canada',
    placeholder: '1234567890TQ1234',
    description: 'QST'
  },
  {
    value: 'cl_tin',
    name: 'Chile',
    placeholder: '12.345.678-K',
    description: 'TIN'
  },
  {
    value: 'cn_tin',
    name: 'China',
    placeholder: '123456789012345678',
    description: 'Tax ID'
  },
  {
    value: 'co_nit',
    name: 'Colombia',
    placeholder: '123.456.789-0',
    description: 'NIT'
  },
  {
    value: 'cr_tin',
    name: 'Costa Rica',
    placeholder: '1-234-567890',
    description: 'Tax ID'
  },
  {
    value: 'eu_vat',
    name: 'Croatia',
    placeholder: 'HR12345678912',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Cyprus',
    placeholder: 'CY12345678Z',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Czech Republic',
    placeholder: 'CZ1234567890',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Denmark',
    placeholder: 'DK12345678',
    description: 'VAT'
  },
  {
    value: 'do_rcn',
    name: 'Dominican Republic',
    placeholder: '123-4567890-1',
    description: 'RCN'
  },
  {
    value: 'ec_ruc',
    name: 'Ecuador',
    placeholder: '1234567890001',
    description: 'RUC'
  },
  {
    value: 'eg_tin',
    name: 'Egypt',
    placeholder: '123456789',
    description: 'Tax ID'
  },
  {
    value: 'sv_nit',
    name: 'El Salvador',
    placeholder: '1234-567890-123-4',
    description: 'NIT'
  },
  {
    value: 'eu_vat',
    name: 'Estonia',
    placeholder: 'EE123456789',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Finland',
    placeholder: 'FI12345678',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'France',
    placeholder: 'FRAB123456789',
    description: 'VAT'
  },
  {
    value: 'ge_vat',
    name: 'Georgia',
    placeholder: '123456789',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Germany',
    placeholder: 'DE123456789',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Greece',
    placeholder: 'EL123456789',
    description: 'VAT'
  },
  {
    value: 'hk_br',
    name: 'Hong Kong',
    placeholder: '12345678',
    description: 'BR'
  },
  {
    value: 'eu_vat',
    name: 'Hungary',
    placeholder: 'HU12345678',
    description: 'VAT'
  },
  {
    value: 'hu_tin',
    name: 'Hungary',
    placeholder: '12345678-1-23',
    description: 'Adószám'
  },
  {
    value: 'is_vat',
    name: 'Iceland',
    placeholder: '123456',
    description: 'VAT'
  },
  {
    value: 'in_gst',
    name: 'India',
    placeholder: '12ABCDE3456FGZH',
    description: 'GST'
  },
  {
    value: 'id_npwp',
    name: 'Indonesia',
    placeholder: '12.345.678.9-012.345',
    description: 'NPWP'
  },
  {
    value: 'eu_vat',
    name: 'Ireland',
    placeholder: 'IE1234567AB',
    description: 'VAT'
  },
  {
    value: 'il_vat',
    name: 'Israel',
    placeholder: '000012345',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Italy',
    placeholder: 'IT12345678912',
    description: 'VAT'
  },
  {
    value: 'jp_cn',
    name: 'Japan',
    placeholder: '1234567891234',
    description: 'CN'
  },
  {
    value: 'jp_rn',
    name: 'Japan',
    placeholder: '12345',
    description: 'RN'
  },
  {
    value: 'jp_trn',
    name: 'Japan',
    placeholder: 'T1234567891234',
    description: 'TRN'
  },
  {
    value: 'ke_pin',
    name: 'Kenya',
    placeholder: 'P000111111A',
    description: 'PIN'
  },
  {
    value: 'eu_vat',
    name: 'Latvia',
    placeholder: 'LV12345678912',
    description: 'VAT'
  },
  {
    value: 'li_uid',
    name: 'Liechtenstein',
    placeholder: 'CHE123456789',
    description: 'UID'
  },
  {
    value: 'eu_vat',
    name: 'Lithuania',
    placeholder: 'LT123456789123',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Luxembourg',
    placeholder: 'LU12345678',
    description: 'VAT'
  },
  {
    value: 'my_frp',
    name: 'Malaysia',
    placeholder: '12345678',
    description: 'FRP'
  },
  {
    value: 'my_itn',
    name: 'Malaysia',
    placeholder: 'C 1234567890',
    description: 'ITN'
  },
  {
    value: 'my_sst',
    name: 'Malaysia',
    placeholder: 'A12-3456-78912345',
    description: 'SST'
  },
  {
    value: 'eu_vat',
    name: 'Malta',
    placeholder: 'MT12345678',
    description: 'VAT'
  },
  {
    value: 'mx_rfc',
    name: 'Mexico',
    placeholder: 'ABC010203AB9',
    description: 'RFC'
  },
  {
    value: 'eu_vat',
    name: 'Netherlands',
    placeholder: 'NL123456789B12',
    description: 'VAT'
  },
  {
    value: 'nz_gst',
    name: 'New Zealand',
    placeholder: '123456789',
    description: 'GST'
  },
  {
    value: 'no_vat',
    name: 'Norway',
    placeholder: '123456789MVA',
    description: 'VAT'
  },
  {
    value: 'pe_ruc',
    name: 'Peru',
    placeholder: '12345678901',
    description: 'RUC'
  },
  {
    value: 'ph_tin',
    name: 'Philippines',
    placeholder: '123456789012',
    description: 'Tax ID'
  },
  {
    value: 'eu_vat',
    name: 'Poland',
    placeholder: 'PL1234567890',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Portugal',
    placeholder: 'PT123456789',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Romania',
    placeholder: 'RO1234567891',
    description: 'VAT'
  },
  {
    value: 'ro_tin',
    name: 'Romania',
    placeholder: '1234567890123',
    description: 'Tax ID'
  },
  {
    value: 'ru_inn',
    name: 'Russia',
    placeholder: '1234567891',
    description: 'INN'
  },
  {
    value: 'ru_kpp',
    name: 'Russia',
    placeholder: '123456789',
    description: 'KPP'
  },
  {
    value: 'sa_vat',
    name: 'Saudi Arabia',
    placeholder: '123456789012345',
    description: 'VAT'
  },
  {
    value: 'rs_pib',
    name: 'Serbia',
    placeholder: '12-3456789-01',
    description: 'PIB'
  },
  {
    value: 'sg_gst',
    name: 'Singapore',
    placeholder: 'M12345678X',
    description: 'GST'
  },
  {
    value: 'sg_uen',
    name: 'Singapore',
    placeholder: '123456789F',
    description: 'UEN'
  },
  {
    value: 'eu_vat',
    name: 'Slovakia',
    placeholder: 'SK1234567891',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Slovenia',
    placeholder: 'SI12345678',
    description: 'VAT'
  },
  {
    value: 'si_tin',
    name: 'Slovenia',
    placeholder: '12345678',
    description: 'Tax ID'
  },
  {
    value: 'za_vat',
    name: 'South Africa',
    placeholder: '4123456789',
    description: 'VAT'
  },
  {
    value: 'kr_brn',
    name: 'South Korea',
    placeholder: '123-45-67890',
    description: 'BRN'
  },
  {
    value: 'es_cif',
    name: 'Spain',
    placeholder: 'A12345678',
    description: 'CIF'
  },
  {
    value: 'eu_vat',
    name: 'Spain',
    placeholder: 'ESA1234567Z',
    description: 'VAT'
  },
  {
    value: 'eu_vat',
    name: 'Sweden',
    placeholder: 'SE123456789123',
    description: 'VAT'
  },
  {
    value: 'ch_vat',
    name: 'Switzerland',
    placeholder: 'CHE-123.456.789 MWST',
    description: 'VAT'
  },
  {
    value: 'tw_vat',
    name: 'Taiwan',
    placeholder: '12345678',
    description: 'VAT'
  },
  {
    value: 'th_vat',
    name: 'Thailand',
    placeholder: '1234567891234',
    description: 'VAT'
  },
  {
    value: 'tr_tin',
    name: 'Turkey',
    placeholder: '0123456789',
    description: 'Tax ID'
  },
  {
    value: 'ua_vat',
    name: 'Ukraine',
    placeholder: '123456789',
    description: 'VAT'
  },
  {
    value: 'ae_trn',
    name: 'United Arab Emirates',
    placeholder: '123456789012345',
    description: 'TRN'
  },
  {
    value: 'eu_vat',
    name: 'UK (Northern Ireland)',
    placeholder: 'XI123456789',
    description: 'VAT'
  },
  {
    value: 'gb_vat',
    name: 'United Kingdom',
    placeholder: 'GB123456789',
    description: 'VAT'
  },
  {
    value: 'us_ein',
    name: 'United States',
    placeholder: '12-3456789',
    description: 'EIN'
  },
  {
    value: 'uy_ruc',
    name: 'Uruguay',
    placeholder: '123456789012',
    description: 'RUC'
  },
  {
    value: 've_rif',
    name: 'Venezuela',
    placeholder: 'A-12345678-9',
    description: 'RIF'
  },
  {
    value: 'vn_tin',
    name: 'Vietnam',
    placeholder: '1234567890',
    description: 'Tax ID'
  }
] as const;
