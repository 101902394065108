import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { ACCOUNT_SETTINGS_PAGE_TABS, usePageTabs } from '@/hooks/usePageTabs';
import { useIsSharedBuilderOnly } from '@/hooks/useSession';
import { PageHeader, PageLayout } from '@/components/layout';
import { Management } from '@/pages/settings/account/Management';
import { Overview } from '@/pages/settings/account/Overview';

type AccountTabs = 'account' | 'account/management';

export function Account({ activeTab = 'account' }: { activeTab?: AccountTabs }) {
  const [t, { language }] = useTranslation();
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const { tabConfig } = usePageTabs(ACCOUNT_SETTINGS_PAGE_TABS);

  const { Management: managementTab, ...rest } = tabConfig.tabs;

  const filteredNavTabs = isSharedBuilderOnly ? { ...tabConfig, tabs: { ...rest } } : tabConfig;

  return (
    <PageLayout>
      <PageHeader
        heading={`${t('navigation.account')} ${t('navigation.settings')}`}
        tabConfig={filteredNavTabs}
        activeTab={activeTab}
      />
      {activeTab === ACCOUNT_SETTINGS_PAGE_TABS.tabs.Overview.path && <Overview />}
      {activeTab === ACCOUNT_SETTINGS_PAGE_TABS.tabs.Management.path && <Management />}
      <Helmet>
        <title lang={language}>{`${t('navigation.account')} ${t('navigation.settings')} - ${t(
          'titles.app_name'
        )}`}</title>
      </Helmet>
    </PageLayout>
  );
}
