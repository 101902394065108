import { useTranslation } from 'react-i18next';
import { Card } from '@knack/asterisk-react';

import { useFreezeInfo } from '@/hooks/account/useFreezeInfo';
import { usePlans } from '@/hooks/usePlans';
import { DeleteAccountModal } from '@/pages/settings/account/delete-account-modal/DeleteAccountModal';
import { FreezeAccountModal } from '@/pages/settings/account/FreezeAccountModal';

export function Management() {
  const [t] = useTranslation();
  const { originalPlan } = usePlans();
  const isTrial = originalPlan?.id === 'trial';
  const { isFrozen, nextFreezeDate, canFreeze, freezeCost } = useFreezeInfo();

  return (
    <div className="grid gap-6 lg:grid-cols-2" data-testid="account-page">
      <Card className="flex flex-col gap-6 p-6 sm:p-6" data-testid="freeze-account-card">
        <h2 className="text-xl text-emphasis">
          {t(`components.account.${isFrozen ? 'unfreeze_account' : 'freeze_account'}`)}
        </h2>
        {isTrial && !isFrozen && (
          <div>
            <p data-testid="freeze-description-trial">
              {t('components.account.freeze_description_trial')}
            </p>
          </div>
        )}
        {!isFrozen && !isTrial && (
          <div>
            <p data-testid="freeze-description-freeze">
              {t('components.account.freeze_description_freeze', {
                cost: freezeCost
              })}
            </p>
          </div>
        )}
        {isFrozen && (
          <div>
            <p data-testid="freeze-description-unfreeze">
              {t('components.account.freeze_description_unfreeze', {
                cost: freezeCost
              })}
            </p>
          </div>
        )}
        {!canFreeze && !isFrozen && (
          <div>
            <p data-testid="can-not-freeze-yet">
              {t('components.account.can_not_freeze_yet', {
                date: nextFreezeDate
              })}
            </p>
          </div>
        )}
        <div className="flex flex-wrap items-center gap-4">
          <FreezeAccountModal disabled={!canFreeze && !isFrozen} />
          <p>
            {isTrial
              ? t('components.account.freeze_cost_trial')
              : t('components.account.freeze_cost', {
                  cost: isFrozen ? originalPlan?.price : freezeCost
                })}
          </p>
        </div>
      </Card>
      <Card
        className="flex flex-col content-start gap-6 p-6 sm:p-6"
        data-testid="delete-account-card"
      >
        <h2 className="text-xl text-emphasis">{t('components.account.delete_account')}</h2>
        <div className="flex h-full flex-col items-start justify-between gap-6">
          <p>{t('components.account.delete_description')}</p>
          <div className="flex items-center gap-4">
            <DeleteAccountModal />
          </div>
        </div>
      </Card>
    </div>
  );
}
