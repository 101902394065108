import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Route } from '@/enums';
import { useSession } from '@/hooks/useSession';
import { ProfileAvatar } from '@/components/ui/ProfileAvatar';

export function ChecklistsTitle() {
  const session = useSession();
  const [t] = useTranslation();

  return (
    <div className="flex items-center gap-3">
      <Link to={{ pathname: `/${Route.Settings}/${Route.Account}` }}>
        <ProfileAvatar
          firstName={session.user.firstName}
          email={session.user.email}
          avatarUri={session.user.avatarUri}
        />
      </Link>

      <div className="flex flex-col">
        <div className="text-sm font-medium">
          {session.user.firstName
            ? t('components.checklists.header_with_name', { name: session.user.firstName })
            : t('components.checklists.header_without_name')}
        </div>
        <div className="text-xs">{t('components.checklists.subheader')}</div>
      </div>
    </div>
  );
}
