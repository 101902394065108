import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCheck as CheckIcon } from 'react-icons/md';
import { zodResolver } from '@hookform/resolvers/zod';
import { Badge, Button, Card, InputPassword, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  useChangePasswordMutation,
  type UpdatePasswordParams
} from '@/hooks/api/mutations/useChangePasswordMutation';
import { useServerErrors } from '@/hooks/api/useServerErrors';
import { useSession } from '@/hooks/useSession';
import { cn } from '@/utils/tailwind';
import { FormControl } from '@/components/ui/FormControl';
import { DisableTwoFactorAuth } from '@/pages/settings/security/two-factor-auth/DisableConfirm';
import { EnableTwoFactorAuth } from '@/pages/settings/security/two-factor-auth/EnableTwoFactorAuth';

export function Security() {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { getError } = useServerErrors();
  const session = useSession();
  const { mutate: updatePasword, isPending: isSaving } = useChangePasswordMutation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const hasSetPassword = session?.user?.hasSetPassword;

  const schema = z
    .object({
      oldPassword: !hasSetPassword ? z.optional(z.string()) : z.string(),
      newPassword: z
        .string()
        .min(1, t('components.auth.password_required'))
        .min(8, t('components.auth.password_min_length_error')),
      confirmPassword: z
        .string()
        .min(1, t('components.auth.password_confirmation_required'))
        .min(8, t('components.auth.password_min_length_error'))
    })
    .refine((data) => data.confirmPassword === data.newPassword, {
      message: t('components.auth.passwords_do_not_match'),
      path: ['confirmPassword']
    })
    .refine((data) => data.oldPassword !== data.newPassword, {
      message: t('components.auth.can_not_use_current_password'),
      path: ['oldPassword']
    });

  type FormSchemaType = z.infer<typeof schema>;

  const {
    register,
    reset,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setError
  } = useForm<FormSchemaType>({
    resolver: zodResolver(schema),
    shouldFocusError: false
  });

  function onSubmit(formData: UpdatePasswordParams) {
    if (!isValid) {
      return;
    }

    updatePasword(formData, {
      onSuccess: () => {
        presentToast({
          title: hasSetPassword
            ? t('components.account.password_change_success')
            : t('components.change_password.set_password_success')
        });
        reset();
      },
      onError: (err: any) => {
        if (err?.response?.status !== 403) {
          const errorCode = err?.response?.data?.errors[0]?.message;
          const error = getError(errorCode);
          if (error?.key === 'wrong_password') {
            setError('oldPassword', { message: error.message });
          }
          if (error?.key === 'common_word') {
            setError('newPassword', { message: error?.message });
          }
          setHasSubmitted(true);
        }
      }
    });
  }

  return (
    <>
      <div key="security" className="grid gap-6 lg:grid-cols-2" data-testid="security-page">
        <Card className="flex flex-col p-6 sm:p-6">
          <h2 className="mb-6 text-xl text-emphasis">
            {hasSetPassword
              ? t('components.change_password.title')
              : t('components.change_password.set_title')}
          </h2>
          {!hasSetPassword && (
            <p className="mb-6 text-subtle">
              {t('components.change_password.set_password_description')}
            </p>
          )}
          <form
            id="password-change-form"
            // We need to let handleSubmit get triggered on change to get error validation prior to submitting
            onChange={handleSubmit(() => {})}
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6 pb-6"
          >
            {hasSetPassword && (
              <FormControl>
                <FormControl.Label
                  htmlFor="current-password"
                  intent={errors.oldPassword && hasSubmitted ? 'destructive' : undefined}
                >
                  {t('components.change_password.current_password')}
                </FormControl.Label>
                <InputPassword
                  disabled={isSaving}
                  id="current-password"
                  title={t('components.change_password.current_password')}
                  size="sm"
                  {...register('oldPassword')}
                />
                {errors.oldPassword && hasSubmitted && (
                  <FormControl.Message type="error">
                    {errors?.oldPassword?.message}
                  </FormControl.Message>
                )}
              </FormControl>
            )}
            <FormControl>
              <FormControl.Label
                htmlFor="new-password"
                intent={errors.newPassword && hasSubmitted ? 'destructive' : undefined}
              >
                {t('components.change_password.new_password')}
              </FormControl.Label>
              <InputPassword
                data-private
                disabled={isSaving}
                id="new-password"
                data-testid="security-new-password"
                title={t('components.change_password.new_password')}
                size="sm"
                {...register('newPassword')}
              />
              {errors.newPassword && hasSubmitted && (
                <FormControl.Message type="error">
                  {errors?.newPassword?.message}
                </FormControl.Message>
              )}
            </FormControl>
            <FormControl>
              <FormControl.Label
                htmlFor="confirm-password"
                intent={errors.confirmPassword && hasSubmitted ? 'destructive' : undefined}
              >
                {t('components.change_password.confirm_password')}
              </FormControl.Label>
              <InputPassword
                data-private
                disabled={isSaving}
                id="confirm-password"
                data-testid="security-confirm-new-password"
                title={t('components.change_password.confirm_password')}
                size="sm"
                {...register('confirmPassword')}
              />
              {errors.confirmPassword && hasSubmitted && (
                <FormControl.Message type="error">
                  {errors.confirmPassword.message}
                </FormControl.Message>
              )}
            </FormControl>
          </form>
          <div className="flex flex-col gap-2">
            {hasSetPassword && (
              <div className="flex gap-2">
                <p
                  className={cn('block text-xs', {
                    'text-success-default': !errors.oldPassword && isDirty
                  })}
                >
                  {t('components.auth.password_cannot_be_previous')}
                </p>
                {!errors.oldPassword && isDirty && <CheckIcon className="fill-green-700" />}
              </div>
            )}
            <div className="flex gap-2">
              <p
                className={cn('block text-xs', {
                  'text-success-default': !errors.newPassword && isDirty
                })}
              >
                {t('components.auth.password_min_length_error')}
              </p>
              {!errors.newPassword && isDirty && <CheckIcon className="fill-green-700" />}
            </div>
            <div className="flex gap-2">
              <p
                className={cn('block text-xs', {
                  'text-success-default': !errors.confirmPassword && isDirty
                })}
              >
                {t('components.auth.password_must_match_error')}
              </p>
              {!errors.confirmPassword && isDirty && <CheckIcon className="fill-green-700" />}
            </div>
          </div>
        </Card>
        <Card className="flex flex-col gap-6 p-6 sm:p-6">
          <div className="flex items-center justify-between">
            <h2 className="text-xl text-emphasis">
              {t('components.two_factor_authentication.title')}
            </h2>
            <Badge
              className="text-xs"
              intent={session?.user?.isTwoFactorAuthEnabled ? 'success' : 'neutral'}
            >
              {session?.user?.isTwoFactorAuthEnabled ? t('status.enabled') : t('status.disabled')}
            </Badge>
          </div>

          <div>
            <p>{t('components.two_factor_authentication.description')}</p>
          </div>

          {session?.user?.isTwoFactorAuthEnabled ? (
            <DisableTwoFactorAuth />
          ) : (
            <EnableTwoFactorAuth />
          )}
        </Card>
      </div>

      <Button
        disabled={isSaving || !isDirty || !isValid}
        isLoading={isSaving}
        type="submit"
        form="password-change-form"
        className="mt-6 max-w-40"
        data-testid="security-save-button"
      >
        {t('actions.save_changes')}
      </Button>
    </>
  );
}
