import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SvgGradient } from '@knack/asterisk-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { App } from '@/App';
import { AppProviders } from '@/AppProviders';

import '@/i18n';

import { Router } from '@/Router';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppProviders>
          <App>
            <SvgGradient gradientId="gradient-1" />
            <SvgGradient gradientId="gradient-2" />
            <SvgGradient gradientId="gradient-2-secondary" />
            <Router />
          </App>
        </AppProviders>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
