import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import {
  type IssueWithProductReasons,
  type IssueWithSupportReasons,
  type MovedToCompetitorReasons,
  type ReasonsForDeleting
} from '@/enums';
import { type Account } from '@/types/account';
import { type User } from '@/types/user';
import { useSession } from '@/hooks/useSession';

const reasonsForAccountDeletion = {
  price: 'Price',
  'issue-with-product': 'Product not good enough',
  'issue-with-support': 'Not enough support/Training',
  'project-ended': 'Project ended',
  'job-change': 'Job change/Left company',
  competitor: 'Moved to competitor',
  'not-a-good-fit-for-us': 'Not a good fit for us',
  other: 'Other'
} as const;

export type AccountDeletionFeedback = {
  reasonForAccountDeletion: ReasonsForDeleting;
  notGoodFitForUsReason?: string;
  directFeedback?: string;
  otherCompetitor?: string;
  otherReason?: string;
  productIssue?: IssueWithProductReasons;
  supportIssue?: IssueWithSupportReasons;
  issueAdditionalInformation?: string;
  productIssueMissingFeature?: string;
  productIssueBugs?: string;
  supportIssueTicketID?: string;
  otherCompetitorMissingFeatures?: string;
  otherCompetitorReason?: MovedToCompetitorReasons;
};

async function deleteAccount(accountId: string, userId: string, feedback: AccountDeletionFeedback) {
  const { data } = await axios.put<{ account: Account; user: User }>(
    `/v1/accounts/${accountId}/delete/${userId}`,
    {
      id: userId,
      feedback: {
        ...feedback,
        reasonForAccountDeletion: reasonsForAccountDeletion[feedback.reasonForAccountDeletion]
      }
    },
    {
      withCredentials: true
    }
  );
  return data;
}

export function useDeleteAccountMutation() {
  const session = useSession();

  return useMutation({
    mutationFn: (feedback: AccountDeletionFeedback) =>
      deleteAccount(session.account.id, session.user.id, feedback)
  });
}
