import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { type AxiosError } from 'axios';
import { z } from 'zod';

import { useForgotPasswordMutation } from '@/hooks/api/mutations/useForgotPasswordMutation';
import { zodEmailRegex } from '@/utils/zod';

const useForgotPasswordForm = () => {
  const [t] = useTranslation();

  const forgotPasswordFormSchema = z.object({
    email: z
      .string()
      .min(1, t('components.auth.email_required'))
      .regex(zodEmailRegex, t('components.auth.email_invalid'))
  });

  type ForgotPasswordFormSchemaType = z.infer<typeof forgotPasswordFormSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError
  } = useForm<ForgotPasswordFormSchemaType>({
    resolver: zodResolver(forgotPasswordFormSchema)
  });

  const resetPassword = useForgotPasswordMutation();

  const onSubmitHandler: SubmitHandler<ForgotPasswordFormSchemaType> = async ({ email }) => {
    try {
      await resetPassword.mutateAsync(
        { email },
        {
          onError(error) {
            if ((error as AxiosError).response?.status === 429) {
              return setError('root', {
                type: t('components.sign_in.too_many_failed'),
                message: t('components.sign_in.try_again_later')
              });
            }
            return setError('root', {
              type: t('components.sign_in.unexpected_error'),
              message: t('components.sign_in.try_again_later')
            });
          }
        }
      );
    } catch (error) {
      // Without a try/catch here, isSubmitting is not set to false after an error
    }
  };

  return {
    resetPassword,
    register,
    handleSubmit,
    errors,
    isSubmitting,
    isSubmitSuccessful,
    onSubmitHandler
  };
};

export { useForgotPasswordForm };
