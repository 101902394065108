import { type FieldErrors } from 'react-hook-form';
import { MdLockClock as LockIcon } from 'react-icons/md';
import { Banner } from '@knack/asterisk-react';

interface FormErrorBannerProps {
  errors: FieldErrors;
  hideIcon?: boolean;
}

export function FormErrorBanner({ errors, hideIcon = false }: FormErrorBannerProps) {
  // "Root errors" is the way react-hook-form handles an error not associated to a field.
  // The value of errors.root.type can be either a number or a string. In this case, we are explicitly checking for a string, so any errors that are not strings will be ignored.

  if (!errors.root || typeof errors.root.type !== 'string') return null;

  return (
    <Banner
      className="mb-6"
      intent="destructive"
      icon={!hideIcon ? LockIcon : undefined}
      title={errors.root.type}
    >
      <Banner.Message>{errors.root.message}</Banner.Message>
    </Banner>
  );
}
