import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiCheckCircle as CheckCircleIcon, HiChevronRight as RightIcon } from 'react-icons/hi2';
import { ThemeProviderContext } from '@knack/asterisk-react';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { cn } from '@/utils/tailwind';

type Props = {
  sectionId: string;
  onClick: () => void;
};

export function ChecklistsSection({ sectionId, onClick }: Props) {
  const [t] = useTranslation();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const { isSectionCompleted, isSectionNotStarted, getSectionById } = useChecklists();

  const section = getSectionById(sectionId);

  const isCompleted = isSectionCompleted(sectionId);
  const isNotStarted = isSectionNotStarted(sectionId);

  return (
    <button
      onClick={onClick}
      type="button"
      className="relative overflow-hidden rounded transition-opacity hover:opacity-75"
      data-section-id={sectionId}
    >
      {!isCompleted && (
        <div className="absolute inset-0 bg-gradient-3 opacity-10 group-hover:opacity-5" />
      )}
      <div className="flex items-center justify-between p-4">
        <div
          className={cn('flex items-center gap-2 py-0.5 text-sm font-medium', {
            'text-brand-500': isCompleted,
            'text-opacity-50': isCompleted && !isDarkMode,
            'text-brand-300': isCompleted && isDarkMode
          })}
        >
          {isCompleted && <CheckCircleIcon size={16} className="fill-[url(#svg-gradient-2)]" />}
          {t(`components.checklists.sections.${sectionId}`)}
        </div>
        <div className="flex items-center gap-2">
          <div
            className={cn('p-1 text-xs font-medium text-brand-500', {
              'text-opacity-50': isCompleted && !isDarkMode,
              'text-brand-300': isCompleted && isDarkMode
            })}
          >
            {isNotStarted
              ? t('components.checklists.section_not_started')
              : t('components.checklists.section_progress', {
                  completed: section.completed,
                  total: section.steps.length
                })}
          </div>
          <RightIcon size={24} className="fill-[url(#svg-gradient-1)]" />
        </div>
      </div>
    </button>
  );
}
