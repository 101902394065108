import { Tooltip } from '@knack/asterisk-react';

interface CellTooltipProps {
  text?: string;
  children?: React.ReactNode;
}

function CellTooltip({ children, text }: CellTooltipProps) {
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <div className="inline-block max-w-[30ch] truncate align-middle">{children}</div>
      </Tooltip.Trigger>
      <Tooltip.Content className="max-w-[40ch] whitespace-normal text-center">
        {text ?? children}
      </Tooltip.Content>
    </Tooltip>
  );
}

export { CellTooltip };
