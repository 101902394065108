import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

export type SetApiPlanLimitPayload = {
  quantity: number;
};

async function setApiPlanLimit(payload: SetApiPlanLimitPayload) {
  const { data } = await axios.post('/v1/dashboard/billing/set-api-plan-limit', payload, {
    withCredentials: true
  });

  return data.success;
}

async function removeApiPlan() {
  const { data } = await axios.delete('/v1/dashboard/billing/remove-api-plan', {
    withCredentials: true
  });

  return data.success;
}

export function useApiPlanMutation() {
  const queryClient = useQueryClient();

  const onApiPlanChangeSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.account]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.overview]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.apiPlan]
    });
  };

  const setApiPlanLimitMutation = useMutation({
    mutationFn: (payload: SetApiPlanLimitPayload) => setApiPlanLimit(payload),
    onSuccess: () => {
      onApiPlanChangeSuccess();
    }
  });

  const removeApiPlanMutation = useMutation({
    mutationFn: removeApiPlan,
    onSuccess: () => {
      onApiPlanChangeSuccess();
    }
  });

  return { setApiPlanLimitMutation, removeApiPlanMutation };
}
