import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiCheck as CheckIcon, HiOutlineLogout as LogOutIcon } from 'react-icons/hi';
import { HiMoon as MoonIcon, HiSun as SunIcon } from 'react-icons/hi2';
import { DropdownMenu, ThemeProviderContext } from '@knack/asterisk-react';

import { useLogoutUserMutation } from '@/hooks/api/mutations/useLogoutUserMutation';
import { useSession } from '@/hooks/useSession';
import { IS_SMALL_SCREEN } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { ProfileCard } from '@/components/ui/ProfileCard';

export function NavItemProfile({ className }: { className?: string }) {
  const [t] = useTranslation();
  const session = useSession();
  const logout = useLogoutUserMutation();
  const { currentMode, setCurrentMode } = useContext(ThemeProviderContext);

  const handleSignOut = () => {
    logout.mutate({
      accountId: session.account.id,
      userId: session.user.id,
      isSharedBuilderOnly: !session.account.id
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        aria-label="User options"
        className={cn(
          'flex w-full overflow-hidden rounded-lg p-1 text-left hover:bg-subtle',
          className
        )}
        data-testid="auth-logout-user-email"
      >
        <ProfileCard
          firstName={session.user.firstName}
          lastName={session.user.lastName}
          email={session.user.email}
          avatarUri={session.user.avatarUri}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="left-0 z-30 min-w-[260px]" side="top" collisionPadding={16}>
        <DropdownMenu.SubMenu>
          <DropdownMenu.SubTrigger>
            {currentMode === 'light' ? (
              <SunIcon className="mr-2 h-4 w-4" data-testid="navigation-appearance" />
            ) : (
              <MoonIcon className="mr-2 h-4 w-4" data-testid="navigation-appearance" />
            )}
            <span>{t('navigation.appearance')}</span>
          </DropdownMenu.SubTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.SubContent
              alignOffset={IS_SMALL_SCREEN ? -310 : -80}
              sideOffset={IS_SMALL_SCREEN ? -180 : 0}
              className="w-48"
            >
              <DropdownMenu.Item onClick={() => setCurrentMode('light')}>
                <span>{t('navigation.light_mode')}</span>
                <DropdownMenu.Shortcut>
                  {currentMode === 'light' && <CheckIcon />}
                </DropdownMenu.Shortcut>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={() => setCurrentMode('dark')}>
                <span>{t('navigation.dark_mode')}</span>
                <DropdownMenu.Shortcut>
                  {currentMode === 'dark' && <CheckIcon />}
                </DropdownMenu.Shortcut>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={() => setCurrentMode('system')}>
                <span>{t('navigation.system_default')}</span>
                <DropdownMenu.Shortcut>
                  {currentMode === 'system' && <CheckIcon />}
                </DropdownMenu.Shortcut>
              </DropdownMenu.Item>
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        </DropdownMenu.SubMenu>
        <DropdownMenu.Item asChild onSelect={handleSignOut}>
          <div>
            <LogOutIcon className="mr-2 h-4 w-4" data-testid="auth-logout-button" />
            <span>{t('actions.sign_out')}</span>
          </div>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
