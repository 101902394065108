import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { type KnackApplication } from '@/types/apps';
import { useSession } from '@/hooks/useSession';

export type AiQueryParams = {
  query?: string;
  accountID: string;
  email: string;
};

async function createAppFromAiQuery(queryData: AiQueryParams) {
  const url = `/v1/accounts/${queryData.accountID}/applications/create-app-from-customer-prompt`;
  const { data } = await axios.post<KnackApplication>(url, queryData, {
    withCredentials: true
  });

  return data;
}

export function useAiQueryMutation() {
  const session = useSession();

  return {
    createAppFromAiQuery: useMutation({
      mutationFn: (query: string) =>
        createAppFromAiQuery({ query, accountID: session.account.id, email: session.user.email })
    })
  };
}
