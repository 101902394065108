import { Skeleton } from '@knack/asterisk-react';

export function SharedAppsSkeleton() {
  const NUMBER_OF_SHARED_BUILDERS = 3;
  return (
    <div className="space-y-20">
      {Array.from({ length: NUMBER_OF_SHARED_BUILDERS }, (_, index) => (
        <div key={index}>
          <div className="mb-7 flex items-center justify-between">
            <div className="flex max-w-full items-center">
              <Skeleton className="h-10 w-10 rounded-full" />
              <div className="ml-2 space-y-2">
                <Skeleton className="h-3 w-24" />
                <Skeleton className="h-2 w-44" />
              </div>
            </div>
            <Skeleton className="h-5 w-5 rounded-full" />
          </div>
          <div>
            <div className="mb-7 grid h-9 grid-cols-[248px_1fr_1fr_1fr_328px_1.5fr] content-center border-b border-subtle">
              <Skeleton className="h-2 w-9" />
              <Skeleton className="h-2 w-14 justify-self-end" />
              <Skeleton className="h-2 w-14 justify-self-end" />
              <Skeleton className="h-2 w-10 justify-self-end" />
              <Skeleton className="ml-6 h-2 w-20" />
              <Skeleton className="ml-5 h-2 w-14" />
            </div>
            <div className="space-y-9">
              <div className="grid grid-cols-[248px_1fr_1fr_1fr_328px_1.5fr]">
                <Skeleton className="h-3 w-36" />
                <Skeleton className="h-3 w-8 justify-self-end" />
                <Skeleton className="h-3 w-11 justify-self-end" />
                <Skeleton className="h-3 w-4 justify-self-end" />
                <Skeleton className="ml-6 h-3 w-60" />
              </div>
              <div className="grid grid-cols-[248px_1fr_1fr_1fr_328px_1.5fr]">
                <Skeleton className="h-3 w-48" />
                <Skeleton className="h-3 w-10 justify-self-end" />
                <Skeleton className="h-3 w-12 justify-self-end" />
                <Skeleton className="h-3 w-8 justify-self-end" />
                <Skeleton className="ml-6 h-3 w-72" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
