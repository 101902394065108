import { useEffect, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { type StripeCardElement } from '@stripe/stripe-js';
import axios from 'axios';
import isEqual from 'lodash.isequal';

import { type ProductPlan } from '@/types/account';
import {
  usePaymentCardMutation,
  type UpdatePaymentCardPayload
} from '@/hooks/api/mutations/usePaymentCardMutation';
import { type Coupon } from '@/hooks/api/mutations/useValidateCouponMutation';
import { useSession } from '@/hooks/useSession';
import { FormErrorBanner } from '@/components/forms';
import { FormControl } from '@/components/ui/FormControl';
import { InputStripeAddress } from '@/components/ui/InputStripeAddress';
import { InputStripeCard } from '@/components/ui/InputStripeCard';
import { InputValidateStripePromoCode } from '@/components/ui/InputValidateStripePromoCode';

export type PaymentFormState = {
  isFormDirty: boolean;
  isFormSubmitting: boolean;
};

export interface PaymentFormValues {
  name?: UpdatePaymentCardPayload['name'];
  address: UpdatePaymentCardPayload['address'];
  promoCode?: UpdatePaymentCardPayload['promoCode'];
}

interface PaymentFormProps {
  formId: string;
  onFormStateChange: (formState: PaymentFormState) => void;
  onFormSuccess: () => void;
  formDefaultValues?: PaymentFormValues;
  shouldShowPromoCodeInput?: boolean;
  selectedPlan?: ProductPlan;
}

export function PaymentForm({
  formId,
  shouldShowPromoCodeInput = false,
  onFormStateChange,
  onFormSuccess,
  formDefaultValues,
  selectedPlan
}: PaymentFormProps) {
  const session = useSession();
  const [t] = useTranslation();
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [promoCode, setPromoCode] = useState<string | null>('');
  const updateCardMutation = usePaymentCardMutation();

  const stripe = useStripe();
  const stripeElements = useElements();

  const { handleSubmit, setError, formState, setValue } = useForm();

  const { errors, isSubmitting, isDirty } = formState;

  useEffect(() => {
    onFormStateChange({
      isFormDirty: isDirty,
      isFormSubmitting: isSubmitting
    });
  }, [isDirty, isSubmitting, onFormStateChange]);

  const onSubmitHandler: SubmitHandler<any> = async () => {
    if (!isDirty) {
      return;
    }

    // Prevent form submission if Stripe hasn't loaded
    if (!stripe || !stripeElements) {
      return;
    }

    const addressElement = stripeElements.getElement('address');
    const addressData = await addressElement?.getValue();

    // Let address element display errors
    if (!addressData?.complete) {
      return;
    }

    let stripeSecret = '';

    // Get Stripe intent secret from the server before calling Stripe's API
    try {
      const { data: intentData } = await axios.get(
        `/v1/accounts/${session.account.id}/billing/createSetupIntent`,
        {
          withCredentials: true
        }
      );
      stripeSecret = intentData.secret;
    } catch (error) {
      setError('root', {
        type: '',
        message: 'Something went wrong. Please try again.'
      });
      return;
    }

    // Stripe intent secret was retrieved from our server, so we can now call Stripe's API to update the card
    const { setupIntent, error: stripeError } = await stripe.confirmCardSetup(stripeSecret, {
      payment_method: {
        card: stripeElements.getElement('card') as StripeCardElement
      }
    });

    if (stripeError) {
      setError('root', {
        type: '',
        message: stripeError.message
      });
      return;
    }

    // The call to Stripe to update the card was successful, so we can now update the payment information in our database
    if (setupIntent.status === 'succeeded') {
      const cardPayload: UpdatePaymentCardPayload = {
        stripe_token: setupIntent.payment_method as string,
        plan: selectedPlan?.id,
        name: addressData?.value.name,
        address: { ...addressData?.value.address }
      };

      if (promoCode && coupon && coupon !== null) {
        cardPayload.promoCode = promoCode;
      }

      try {
        const cardUpdated = await updateCardMutation.mutateAsync(cardPayload);
        if (!cardUpdated) {
          setError('root', {
            type: '',
            message: t('components.billing.payment.charge_rejected')
          });
          return;
        }
        onFormSuccess();
      } catch (error) {
        setError('root', {
          type: '',
          message: t('components.billing.payment.charge_rejected')
        });
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`Error confirming SetupIntent, status: ${setupIntent.status}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} id={formId} data-private>
      <FormControl className="mb-6">
        <FormControl.Label htmlFor="card">
          {t('components.billing.payment.card_number')}
        </FormControl.Label>
        <InputStripeCard
          onChange={() =>
            // Force form dirty
            setValue('card', '', {
              shouldDirty: true
            })
          }
        />
      </FormControl>
      <FormControl className="mb-6">
        <InputStripeAddress
          defaultValues={formDefaultValues}
          onChange={(addressElement) => {
            // Force form dirty if is different to default values
            if (!isEqual(addressElement.value, formDefaultValues)) {
              setValue('address', addressElement, {
                shouldDirty: true
              });
            }
          }}
        />
      </FormControl>
      {shouldShowPromoCodeInput && selectedPlan && (
        <InputValidateStripePromoCode
          selectedPlan={selectedPlan}
          onValidation={({ promoCodeValidated, couponValidated }) => {
            setCoupon(couponValidated);
            setPromoCode(promoCodeValidated);
          }}
        />
      )}
      <FormErrorBanner errors={errors} hideIcon />
    </form>
  );
}
