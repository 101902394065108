import { useEffect, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Checkbox, Dialog, Input, Label, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackApplication } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { MAX_APP_NAME_AND_SLUG_CHARS } from '@/utils/constants';
import { getErrorMessage } from '@/utils/errors';
import { FormControl } from '@/components/ui/FormControl';
import { AppModalFormButtons } from '@/pages/apps/app-modals/AppModalFormButtons';
import { useUniqueAppCheck } from '@/pages/apps/useUniqueAppCheck';

interface DuplicateAppFormProps {
  handleDialogClose: () => void;
  currentApp: KnackApplication;
}

export function DuplicateAppForm({ handleDialogClose, currentApp }: DuplicateAppFormProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { duplicateApp } = useAppMutation();
  const { isUniqueAppName } = useUniqueAppCheck();
  const [errorMessage, setErrorMessage] = useState('');

  const duplicateAppFormSchema = z.object({
    appName: z
      .string()
      .min(1, t('components.create_app.name_required'))
      .max(MAX_APP_NAME_AND_SLUG_CHARS, t('components.create_app.name_max_length'))
      .refine(
        (val) => isUniqueAppName(val),
        (val) => ({
          message: t('components.create_app.name_is_already_used', {
            appName: val
          })
        })
      )
  });

  type DuplicateAppFormSchemaType = z.infer<typeof duplicateAppFormSchema>;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue
  } = useForm<DuplicateAppFormSchemaType>({
    resolver: zodResolver(duplicateAppFormSchema)
  });

  const [options, setOptions] = useState({
    appCopyRecords: false,
    appCopyTasks: false,
    appPausedTasks: false
  });

  const onSubmitHandler: SubmitHandler<DuplicateAppFormSchemaType> = (data) => {
    duplicateApp.mutate(
      {
        ...data,
        ...options,
        copyAppId: currentApp.id
      },
      {
        onSuccess: () => {
          if (options.appCopyRecords) {
            presentToast({
              title: t('components.duplicate_app.app_creation_success_copying')
            });
          } else {
            presentToast({
              title: t('components.duplicate_app.app_creation_success')
            });
          }
          handleDialogClose();
        },
        onError: (error) => {
          const message = getErrorMessage(
            error,
            t('components.duplicate_app.error_duplicating_app', {
              appName: currentApp.name
            })
          );
          setErrorMessage(message);
        }
      }
    );
  };

  useEffect(() => {
    setValue('appName', `${currentApp.name} Copy`);
  }, [currentApp, setValue]);

  return (
    <form className="flex flex-1 flex-col" onSubmit={handleSubmit(onSubmitHandler)}>
      <Dialog.MainContent>
        <FormControl>
          <FormControl.Label htmlFor="app-name" className="focus-visible:outline-none" isRequired>
            {t('components.create_app.app_name')}
          </FormControl.Label>
        </FormControl>
        <Input
          id="app-name"
          type="text"
          title={t('components.create_app.app_name')}
          className="h-10 text-sm"
          disabled={isSubmitting}
          intent={errors.appName && 'destructive'}
          data-testid="myapps-duplicate-app-name-input"
          {...register('appName')}
        />
        {errors.appName && (
          <FormControl.Message type="error" className="text-sm">
            {errors.appName.message}
          </FormControl.Message>
        )}
        <FormControl className="mt-6 gap-8 space-y-6 font-medium">
          <FormControl.Label htmlFor="app-duplicate">
            {t('components.duplicate_app.duplicate')}
          </FormControl.Label>
          <Input.Container>
            <div className="flex flex-col gap-2">
              <div className="flex items-center">
                <Checkbox
                  data-testid="myapps-duplicate-app-records-checkbox"
                  id="records"
                  checked={options.appCopyRecords}
                  onCheckedChange={(e) => {
                    if (e !== 'indeterminate') {
                      setOptions((prev) => ({ ...prev, appCopyRecords: e }));
                    }
                  }}
                />
                <Label htmlFor="records" className="ml-2">
                  {t('components.duplicate_app.records')}
                </Label>
              </div>
              <div className="flex items-center">
                <Checkbox
                  data-testid="myapps-duplicate-app-tasks-checkbox"
                  id="tasks"
                  checked={options.appCopyTasks}
                  onCheckedChange={(e) => {
                    if (e !== 'indeterminate') {
                      setOptions((prev) => ({
                        ...prev,
                        appCopyTasks: e,
                        appPausedTasks: !e && false
                      }));
                    }
                  }}
                />
                <Label htmlFor="tasks" className="ml-2">
                  {t('components.duplicate_app.tasks')}
                </Label>
              </div>
              <div className="ml-6 flex items-center">
                <Checkbox
                  data-testid="myapps-duplicate-app-pause-checkbox"
                  id="pause"
                  checked={options.appPausedTasks}
                  onCheckedChange={(e) => {
                    if (e !== 'indeterminate') {
                      setOptions((prev) => ({ ...prev, appPausedTasks: e }));
                    }
                  }}
                  disabled={!options.appCopyTasks}
                />
                <Label htmlFor="pause" className="ml-2">
                  {t('components.duplicate_app.tasks_pause')}
                </Label>
              </div>
            </div>
          </Input.Container>
          {errorMessage && (
            <Banner intent="destructive" icon={ExclamationIcon}>
              <Banner.Message>{errorMessage}</Banner.Message>
            </Banner>
          )}
        </FormControl>
      </Dialog.MainContent>
      <AppModalFormButtons
        isSubmitButtonDisabled={isSubmitting}
        isLoading={duplicateApp.isPending || isSubmitting}
      />
    </form>
  );
}
