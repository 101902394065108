import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { type Account } from '@/types/account';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useBillingOverviewQuery } from '@/hooks/api/queries/useBillingOverviewQuery';
import {
  BILLING_PAGE_TABS,
  BILLING_PAGE_TABS_HIPAA,
  BILLING_PAGE_TABS_TRIAL,
  usePageTabs
} from '@/hooks/usePageTabs';
import { PageHeader, PageLayout } from '@/components/layout';
import { AddOns } from './addons/AddOns';
import { Invoices } from './invoices/Invoices';
import { Overview } from './overview/Overview';
import { OverviewSkeleton } from './overview/OverviewSkeleton';
import { PageHeaderSkeleton } from './PageHeaderSkeleton';
import { Payment } from './payment/Payment';
import { Plans } from './plans/Plans';

const getTabsToDisplay = (isSubscribed: boolean, isHipaa: boolean) => {
  if (!isSubscribed) return BILLING_PAGE_TABS_TRIAL;
  if (isHipaa) return BILLING_PAGE_TABS_HIPAA;
  return BILLING_PAGE_TABS;
};

export type PageTabParam = {
  path: string;
};

export type BillingTabs = '' | 'plans' | 'add-ons' | 'payment' | 'invoices' | 'hipaa';

export function Billing({ activeTab = '' }: { activeTab?: BillingTabs }) {
  const [t, { language }] = useTranslation();

  const { data: billingData, isLoading: isBillingDataLoading } = useBillingOverviewQuery();
  const { data: accountData, isLoading: isAccountDataLoading } = useAccountQuery();

  // Non-subscribed users (trials and internal accounts) have an empty `customer` object in the schema
  const isSubscribed =
    (billingData?.customer && Object.keys(billingData.customer).length > 0) ?? false;
  const isHipaa = accountData?.isHipaa;

  const { tabConfig, setTabConfig } = usePageTabs(getTabsToDisplay(isSubscribed, isHipaa || false));

  const pageTitle = (
    <Helmet>
      <title lang={language}>{`${t('navigation.billing')} ${t('navigation.settings')} - ${t(
        'titles.app_name'
      )}`}</title>
    </Helmet>
  );

  useEffect(() => {
    setTabConfig(getTabsToDisplay(isSubscribed, isHipaa || false));
  }, [isSubscribed, isHipaa, setTabConfig]);

  if ((!billingData && isBillingDataLoading) || (!accountData && isAccountDataLoading)) {
    return (
      <PageLayout>
        <PageHeaderSkeleton isSubscribed={isSubscribed} />
        <OverviewSkeleton isSubscribed={isSubscribed} />
        {pageTitle}
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageHeader heading={t('navigation.billing')} tabConfig={tabConfig} activeTab={activeTab} />

      {!activeTab && (
        <Overview
          accountData={accountData as Account}
          billingData={billingData}
          isSubscribed={isSubscribed}
        />
      )}
      {activeTab === BILLING_PAGE_TABS.tabs.Plans.path && (
        <Plans
          accountData={accountData as Account}
          hasPaymentMethod={!!billingData?.customer?.paymentMethod}
        />
      )}
      {activeTab === BILLING_PAGE_TABS.tabs.AddOns?.path && <AddOns />}
      {activeTab === BILLING_PAGE_TABS.tabs.Payment?.path && (
        <Payment accountData={accountData as Account} />
      )}
      {activeTab === BILLING_PAGE_TABS.tabs.Invoices?.path && (
        <Invoices
          billingEmail={billingData?.customer?.email ?? ''}
          billingCustomInfo={accountData?.billing?.custom_info ?? ''}
        />
      )}

      {pageTitle}
    </PageLayout>
  );
}
