import { Skeleton } from '@knack/asterisk-react';

export function PageHeaderSkeleton({ isSubscribed }: { isSubscribed: boolean }) {
  return (
    <div className="mb-6 flex flex-wrap items-center">
      <Skeleton className="mb-6 mr-12 h-6 w-24 rounded-full sm:mb-0" />
      <div className="flex flex-wrap items-center gap-8">
        <Skeleton className="h-8 w-20" />

        <Skeleton className="h-2 w-16" />
        {isSubscribed && (
          <>
            <Skeleton className="h-2 w-16" />
            <Skeleton className="h-2 w-16" />
          </>
        )}
      </div>
    </div>
  );
}
