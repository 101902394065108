import { useMemo } from 'react';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';

export function useFreezeInfo() {
  const { data: account } = useAccountQuery();
  const freezeCost = account?.product_plan?.freeze_cost;
  const nextFreezeInfo = useMemo(() => {
    if (!account?.billing?.last_freeze_date) {
      return {
        canFreeze: true
      };
    }
    const now = new Date();
    const nextFreezeDate = new Date(account?.billing?.last_freeze_date);
    nextFreezeDate.setFullYear(nextFreezeDate.getFullYear() + 1);
    const canFreeze = nextFreezeDate <= now;

    return {
      canFreeze,
      nextFreezeDate: canFreeze ? now : nextFreezeDate.toLocaleDateString()
    };
  }, [account?.billing?.last_freeze_date]);

  return {
    isFrozen: account?.status === 'frozen',
    freezeCost,
    ...nextFreezeInfo
  };
}
