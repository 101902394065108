import { Skeleton } from '@knack/asterisk-react';

export function OverviewSkeleton({ isSubscribed }: { isSubscribed: boolean }) {
  return (
    <div className="grid gap-6 lg:grid-cols-2">
      <Skeleton className="h-[340px] w-full lg:max-w-[468px]" />
      <Skeleton className="h-[340px] w-full lg:max-w-[468px]" />
      {isSubscribed && (
        <>
          <Skeleton className="h-[340px] w-full lg:max-w-[468px]" />
          <Skeleton className="h-[340px] w-full lg:max-w-[468px]" />
        </>
      )}
    </div>
  );
}
