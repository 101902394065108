import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';
import { type VariantProps } from 'class-variance-authority';

import { AppSettingsForm } from '@/pages/apps/app-modals/AppSettingsForm';
import { DeleteAppForm } from '@/pages/apps/app-modals/DeleteAppForm';
import { DeleteBuilderForm } from '@/pages/apps/app-modals/DeleteBuilderForm';
import { DuplicateAppForm } from '@/pages/apps/app-modals/DuplicateAppForm';
import { ManageBuildersForm } from '@/pages/apps/app-modals/ManageBuildersForm';
import { TasksQuickViewForm } from '@/pages/apps/app-modals/TasksQuickViewForm';
import { SelectedItemType, type SelectedItem } from '@/pages/apps/apps-table';

interface AppsDialogContentProps extends VariantProps<typeof Dialog.Content> {
  selectedItem: SelectedItem | null;
  headerTitle?: string;
  headerDescription?: string;
  handleDialogClose: () => void;
}

export interface DialogProps {
  width: 'sm' | 'lg';
  headerTitle: string;
  headerDescription: string;
  content?: React.ReactNode;
}

export function AppsDialogContent({
  selectedItem,
  children,
  handleDialogClose,
  ...props
}: AppsDialogContentProps) {
  const [t] = useTranslation();

  const dialogProps: DialogProps = useMemo(() => {
    switch (selectedItem?.type) {
      case SelectedItemType.Settings:
        return {
          width: 'lg',
          headerTitle: t('components.app_settings.app_settings'),
          headerDescription: selectedItem.app.name,
          content: <AppSettingsForm handleDialogClose={handleDialogClose} app={selectedItem.app} />
        };
      case SelectedItemType.ManageBuilders:
        return {
          width: 'sm',
          headerTitle: t('components.manage_builders.title'),
          headerDescription: selectedItem.app.name,
          content: <ManageBuildersForm app={selectedItem.app} />
        };
      case SelectedItemType.Duplicate:
        return {
          width: 'sm',
          headerTitle: t('components.duplicate_app.title'),
          headerDescription: t('components.duplicate_app.description', {
            app_name: selectedItem.app.name
          }),
          content: (
            <DuplicateAppForm handleDialogClose={handleDialogClose} currentApp={selectedItem.app} />
          )
        };
      case SelectedItemType.Delete:
        return {
          width: 'sm',
          headerTitle: t('components.delete_app.title'),
          headerDescription: t('components.delete_app.description', {
            app_name: selectedItem.app.name
          }),
          content: <DeleteAppForm handleDialogClose={handleDialogClose} app={selectedItem.app} />
        };
      case SelectedItemType.TasksQuick:
        return {
          width: 'sm',
          headerTitle: t('components.task_quick_view_modal.title'),
          headerDescription: selectedItem.app.name,
          content: <TasksQuickViewForm app={selectedItem.app} />
        };
      case SelectedItemType.DeleteBuilder:
        return {
          width: 'sm',
          headerTitle: t('components.delete_builder_modal.title'),
          headerDescription: selectedItem.app.name,
          content: (
            <DeleteBuilderForm handleDialogClose={handleDialogClose} app={selectedItem.app} />
          )
        };
      default:
        return { width: 'lg', headerTitle: '', headerDescription: '' };
    }
  }, [selectedItem, t, handleDialogClose]);

  const { width, headerTitle, headerDescription, content } = dialogProps;

  return (
    <Dialog.Content width={width} className="bg-default" {...props} tabIndex={undefined}>
      <Dialog.MainContent>
        {headerTitle && (
          <Dialog.Header>
            <Dialog.Title>{headerTitle}</Dialog.Title>
            {headerDescription && <Dialog.Description>{headerDescription}</Dialog.Description>}
          </Dialog.Header>
        )}
      </Dialog.MainContent>
      {content}
    </Dialog.Content>
  );
}
