import Cookies from 'js-cookie';

export function getReferral() {
  const params = new URLSearchParams(window.location.search);
  return (
    params.get('referral') || params.get('ref') || Cookies.get('referral') || document.referrer
  );
}

export function getEmbeddedId() {
  const params = new URLSearchParams(window.location.search);

  return params.get('embeddedId');
}

export function getWelcomePath() {
  const params = new URLSearchParams(window.location.search);

  return params.get('welcomePath');
}

export function getGAnalyticsProperties() {
  const keys = ['utm_source', 'utm_medium', 'utm_campaign'];

  const params = new URLSearchParams(window.location.search);

  return keys.map((k) => ({
    key: k,
    value: params.get(k) ?? Cookies.get(k)
  }));
}
