import { useCallback, useState } from 'react';

export function useNextRandomFromArray<T>(array: T[]): { next: () => T } {
  const [previousIndex, setPreviousIndex] = useState(-1);
  return {
    next: useCallback(
      function findNext() {
        const nextIndex = Math.floor(Math.random() * array.length);

        if (previousIndex === nextIndex) {
          return findNext();
        }
        setPreviousIndex(nextIndex);

        return array[nextIndex];
      },
      [array, previousIndex]
    )
  };
}
