import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type Account } from '@/types/account';
import { type KnackApplication } from '@/types/apps';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { getLoaderApiUrl } from '@/utils/axiosConfig';

async function getAccount(accountId: string, isSharedBuilderOnly: boolean): Promise<Account> {
  const domain = getLoaderApiUrl();
  const endpoint = isSharedBuilderOnly
    ? `/v1/accounts/dashboard/schemas`
    : `/v1/accounts/${accountId}/dashboard/schemas`;

  const { data } = await axios.get<{ account: Account }>(`${domain}${endpoint}`, {
    withCredentials: true
  });

  return data.account;
}

export function useAccountQuery() {
  const session = useSession();
  const isSharedBuilderOnly = !session.account.id;
  const queryClient = useQueryClient();
  const applications: KnackApplication[] | undefined = queryClient.getQueryData([
    queryKeys.apps.applications
  ]);

  return useQuery({
    queryKey: [queryKeys.account, session.account.id],
    queryFn: () => getAccount(session.account.id, isSharedBuilderOnly),
    staleTime: 1000 * 30,
    refetchInterval: () => (applications?.some((app) => app.isDeleting) ? 5000 : false),
    enabled: !!session.account.id || !!session.user.id // the query will be disabled as long as the session is empty
  });
}
