import { useTranslation } from 'react-i18next';
import { Button, Dialog, type ButtonIntent } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export function AppModalFormButtons({
  isLoading,
  isBackButton,
  isSubmitButtonDisabled,
  submitButtonI18nKey,
  submitButtonIntent,
  children,
  onBack,
  onSubmit
}: {
  isLoading?: boolean;
  isBackButton?: boolean;
  isSubmitButtonDisabled?: boolean;
  children?: React.ReactNode;
  onBack?: () => void;
  onSubmit?: () => void;
  submitButtonIntent?: ButtonIntent;
  submitButtonI18nKey?: string;
}) {
  const [t] = useTranslation();

  const closeOrBackButton = (
    <Button
      intent="minimal"
      size="sm"
      className="sm:px-4"
      onClick={onBack}
      disabled={isLoading}
      data-testid="myapps-app-cancel-modal-btn"
    >
      {isBackButton ? t('actions.back') : t('actions.cancel')}
    </Button>
  );

  return (
    <Dialog.Footer
      className={cn({
        'sm:justify-between': !!children
      })}
    >
      {children}
      <div className="flex gap-4">
        {isBackButton ? (
          closeOrBackButton
        ) : (
          <Dialog.Close asChild>{closeOrBackButton}</Dialog.Close>
        )}
        <Button
          intent={submitButtonIntent}
          size="sm"
          type="submit"
          className="sm:px-4"
          data-testid="create-app-from-sample-dialog-create-button"
          isLoading={isLoading}
          disabled={isSubmitButtonDisabled}
          onClick={onSubmit}
        >
          {t(submitButtonI18nKey || 'components.create_app.create_app')}
        </Button>
      </div>
    </Dialog.Footer>
  );
}
