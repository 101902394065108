export enum Route {
  Root = '/',
  Apps = 'apps',
  SharedApps = 'shared',
  Install = 'install',
  Settings = 'settings',
  Account = 'account',
  Security = 'security',
  Billing = 'billing',
  Plans = 'plans',
  AddOns = 'add-ons',
  Payment = 'payment',
  Invoices = 'invoices',
  Management = 'management',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  SetPassword = 'set-password',
  AccountDeleted = 'account-deleted',
  Resources = 'resources',
  GettingStarted = 'getting-started',
  CatchAll = '*'
}
