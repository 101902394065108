import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getLoaderApiUrl } from '@/utils/axiosConfig';

async function refreshRecordCount(appId: string) {
  const domain = getLoaderApiUrl();
  const endpoint = `${domain}/v1/applications/${appId}/records/count`;

  const { data } = await axios.get(endpoint, {
    withCredentials: true
  });

  return data;
}

export function useRefreshRecordsMutation() {
  const queryClient = useQueryClient();

  const refreshRecordsMutation = useMutation({
    mutationFn: (id: string) => refreshRecordCount(id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.apps.applications] });
    }
  });

  return {
    refreshRecords: refreshRecordsMutation
  };
}
