import { useState } from 'react';
import { useForm, type FieldValues, type SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner, Dialog, useToast } from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';
import { useBuilderMutation } from '@/hooks/api/mutations/useBuilderMutation';
import { useSession } from '@/hooks/useSession';
import { getErrorMessage } from '@/utils/errors';
import { AppModalFormButtons } from '@/pages/apps/app-modals/AppModalFormButtons';

export function DeleteBuilderForm({
  handleDialogClose,
  app
}: {
  handleDialogClose: () => void;
  app: KnackApplication;
}) {
  const [t] = useTranslation();
  const { name: appName, id: appId, accountId } = app;
  const { deleteBuilder } = useBuilderMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const { presentToast } = useToast();
  const session = useSession();

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();

  const onSubmitHandler: SubmitHandler<FieldValues> = () => {
    deleteBuilder.mutate(
      { appId, accountId, builderId: session.user.id },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.delete_builder_modal.toast_message_success', {
              app_name: appName
            })
          });
          handleDialogClose();
        },
        onError: (error) => {
          const message = getErrorMessage(
            error,
            t('components.manage_builders.error_removing_builder')
          );
          setErrorMessage(message);
        }
      }
    );
  };

  return (
    <form className="flex flex-1 flex-col" onSubmit={handleSubmit(onSubmitHandler)}>
      <Dialog.MainContent>
        <p>
          <Trans i18nKey="components.delete_builder_modal.message" values={{ app_name: appName }}>
            <strong>My app</strong>
          </Trans>
        </p>
        {errorMessage && (
          <Banner intent="destructive" icon={ExclamationIcon}>
            <Banner.Message>{errorMessage}</Banner.Message>
          </Banner>
        )}
      </Dialog.MainContent>

      <AppModalFormButtons
        isSubmitButtonDisabled={isSubmitting}
        submitButtonI18nKey="components.delete_builder_modal.remove_builder"
        submitButtonIntent="destructive"
        isLoading={deleteBuilder.isPending || isSubmitting}
      />
    </form>
  );
}
