import { Spinner } from '@knack/asterisk-react';
import { AddressElement } from '@stripe/react-stripe-js';
import {
  type StripeAddressElementChangeEvent,
  type StripeAddressElementOptions
} from '@stripe/stripe-js';

import { useScript } from '@/hooks/useScript';
import { type PaymentFormValues } from '@/pages/settings/billing/payment/PaymentForm';

interface InputStripeAddressProps {
  defaultValues?: PaymentFormValues;
  onChange?: (event: StripeAddressElementChangeEvent) => void;
}

export function InputStripeAddress({ defaultValues, onChange }: InputStripeAddressProps) {
  const googleMapsApiScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${
      import.meta.env.PUBLIC_STRIPE_GOOGLE_API_KEY
    }&libraries=places`,
    {
      removeOnUnmount: false
    }
  );

  const isGoogleMapsApiLoaded = googleMapsApiScriptStatus === 'ready';

  const addressOptions: StripeAddressElementOptions = {
    mode: 'shipping',
    defaultValues,
    ...(isGoogleMapsApiLoaded && {
      autocomplete: {
        mode: 'google_maps_api',
        apiKey: import.meta.env.PUBLIC_STRIPE_GOOGLE_API_KEY
      }
    })
  };

  if (googleMapsApiScriptStatus === 'loading') {
    return <Spinner data-testid="input-stripe-address-spinner" />;
  }

  return <AddressElement options={addressOptions} onChange={onChange} />;
}
