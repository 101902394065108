export function DashboardIllustration() {
  return (
    <svg fill="none" viewBox="0 0 139 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g clipPath="url(#b)">
          <rect x="14.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
          <rect x="14.5" y="13.008" width="114" height="15.546" fill="#9AC1F0" />
          <path
            d="m52.274 21c1.5827 1.5993 2.8918 2.4193 9.7115-0.7714 5.0931-2.3829 9.5651 3.3378 15.043 1.4884 6.9449-2.3446 7.3363 3.0253 12.693-2.1581"
            stroke="#fff"
            strokeLinecap="round"
          />
          <rect x="23.5" y="32" width="95" height="24" rx="2" fill="#EFEFF0" />
          <rect x="23.5" y="58" width="46.5" height="24" rx="2" fill="#EFEFF0" />
          <rect x="72" y="58" width="46.5" height="24" rx="2" fill="#EFEFF0" />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect x="14.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
