import { useCallback, useMemo } from 'react';

import { type KnackApplication } from '@/types/apps';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';

export function useUniqueAppCheck(filteredAppId?: string) {
  const { data: appsData } = useAppsQuery();

  const filteredApps = useMemo(
    () =>
      filteredAppId
        ? appsData?.filter((knackApp: KnackApplication) => knackApp.id !== filteredAppId)
        : appsData,
    [appsData, filteredAppId]
  );

  const isUniqueAppName = useCallback(
    (name: string) =>
      !filteredApps?.some(
        (app: KnackApplication) => app.name.trim().toLowerCase() === name.trim().toLowerCase()
      ),
    [filteredApps]
  );

  const isUniqueAppSlug = useCallback(
    (slug: string) =>
      !filteredApps?.some(
        (app: KnackApplication) => app.slug.trim().toLowerCase() === slug.trim().toLowerCase()
      ),
    [filteredApps]
  );

  return { isUniqueAppName, isUniqueAppSlug };
}
