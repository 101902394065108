import { Avatar } from '@knack/asterisk-react';

import { useGravatarQuery } from '@/hooks/api/queries/useGravatarQuery';

type ProfileAvatarProps = {
  email: string;
  firstName?: string;
  avatarUri?: string;
  size?: 'sm' | 'lg' | 'default' | null;
};

export function ProfileAvatar({ email, firstName, size, avatarUri }: ProfileAvatarProps) {
  const { data: gravatarData } = useGravatarQuery(avatarUri, email);
  const avatarSrc = avatarUri || gravatarData?.gravatarUrl;

  return (
    <Avatar size={size}>
      <Avatar.Image src={avatarSrc} alt="avatar" />
      <Avatar.Fallback className="text-lg" delayMs={200}>
        {firstName?.[0]?.toUpperCase() || email?.[0]?.toUpperCase()}
      </Avatar.Fallback>
    </Avatar>
  );
}
