import { Skeleton } from '@knack/asterisk-react';

export function PageHeaderSkeleton() {
  return (
    <>
      <div className="mb-12 flex flex-wrap items-center">
        <Skeleton className="mb-6 mr-12 h-6 w-20 rounded-full sm:mb-0" />
        <div className="flex flex-wrap items-center gap-5">
          <Skeleton className="mr-5 h-8 w-20" />
          <Skeleton className="h-2 w-16" />
        </div>
      </div>
      <div className="my-8 flex w-full flex-col justify-center gap-4 sm:flex-row md:my-0 md:w-auto md:justify-normal">
        <Skeleton className="h-10 w-56" />
        <Skeleton className="h-10 w-40" />
      </div>
    </>
  );
}
