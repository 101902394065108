import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type Credentials } from '@/types/auth';
import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getAnonymousFlagValue } from '@/utils/flagsmith';
import { addNewUserToHubspot } from '@/utils/hubspot';
import { getEmbeddedId, getReferral } from '@/utils/querystrings';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';

async function signUp({ credentials }: { credentials: Credentials }) {
  // This value is generated on load of the Sign-Up page, we need to send it to the server to ensure that the same
  // cohort that saw the test in the sign-up page get the checklists.
  const checklistFlagValue = await getAnonymousFlagValue('onboarding_checklists_v1');

  const { data } = await axios.post<{ session: Session }>(
    `/v1/dashboard/accounts/sign-up`,
    {
      ...credentials,
      isChecklistEnabled: checklistFlagValue === 'on',
      referral: getReferral(),
      embeddedId: getEmbeddedId()
    },
    { withCredentials: true }
  );

  return data.session;
}

export function useSignUpMutation() {
  const queryClient = useQueryClient();
  const { setIsCreatingFirstApp } = usePostSignUpContext();

  return useMutation({
    mutationFn: signUp,
    onSuccess: (data: Session) => {
      addNewUserToHubspot(data.user.email);
      queryClient.setQueryData([queryKeys.auth.session], data);
      setIsCreatingFirstApp(true);
    }
  });
}
