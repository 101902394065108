import { useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Dialog, Input, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackApplication } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { FormControl } from '@/components/ui/FormControl';
import { AppModalFormButtons } from '@/pages/apps/app-modals/AppModalFormButtons';

interface DeleteAppFormProps {
  handleDialogClose: () => void;
  app: KnackApplication;
}

export function DeleteAppForm({ handleDialogClose, app }: DeleteAppFormProps) {
  const [t] = useTranslation();
  const { deleteApp } = useAppMutation();
  const { name: appName } = app;
  const [errorMessage, setErrorMessage] = useState('');
  const { presentToast } = useToast();

  const deleteAppFormSchema = z.object({
    appName: z.string().refine(
      (val) =>
        // Trim and remove double/extra whitespaces from app name before comparing it with the user input
        val === appName.trim().replace(/\s+/g, ' '),
      {
        message: t('components.delete_app.app_name_not_match'),
        path: ['confirmAppName']
      }
    )
  });

  type DeleteAppFormSchemaType = z.infer<typeof deleteAppFormSchema>;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid }
  } = useForm<DeleteAppFormSchemaType>({
    resolver: zodResolver(deleteAppFormSchema)
  });

  const onSubmitHandler: SubmitHandler<DeleteAppFormSchemaType> = () => {
    deleteApp.mutate(app.id, {
      onSuccess: () => {
        presentToast({
          title: t('components.delete_app.app_deletion_progress')
        });
        handleDialogClose();
      },
      onError: () => {
        setErrorMessage(t('components.delete_app.error_deleting_app', { appName }));
      }
    });
  };

  return (
    <form className="flex flex-1 flex-col" onSubmit={handleSubmit(onSubmitHandler)}>
      <Dialog.MainContent>
        <div className="space-y-6">
          <p>
            <Trans i18nKey="components.delete_app.confirmation" values={{ app_name: appName }}>
              <strong>My app</strong>
            </Trans>
          </p>
          <FormControl className="flex flex-col">
            <FormControl.Label htmlFor="app-name" intent={errors.appName && 'destructive'}>
              {t('components.delete_app.type_to_confirm')}
            </FormControl.Label>
            <Input.Container>
              <Input
                id="app-name"
                type="text"
                placeholder={t('components.create_app.app_name')}
                title={t('components.create_app.app_name')}
                disabled={isSubmitting}
                intent={errors.appName && 'destructive'}
                {...register('appName')}
              />
            </Input.Container>
            {errors.appName && (
              <FormControl.Message type="error">{errors.appName.message}</FormControl.Message>
            )}
          </FormControl>
        </div>
        {errorMessage && (
          <Banner intent="destructive" icon={ExclamationIcon}>
            <Banner.Message>{errorMessage}</Banner.Message>
          </Banner>
        )}
      </Dialog.MainContent>
      <AppModalFormButtons
        isSubmitButtonDisabled={isSubmitting || !!errors.appName || !isValid}
        submitButtonI18nKey="components.apps.delete_app"
        submitButtonIntent="destructive"
        isLoading={deleteApp.isPending || isSubmitting}
      />
    </form>
  );
}
