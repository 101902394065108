export function DataManagementIllustration() {
  return (
    <svg fill="none" viewBox="0 0 139 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g clipPath="url(#b)">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
          <rect x="12.5" y="13.008" width="114" height="15.546" fill="#EA7B9F" />
          <path
            d="m50.5 21.071c1.9733-1.2857 7.2943-1.3378 13.214-0.5664 1.2495 0.1628 2.4158 0.3108 3.4899 0.455 5.018 0.6737 10.694 1.5834 15.699 0.8161l4.5969-0.7047"
            stroke="#FCE9EF"
            strokeLinecap="round"
            strokeWidth="1.2954"
          />
          <rect x="38.5" y="37" width="12" height="2" rx="1" fill="#D1D1D1" />
          <rect x="19.5" y="46" width="12" height="2" rx="1" fill="#D1D1D1" />
          <rect x="19.5" y="59" width="12" height="2" rx="1" fill="#D1D1D1" />
          <rect x="19.5" y="75" width="12" height="2" rx="1" fill="#D1D1D1" />
          <rect x="38.5" y="46" width="12" height="2" rx="1" fill="#EBEBEB" />
          <rect x="94.5" y="46" width="21" height="2" rx="1" fill="#EBEBEB" />
          <rect x="38.5" y="59" width="12" height="2" rx="1" fill="#EBEBEB" />
          <rect x="94.5" y="59" width="21" height="2" rx="1" fill="#EBEBEB" />
          <rect x="38.5" y="75" width="12" height="2" rx="1" fill="#EBEBEB" />
          <rect x="94.5" y="75" width="21" height="2" rx="1" fill="#EBEBEB" />
          <rect x="60.5" y="37" width="24" height="2" rx="1" fill="#D1D1D1" />
          <rect x="94.5" y="37" width="16" height="2" rx="1" fill="#D1D1D1" />
          <rect x="60.5" y="43" width="24" height="12" rx="1" fill="#EBEBEB" />
          <rect x="60.5" y="59" width="24" height="12" rx="1" fill="#EBEBEB" />
          <rect x="60.5" y="75" width="24" height="12" rx="1" fill="#EBEBEB" />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect x="12.5" y="13" width="114" height="74.1" rx="5.1818" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
