import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { Route } from '@/enums';
import { useResetPasswordMutation } from '@/hooks/api/mutations/useResetPasswordMutation';
import { queryKeys } from '@/hooks/api/queryKeys';

const useResetPasswordForm = ({
  token,
  isSetPassword = false
}: {
  token: string;
  isSetPassword?: boolean;
}) => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const resetPasswordFormSchema = z
    .object({
      newPassword: z
        .string()
        .min(1, t('components.auth.password_required'))
        .min(8, t('components.auth.password_min_length_error')),
      confirmPassword: z.string().min(1, t('components.auth.password_confirmation_required'))
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      path: ['confirmPassword'],
      message: t('components.auth.passwords_do_not_match')
    });

  type ResetPasswordFormSchemaType = z.infer<typeof resetPasswordFormSchema>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError
  } = useForm<ResetPasswordFormSchemaType>({
    resolver: zodResolver(resetPasswordFormSchema)
  });

  const resetPassword = useResetPasswordMutation({ isSetPassword });

  const onSubmitHandler: SubmitHandler<ResetPasswordFormSchemaType> = async (data) => {
    try {
      await resetPassword.mutateAsync(
        { token, ...data },
        {
          onSuccess: (response) => {
            if (isSetPassword) {
              queryClient.setQueryData([queryKeys.auth.session], response.session);
              navigate({
                pathname: Route.Root
              });
            }
          },
          onError() {
            setError('root.serverError', {
              type: t('components.sign_in.unexpected_error'),
              message: t('components.sign_in.try_again_later')
            });
          }
        }
      );
    } catch (error) {
      // Without a try/catch here, isSubmitting is not set to false after an error
    }
  };

  return {
    resetPassword,
    register,
    handleSubmit,
    errors,
    isSubmitting,
    isSubmitSuccessful,
    onSubmitHandler,
    setValue
  };
};

export { useResetPasswordForm };
