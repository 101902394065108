import React from 'react';

export function useHasOverflow(
  ref: React.MutableRefObject<HTMLElement | null>,
  direction: 'horizontal' | 'vertical' = 'vertical'
) {
  const [hasOverflow, setHasOverflow] = React.useState<boolean>();

  React.useLayoutEffect(() => {
    const { current } = ref;

    if (!current) {
      return;
    }

    setHasOverflow(
      direction === 'vertical'
        ? current.scrollHeight > current.clientHeight
        : current.scrollWidth > current.clientWidth
    );
  }, [ref, direction]);

  return hasOverflow;
}
