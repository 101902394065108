import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type Account } from '@/types/account';
import { useCsrfTokenQuery } from '@/hooks/api/queries/useCsrfTokenQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';

export type UpdatePasswordParams = {
  oldPassword?: string;
  newPassword: string;
  confirmPassword: string;
};

function translateToPayload(data: UpdatePasswordParams, csrfToken: string) {
  const { oldPassword, newPassword, confirmPassword } = data;
  return {
    ...(oldPassword && { old_password: { password: oldPassword } }),
    password: {
      password: newPassword,
      password_confirmation: confirmPassword
    },
    'X-CSRF-Token': csrfToken
  };
}

async function updatePassword(
  passwordData: UpdatePasswordParams,
  accountId: string,
  userId: string,
  csrfToken: string | undefined
) {
  if (!csrfToken) {
    return undefined;
  }

  const url = `/v1/accounts/${accountId ? `${accountId}/` : ''}session/${userId}`;

  const { data } = await axios.put<Account>(url, translateToPayload(passwordData, csrfToken), {
    withCredentials: true
  });

  return data;
}

export function useChangePasswordMutation() {
  const session = useSession();
  const queryClient = useQueryClient();
  const { data: csrfToken } = useCsrfTokenQuery();

  return useMutation({
    mutationFn: (passwordData: UpdatePasswordParams) =>
      updatePassword(passwordData, session.account.id, session.user.id, csrfToken),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.auth.session] });
    }
  });
}
