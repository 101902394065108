import { useCallback, useMemo } from 'react';

import { useSession } from '@/hooks/useSession';
import { type ChecklistSection, type ChecklistStep } from '@/components/checklists/types';

export const useChecklists = () => {
  const session = useSession();
  const checklists = session.user.marketing?.checklist;

  const [sections, steps, allCompletedSteps, totalCount] = useMemo(() => {
    const bySection: Record<string, ChecklistSection & any> = {};
    let all: string[] = [];
    let allSteps: ChecklistStep[] = [];

    (checklists?.sections ?? []).forEach((section) => {
      const completedSteps = section.steps
        .filter((step) => !!step.completed_at)
        .map((step) => step.definition.id);

      bySection[section.id] = {
        steps: section.steps,
        completed: completedSteps.length
      };

      all = all.concat(completedSteps);
      allSteps = allSteps.concat(section.steps);
    });

    return [
      bySection,
      allSteps,
      new Set(all),
      Object.values(bySection).flatMap((section) => section.steps).length
    ];
  }, [checklists]);

  const isStepCompleted = useCallback(
    (stepId: string): boolean => allCompletedSteps.has(stepId),
    [allCompletedSteps]
  );
  const getSectionById = useCallback((sectionId: string) => sections[sectionId], [sections]);
  const isStepLocked = useCallback(
    (step: ChecklistStep) =>
      !!step.definition.blocked_by && !allCompletedSteps.has(step.definition.blocked_by),
    [allCompletedSteps]
  );

  const isSectionCompleted = useCallback(
    (sectionId: string): boolean =>
      !sections[sectionId].steps.some((step: ChecklistStep) => step.completed_at === null),
    [sections]
  );
  const isSectionNotStarted = useCallback(
    (sectionId: string): boolean =>
      !sections[sectionId].steps.some(
        (step: ChecklistStep) => typeof step.completed_at === 'string'
      ),
    [sections]
  );

  return {
    isStepCompleted,
    getSectionById,
    isStepLocked,
    isSectionCompleted,
    isSectionNotStarted,
    totalCount,
    totalCompleted: allCompletedSteps.size,
    sections,
    steps
  };
};
