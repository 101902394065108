import { useMemo } from 'react';
import { useWatch, type Control, type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  HiCheckCircle as CheckIcon,
  HiQuestionMarkCircle as QuestionIcon,
  HiXCircle as XIcon
} from 'react-icons/hi2';
import { Input, InputWithDisabledText, Textarea, Tooltip } from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { formatSlug } from '@/utils/formatters';
import { FormControl } from '@/components/ui/FormControl';
import { useUniqueAppCheck } from '@/pages/apps/useUniqueAppCheck';

type AppDetailsInputs = {
  appName: string;
  appSlug: string;
  appDescription: string;
};

enum AppModalDetailsInputsErrorTypes {
  TOO_BIG = 'too_big'
}

interface AppModalDetailsInputsProps {
  isSubmitting?: boolean;
  register: UseFormRegister<AppDetailsInputs>;
  errors: FieldErrors<AppDetailsInputs>;
  control: Control<AppDetailsInputs>;
  nameHelperText?: string;
  appId: string;
  shouldRenderDescriptionInput?: boolean;
}

export function AppModalDetailsInputs({
  isSubmitting = false,
  register,
  errors,
  control,
  nameHelperText,
  appId,
  shouldRenderDescriptionInput = false
}: AppModalDetailsInputsProps) {
  const [t] = useTranslation();
  const session = useSession();
  const { isUniqueAppName, isUniqueAppSlug } = useUniqueAppCheck(appId);

  const watchAppName = useWatch<AppDetailsInputs>({
    control,
    name: 'appName'
  });
  const watchAppSlug = useWatch<AppDetailsInputs>({
    control,
    name: 'appSlug'
  });

  const shouldRenderSlugHelperIcon = useMemo(
    () =>
      watchAppSlug &&
      formatSlug(watchAppSlug) &&
      errors.appSlug?.type !== AppModalDetailsInputsErrorTypes.TOO_BIG,
    [errors.appSlug, watchAppSlug]
  );

  const baseKnackUrl = `https://${session.account.slug}.${import.meta.env.PUBLIC_LIVE_APP_SUFFIX}/`;

  return (
    <>
      <FormControl>
        <FormControl.Label htmlFor="app-name" intent={errors.appName && 'destructive'} isRequired>
          {t('components.create_app.app_name')}
        </FormControl.Label>
        <Input.Container>
          <Input
            data-testid="app-settings-name-field"
            id="app-name"
            type="text"
            title={t('components.create_app.app_name')}
            disabled={isSubmitting}
            intent={errors.appName && 'destructive'}
            {...register('appName')}
          />
          {watchAppName && (
            <Input.Icon>
              {isUniqueAppName(watchAppName) ? (
                <CheckIcon size={20} className="cursor-default fill-green-700" />
              ) : (
                <Tooltip>
                  <Tooltip.Trigger className="cursor-auto" type="button">
                    <XIcon size={20} className="cursor-default fill-red-500" />
                    <span className="sr-only">{t('keywords.tooltip')}</span>
                  </Tooltip.Trigger>
                  <Tooltip.Content className="bg-gray-900 text-white">
                    {t('components.create_app.name_is_already_used', {
                      appName: watchAppName
                    })}
                  </Tooltip.Content>
                </Tooltip>
              )}
            </Input.Icon>
          )}
        </Input.Container>
        {errors.appName && (
          <FormControl.Message type="error" data-testid="app-name-error">
            {errors.appName.message}
          </FormControl.Message>
        )}
        {nameHelperText && !errors.appName && (
          <FormControl.Message className="text-subtle">{nameHelperText}</FormControl.Message>
        )}
      </FormControl>
      <FormControl>
        <span className="inline-flex">
          <FormControl.Label
            htmlFor="app-url"
            className="flex"
            intent={errors.appSlug && 'destructive'}
            isRequired
          >
            {t('components.create_app.live_app_url')}
          </FormControl.Label>
          <Tooltip>
            <Tooltip.Trigger
              data-testid="app-settings-question-mark-tooltip"
              className="mb-2 ml-1 cursor-auto"
              type="button"
            >
              <QuestionIcon className="text-default" size={16} />
              <span className="sr-only">{t('keywords.tooltip')}</span>
            </Tooltip.Trigger>
            <Tooltip.Content data-testid="app-settings-question-mark-tooltip-text-div">
              {t('components.create_app.live_app_url_tooltip')}
            </Tooltip.Content>
          </Tooltip>
        </span>
        <Input.Container>
          <InputWithDisabledText
            data-testid="app-settings-live-app-url-field"
            id="app-url"
            disabledTextLeft={baseKnackUrl}
            title={t('components.create_app.live_app_url')}
            disabled={isSubmitting}
            intent={errors.appSlug && 'destructive'}
            {...register('appSlug', {
              onChange: (e) => {
                e.target.value = formatSlug(e.target.value);
              }
            })}
          />
          {shouldRenderSlugHelperIcon && (
            <Input.Icon>
              {isUniqueAppSlug(watchAppSlug) && !watchAppSlug.endsWith('-') ? (
                <CheckIcon size={20} className="cursor-default text-success-default" />
              ) : (
                <Tooltip>
                  <Tooltip.Trigger className="cursor-auto" type="button">
                    <XIcon size={20} className="cursor-default text-destructive" />
                    <span className="sr-only">{t('keywords.tooltip')}</span>
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    {watchAppSlug.endsWith('-')
                      ? t('components.create_app.app_slug_ends_with_hyphen')
                      : t('components.create_app.slug_is_already_used', {
                          appSlug: watchAppSlug
                        })}
                  </Tooltip.Content>
                </Tooltip>
              )}
            </Input.Icon>
          )}
        </Input.Container>
        {errors.appSlug && (
          <FormControl.Message type="error" data-testid="app-slug-error">
            {errors.appSlug.message}
          </FormControl.Message>
        )}
      </FormControl>
      {shouldRenderDescriptionInput && (
        <FormControl>
          <FormControl.Label htmlFor="app-description">
            {t('components.create_app.app_description')}
          </FormControl.Label>
          <Input.Container>
            <Textarea
              data-testid="app-settings-description-field"
              id="app-description"
              placeholder={t('components.create_app.app_description_placeholder')}
              className="h-28"
              rows={5}
              disabled={isSubmitting}
              {...register('appDescription')}
            />
          </Input.Container>
        </FormControl>
      )}
    </>
  );
}
