import { HiLightBulb as LightBulbIcon } from 'react-icons/hi2';

export function ChecklistsBeacon() {
  return (
    <>
      <div className="absolute inline-flex h-6 w-6 animate-ping rounded-full bg-brand-100" />
      <div className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-brand-500 text-white shadow-[0px_0px_1px_3px_rgba(231,200,226,1)]">
        <LightBulbIcon />
      </div>
    </>
  );
}
