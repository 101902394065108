import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { KnackLogo } from '@knack/asterisk-react';
import { AnimatePresence, motion } from 'framer-motion';

import { cn } from '@/utils/tailwind';
import { MainNav, NavItemProfile } from '@/components/navigation/main-nav';
import { TrialCountdownWidget } from '@/components/ui/TrialCountdownWidget';
import { HamburgerToggle } from './HamburgerToggle';

export function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    // Prevent body scrolling when the mobile nav is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const containerAnimationVariants = {
    open: {
      x: 0,
      transition: {
        duration: 0.2
      }
    },
    closed: {
      x: '100%',
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <>
      <div
        className={cn(
          'sticky top-0 z-10 flex h-[60px] flex-col justify-center bg-default px-4 transition-[background-color] duration-200 ease-in-out md:hidden',
          {
            'w-full bg-muted': isOpen
          }
        )}
      >
        <div className="flex flex-1 items-center justify-between">
          <NavLink to={{ pathname: '/', search }} aria-label="Homepage" className="text-brand">
            <KnackLogo />
          </NavLink>
          <HamburgerToggle isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div className="mt-auto h-[1px] w-full bg-subtle" />
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="mobile-nav"
            className="fixed top-[60px] z-20 h-[calc(100%-60px)] w-full overflow-y-scroll bg-muted p-4 pb-12 md:hidden"
            variants={containerAnimationVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <div className="flex h-full flex-col gap-6">
              <MainNav isMobile />
              <div className="mt-auto">
                <TrialCountdownWidget className="mt-0" />
                <div className="my-6 h-px w-full bg-emphasis" />
                <NavItemProfile className="mb-4" />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
