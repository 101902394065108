import { Crisp } from 'crisp-sdk-web';

import { type Session } from '@/types/session';

export const initCrisp = () => {
  // Remove HubSpot chat if it's present
  window.HubSpotConversations?.widget?.remove();
  if (!import.meta.env.PUBLIC_CRISP_ID) {
    return;
  }
  Crisp.configure(import.meta.env.PUBLIC_CRISP_ID);
};

export const setCrispUserData = (session: Session, signature: string) => {
  Crisp.user?.setEmail(session.user.email, signature);
  Crisp.user?.setNickname(`${session.user.firstName} ${session.user.lastName}`);
  Crisp.user?.setCompany(session.account.name, {});
};

export const removeCrisp = () => {
  if (!import.meta.env.PUBLIC_CRISP_ID) {
    return;
  }
  if (!Crisp.isCrispInjected()) {
    return;
  }
  Crisp.chat?.hide();
};
