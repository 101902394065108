import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';
import LogRocket from 'logrocket';
import pRetry from 'p-retry';

export const getStripeObject = async () => {
  try {
    const stripeObject = await pRetry(
      () => loadStripe(`${import.meta.env.PUBLIC_STRIPE_PUBLIC_KEY}`),
      {
        onFailedAttempt: (error) => {
          // eslint-disable-next-line no-console
          console.log(`Attempt ${error.attemptNumber} to load Stripe failed.`);
        },
        retries: 3
      }
    );
    return stripeObject;
  } catch (error) {
    LogRocket.captureException(error as Error);
    return null;
  }
};

export function getStripeOptions(isDarkMode: boolean): StripeElementsOptions {
  return {
    appearance: {
      rules: {
        '.Input': {
          marginBottom: '12px',
          borderRadius: '0.375rem',
          border: '1px solid #D3CFD2',
          fontFamily: 'Inter, Segoe UI, sans-serif',
          fontSize: '16px',
          padding: '10px 9px',
          backgroundColor: !isDarkMode ? '#FFFFFF' : '#1B181B',
          color: !isDarkMode ? '#1B181B' : '#FFFFFF'
        },
        '.Input::placeholder': {
          color: '#898088'
        },
        '.Input:hover': {
          border: '1px solid #A9A2A8'
        },
        '.Input:focus': {
          outline: '2px solid black',
          outlineOffset: '2px',
          border: '1px solid #D3CFD2',
          boxShadow: 'none'
        },
        '.Input--invalid': {
          border: `1px solid ${isDarkMode ? '#E1999F' : '#D12E3C'}`,
          boxShadow: 'none',
          color: isDarkMode ? '#E1999F' : '#D12E3C'
        },
        '.Error': {
          color: isDarkMode ? '#E1999F' : '#D12E3C'
        },
        '.Input--invalid:hover': {
          border: `1px solid ${isDarkMode ? '#E1999F' : '#D12E3C'}`
        },
        '.Input--invalid:focus': {
          outline: '2px solid #FFEBEE',
          outlineOffset: '2px'
        },
        '.Label': {
          textTransform: 'capitalize',
          fontFamily: 'Inter, Segoe UI, sans-serif',
          color: !isDarkMode ? '#574D55' : '#d3cfd2',
          marginBottom: '8px'
        }
      }
    },
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500'
      }
    ]
  };
}
