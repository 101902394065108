import {
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type Dispatch,
  type SetStateAction
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Banner, Button, Dialog, Input, RadioCardGroup, Skeleton } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { cn } from '@/utils/tailwind';
import {
  DEFAULT_SAMPLES,
  SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER,
  TOP_SAMPLE_APPS
} from '@/pages/apps/create-app/constants';

type Props = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const INDUSTRIES = [
  'financial_services',
  'marketing',
  'property_management',
  'software',
  'professional_services',
  'education',
  'government',
  'manufacturing',
  'retail',
  'healthcare',
  'non_profit',
  'construction'
] as const;

function NavItem({
  isActive,
  title,
  onClick,
  ...props
}: {
  isActive: boolean;
  title: string;
  onClick: () => void;
}) {
  return (
    <Button
      onClick={onClick}
      intent="minimalStandalone"
      className={cn(
        'mb-1 block w-full px-2 py-2.5 text-left hover:bg-subtle',
        isActive && 'bg-emphasis text-emphasis'
      )}
      data-active={isActive}
      {...props}
    >
      {title}
    </Button>
  );
}

type Tab = (typeof INDUSTRIES)[number] | 'suggested' | 'top' | 'all';

export function CreateAppFromSampleModal({ open, setIsOpen }: Props) {
  const [t] = useTranslation();
  const [selectedTab, setSelectedTab] = useState<Tab>('suggested');
  const { data, isLoading, error: sampleQueryError } = useSampleAppsQuery();
  const [search, setSearch] = useState('');
  const [selectedSample, setSelectedSample] = useState('');
  const session = useSession();
  const { createAppFromSample } = useAppMutation();
  const listRef = useRef<HTMLDivElement>(null);

  const sampleApps = useMemo(() => {
    let samples = data ?? [];
    const defaultSamples = samples.filter((sample: SampleApp) =>
      DEFAULT_SAMPLES.includes(sample.slug)
    );

    if (search) {
      return samples.filter(
        (app: SampleApp) =>
          app.title.toLowerCase().includes(search.toLowerCase()) ||
          app.description.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedTab === 'suggested') {
      const userIndustry = session.user.marketing?.questionnaire?.industry;

      const suggested = samples.filter(
        (sample: SampleApp) => userIndustry && sample.industries?.includes(userIndustry)
      );

      samples = suggested.length > 0 ? suggested : defaultSamples;
    } else if (selectedTab === 'top') {
      samples = samples.filter((sample: SampleApp) => TOP_SAMPLE_APPS.includes(sample.slug));
    } else if (selectedTab !== 'all') {
      samples = samples.filter((sample: SampleApp) => sample.industries?.includes(selectedTab));
    }

    return samples.sort((a: SampleApp, b: SampleApp) => {
      let indexA = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.indexOf(a.slug);
      let indexB = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.indexOf(b.slug);

      if (indexA === -1) {
        indexA = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.length;
      }

      if (indexB === -1) {
        indexB = SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER.length;
      }

      return indexA - indexB;
    });
  }, [data, selectedTab, search, session.user.marketing]);

  useEffect(() => {
    if (selectedTab !== 'all') {
      setSearch('');
    }

    setSelectedSample('');
  }, [selectedTab]);

  const handleCreateApp = () => {
    const sample = data?.find((s: SampleApp) => s.id === selectedSample);

    if (!sample) {
      return;
    }

    const sampleData = {
      appId: sample.id,
      templateSlug: sample.slug,
      appDescription: sample.description
    };
    createAppFromSample.mutate(sampleData, {
      onSuccess: async (response) => {
        if (shouldSendAnalytics(session.user.email)) {
          await window.analytics.track('New App From Sample Created', {
            app: sample.title,
            groupId: session.account.id
          });
        }

        window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
          response.app.slug
        }/schema/list`;
      }
    });
  };

  const handleSearchOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setSelectedTab('all');
  };

  const error = createAppFromSample.error || sampleQueryError;

  useEffect(() => {
    if (!error) {
      return;
    }

    listRef.current?.scrollTo?.({ top: 0, behavior: 'smooth' });
  }, [error]);

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <Dialog.Content width="lg">
        <Dialog.MainContent className="flex max-h-[75vh] flex-col p-0">
          <Dialog.Header className="flex flex-col border-b border-subtle p-6">
            <div className="text-xl font-medium text-emphasis">
              {t('components.create_app.select_sample_app')}
            </div>
            <div className="text-xs">
              {t('components.create_app.sample_app_dialog_description')}
            </div>
          </Dialog.Header>
          <div className="flex overflow-auto">
            <div className="hidden overflow-y-auto border-r border-subtle p-6 sm:block">
              <NavItem
                isActive={selectedTab === 'suggested'}
                title={t('components.create_app.sample_suggested')}
                onClick={() => setSelectedTab('suggested')}
                data-testid="sample-apps-suggested-nav-item"
              />
              <NavItem
                isActive={selectedTab === 'top'}
                title={t('components.create_app.sample_top')}
                onClick={() => setSelectedTab('top')}
                data-testid="sample-apps-top-nav-item"
              />
              <NavItem
                isActive={selectedTab === 'all'}
                title={t('components.create_app.sample_all')}
                onClick={() => setSelectedTab('all')}
                data-testid="sample-apps-all-nav-item"
              />
              <div className="mx-2 mb-3 mt-2 h-px bg-subtle" />
              <div className="mb-1 w-full rounded-lg p-2 text-xs text-subtle">
                {t('components.create_app.sample_filter')}
              </div>
              {INDUSTRIES.map((industry) => (
                <NavItem
                  key={industry}
                  isActive={selectedTab === industry}
                  onClick={() => setSelectedTab(industry)}
                  title={t(
                    `components.onboarding.questionnaire.questions.industry.options.${industry}`
                  )}
                  data-testid={`sample-apps-${industry}-nav-item`}
                />
              ))}
            </div>
            <div className="flex flex-1 flex-col gap-6 overflow-y-auto p-6" ref={listRef}>
              {error && (
                <Banner intent="destructive" data-testid="sample-apps-error">
                  <Banner.Message>{t('errors.generic_error')}</Banner.Message>
                </Banner>
              )}

              <Input
                value={search}
                onChange={handleSearchOnChange}
                placeholder={t('components.create_app.sample_search')}
                data-testid="sample-apps-search-input"
              />

              {selectedTab === 'all' && search && sampleApps.length === 0 && (
                <div className="flex size-full flex-col items-center justify-center gap-6">
                  <div className="text-5xl">🤔</div>
                  <div className="text-emphasis">
                    <Trans
                      i18nKey="components.create_app.sample_search_no_results"
                      values={{ search }}
                    >
                      <span className="font-semibold">search query</span>
                    </Trans>
                  </div>
                </div>
              )}

              {isLoading ? (
                <div
                  className="grid grid-cols-1 gap-6 md:grid-cols-2"
                  data-testid="sample-apps-skeleton"
                >
                  {Array.from(new Array(8), (item, i) => (
                    <Skeleton key={i} className="h-36" />
                  ))}
                </div>
              ) : (
                <RadioCardGroup
                  className="grid grid-cols-1 gap-6 md:grid-cols-2"
                  onValueChange={setSelectedSample}
                  value={selectedSample}
                  data-testid="sample-apps-list"
                >
                  {sampleApps.map((sample: SampleApp) => (
                    <RadioCardGroup.Card
                      contentClassName="pointer-events-auto"
                      key={sample.id}
                      value={sample.id}
                      width="100%"
                    >
                      <RadioCardGroup.Content
                        title={sample.title}
                        description={sample.description}
                      />
                      <a
                        onClick={(e) => e.stopPropagation()}
                        className="mt-2 w-fit text-sm underline underline-offset-[3px]"
                        href={sample.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t('components.create_app.more_info')}
                      </a>
                    </RadioCardGroup.Card>
                  ))}
                </RadioCardGroup>
              )}
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-4 border-t border-subtle">
          <Button
            disabled={createAppFromSample.isPending}
            onClick={() => setIsOpen(false)}
            intent="minimal"
            data-testid="sample-apps-close-button"
          >
            {t('actions.close')}
          </Button>
          <Button
            disabled={isLoading || !selectedSample}
            onClick={handleCreateApp}
            isLoading={createAppFromSample.isPending}
            data-testid="create-app-from-sample-submit-button"
          >
            {t('components.create_app.create_app')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
