import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiOutlineSquares2X2 as SampleMarketplaceIcon } from 'react-icons/hi2';
import { MdClose as CloseIcon } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, Skeleton, Tooltip } from '@knack/asterisk-react';

import { type SampleApp as SampleAppType } from '@/types/apps';
import { useAccountRestrictions } from '@/hooks/account/useAccountRestrictions';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { useHasOverflow } from '@/hooks/useHasOverflow';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useIsSharedBuilderOnly, useSession } from '@/hooks/useSession';
import { useTranslationArray } from '@/hooks/useTranslationArray';
import { useWelcomePathAutomation } from '@/hooks/useWelcomePathAutomation';
import { shouldSendAnalytics } from '@/utils/analytics';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';
import { getErrorMessage } from '@/utils/errors';
import { cn } from '@/utils/tailwind';
import { LoadingModal } from '@/components/ui/LoadingModal';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import { DEFAULT_SAMPLES, WelcomePath } from '@/pages/apps/create-app/constants';
import { CreateAppFromSampleModal } from '@/pages/apps/create-app/CreateAppFromSampleModal';
import { CreateAppModal } from '@/pages/apps/create-app/CreateAppModal';
import { DashboardIllustration } from './illustrations/DashboardIllustration';
import { DataManagementIllustration } from './illustrations/DataManagementIllustration';
import { DirectoryIllustration } from './illustrations/DirectoryIllustration';
import { ListIllustration } from './illustrations/ListIllustration';
import { PortalIllustration } from './illustrations/PortalIllustration';
import { ReportsIllustration } from './illustrations/ReportsIllustration';

type Illustration = {
  component: React.ReactNode;
  className: string;
};

const ILLUSTRATIONS: Record<string, Illustration> = {
  dashboard: {
    component: <DashboardIllustration />,
    className: 'bg-illustration-blue'
  },
  data_management: {
    component: <DataManagementIllustration />,
    className: 'bg-illustration-pink'
  },
  directory: {
    component: <DirectoryIllustration />,
    className: 'bg-illustration-green'
  },
  list: {
    component: <ListIllustration />,
    className: 'bg-illustration-orange'
  },
  portal: {
    component: <PortalIllustration />,
    className: 'bg-illustration-purple'
  },
  reports: {
    component: <ReportsIllustration />,
    className: 'bg-illustration-yellow'
  }
} as const;

type SampleAppProps = {
  title: string;
  illustration: Illustration;
  isLoading?: boolean;
  onSampleClick?: () => void;
};

function SampleApp({ title, illustration, isLoading = false, onSampleClick }: SampleAppProps) {
  const textRef = useRef<HTMLDivElement>(null);
  const hasTextOverflow = useHasOverflow(textRef, 'horizontal');

  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col gap-2 lg:w-0">
        <Skeleton className="h-24 sm:h-32 lg:h-24" />
        <Skeleton className="h-4" />
      </div>
    );
  }

  return (
    <Tooltip open={hasTextOverflow ? undefined : false}>
      <Tooltip.Trigger asChild>
        <button
          onClick={onSampleClick}
          type="button"
          className="group flex flex-1 cursor-pointer flex-col gap-2 text-left lg:w-0"
        >
          <div
            className={`relative h-24 w-full overflow-hidden rounded-md sm:h-32 lg:h-24 ${illustration.className}`}
          >
            <div className="absolute inset-0 z-10 bg-black bg-opacity-0 transition-colors group-hover:bg-opacity-20" />
            <div className="flex h-full w-full items-center justify-center drop-shadow-2xl [&>*]:max-h-full">
              {illustration.component}
            </div>
          </div>
          <div
            ref={textRef}
            className="w-full overflow-hidden text-ellipsis text-nowrap text-xs font-medium underline decoration-transparent underline-offset-[3px] transition-colors group-hover:decoration-current"
          >
            {title}
          </div>
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content>{title}</Tooltip.Content>
    </Tooltip>
  );
}

function AppButton({
  label,
  disabled = false,
  onClick,
  children,
  dataTestId,
  dataFeatureId
}: {
  label: string;
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  dataTestId?: string;
  dataFeatureId?: string;
}) {
  const [t] = useTranslation();
  const accountRestrictions = useAccountRestrictions();
  const isSharedBuilderOnly = useIsSharedBuilderOnly();

  const tooltipText = useMemo(() => {
    switch (true) {
      case accountRestrictions?.suspended:
        return t('components.apps.tooltip_suspended');
      case accountRestrictions?.frozen:
        return t('components.apps.tooltip_frozen');
      case accountRestrictions?.trialExpired:
        return t('components.apps.tooltip_trial_expired');
      case accountRestrictions?.reachedAppLimit:
        return t('components.apps.tooltip_limit_reached');
      case isSharedBuilderOnly:
        return t('components.apps.tooltip_shared_builder');
      default:
        return t('components.apps.tooltip_default');
    }
  }, [accountRestrictions, isSharedBuilderOnly, t]);

  return (
    <Tooltip open={disabled ? undefined : false}>
      <Tooltip.Trigger
        className={cn('flex flex-1 cursor-pointer flex-col gap-2', disabled && 'opacity-30')}
        onClick={onClick}
      >
        <div
          data-feature={dataFeatureId}
          className="relative flex h-24 w-full items-center justify-center  rounded-md bg-muted sm:h-32 lg:h-24"
        >
          {children}
        </div>
        <div className="text-xs font-medium" data-testid={dataTestId}>
          {label}
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>{tooltipText}</Tooltip.Content>
    </Tooltip>
  );
}

type CreateNewAppProps = {
  isDisabled?: boolean;
  isDismissible: boolean;
  isHidden: boolean;
  onOpenRestrictionModal: () => void;
  setAppCreationError: (error: string) => void;
};

const shuffle = (array: any[]) => array.sort(() => Math.random() - 0.5);

export function CreateNewApp({
  isDisabled = false,
  isDismissible,
  isHidden,
  onOpenRestrictionModal,
  setAppCreationError
}: CreateNewAppProps) {
  const [t] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldShowCreateAppModal, setShouldShowCreateAppModal] = useState(false);
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const [dismissed, setDismissed] = useLocalStorage(LOCAL_STORAGE_KEYS.createNewAppDismissed, 'no');
  const { data: samples, isLoading } = useSampleAppsQuery();
  const { createAppFromSample } = useAppMutation();
  const session = useSession();
  const [shouldShowAppSampleModal, setShouldShowAppSampleModal] = useState(false);
  const [selectedSampleTitle, setSelectedSampleTitle] = useState('');
  const basePhrases = useTranslationArray('components.create_app.creating');
  const creatingPhrases = useMemo(
    () => [
      t('components.create_app.creating_template', { template: selectedSampleTitle }),
      ...basePhrases
    ],
    [t, selectedSampleTitle, basePhrases]
  );
  const automation = useWelcomePathAutomation();

  useEffect(() => {
    if (searchParams.get('ai')) {
      setShouldShowAiModal(true);

      searchParams.delete('ai');

      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const suggestedSample = useMemo(() => {
    if (!session.user.marketing?.questionnaire || !samples) {
      return undefined;
    }

    const shuffled = shuffle(samples);

    const userIndustry = session.user.marketing?.questionnaire?.industry;

    return (
      shuffled.find(
        (sample: SampleAppType) =>
          sample.use_case === session.user.marketing?.questionnaire?.use_case &&
          !DEFAULT_SAMPLES.slice(1, DEFAULT_SAMPLES.length).includes(sample.slug)
      ) ??
      shuffled.find(
        (sample: SampleAppType) =>
          userIndustry &&
          (sample.industries ?? []).includes(userIndustry) &&
          !DEFAULT_SAMPLES.slice(1, DEFAULT_SAMPLES.length).includes(sample.slug)
      )
    );
  }, [samples]);

  const handleInstallSample = (sample: SampleAppType) => {
    setAppCreationError('');
    setSelectedSampleTitle(sample.title);
    const data = {
      appId: sample.id,
      templateSlug: sample.slug,
      appDescription: sample.description
    };
    createAppFromSample.mutate(data, {
      onSuccess: async (response) => {
        if (shouldSendAnalytics(session.user.email)) {
          await window.analytics.track('New App From Sample Created', {
            app: sample.title,
            groupId: session.account.id
          });
        }
        window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
          response.app.slug
        }`;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
      },
      onSettled: () => {
        setSelectedSampleTitle('');
      }
    });
  };

  useEffect(() => {
    if (automation && automation.path === WelcomePath.Template) {
      if (!samples || isDisabled || createAppFromSample.isPending) {
        return;
      }

      const template = samples.find((s: SampleAppType) => s.id === automation.extra.templateId);

      if (!template) {
        return;
      }

      handleInstallSample(template);
    }
  }, [automation, isDisabled, samples]);

  if (dismissed === 'yes' || isHidden) {
    return null;
  }

  return (
    <Card className="relative flex flex-col gap-4 sm:p-4">
      {isDismissible && (
        <Button
          intent="minimalStandalone"
          onClick={() => setDismissed('yes')}
          className="absolute right-3 top-3 p-2 opacity-70 transition-opacity hover:opacity-100"
        >
          <Button.Icon icon={CloseIcon} />
        </Button>
      )}

      <h3 className="text-xl font-medium text-emphasis">{t('components.apps.create_a_new_app')}</h3>
      <div className="grid grid-cols-2 flex-row gap-4 sm:grid-cols-3 lg:flex">
        <AppButton
          label={t('components.apps.new_app')}
          dataTestId="starting-create-new-app"
          disabled={isDisabled}
          onClick={() => setShouldShowCreateAppModal(!isDisabled && !createAppFromSample.isPending)}
        >
          <div className="rounded-full bg-gradient-1 p-1.5 text-white">
            <PlusIcon size={20} />
          </div>
        </AppButton>
        <div className="hidden min-h-full w-px bg-subtle lg:block" />
        {createAppFromSample.isPending && selectedSampleTitle && (
          <LoadingModal phrases={creatingPhrases} />
        )}

        {Array.from({ length: 4 }, (_, index) => {
          const sample =
            index === 0 && suggestedSample
              ? suggestedSample
              : samples?.find((s: SampleAppType) => s.slug === DEFAULT_SAMPLES[index]);

          return (
            <SampleApp
              key={sample?.id ?? index}
              title={sample?.title}
              isLoading={isLoading}
              illustration={ILLUSTRATIONS[sample?.icon] ?? ILLUSTRATIONS.dashboard}
              onSampleClick={() =>
                isDisabled ? onOpenRestrictionModal() : handleInstallSample(sample)
              }
            />
          );
        })}
        <AppButton
          label={t('components.apps.all_sample_apps')}
          disabled={isDisabled}
          onClick={() => setShouldShowAppSampleModal(!isDisabled && !createAppFromSample.isPending)}
          dataFeatureId="sample_apps_button"
        >
          <SampleMarketplaceIcon className="text-brand" size={48} strokeWidth={0.75} />
        </AppButton>

        <CreateAppModal
          open={shouldShowCreateAppModal}
          setIsOpen={setShouldShowCreateAppModal}
          onOpenAiModal={() => setShouldShowAiModal(true)}
        />

        <CreateAppFromSampleModal
          open={shouldShowAppSampleModal}
          setIsOpen={setShouldShowAppSampleModal}
        />

        {shouldShowAiModal && (
          <AiModal
            open
            onClose={() => {
              setShouldShowAiModal(false);
              setShouldShowCreateAppModal(false);
            }}
          />
        )}
      </div>
    </Card>
  );
}
