import { useEffect, useState } from 'react';

import { type Account, type AccountApplication } from '@/types/account';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useIsSharedBuilderOnly } from '@/hooks/useSession';
import { getHasTrialExpired } from '@/utils/trials';

export interface AccountRestrictionsProps {
  suspended?: boolean;
  suspensionWarning?: boolean;
  frozen?: boolean;
  trialExpired?: boolean;
  reachedAppLimit?: boolean;
  reachedStorageLimit?: boolean;
  reachedRecordLimit?: boolean;
}

function getAccountRestrictions(account: Account) {
  if (!account) {
    return {};
  }
  const numberOfApps = account.applications?.length;
  const appsLimit = account.plan_limits?.applications;
  const recordsLimit = account.plan_limits?.records;
  const storageLimit = account.plan_limits?.storage;
  const storageLimitInBytes = storageLimit * 1024 * 1024 * 1024;

  let numberOfRecords = 0;
  let storageUsedInBytes = 0;

  account.applications?.forEach((app: AccountApplication) => {
    numberOfRecords += app.counts?.total_entries ?? 0;
    storageUsedInBytes += app.counts?.asset_size ?? 0;
  });

  // States documentation: https://knack.atlassian.net/wiki/spaces/dashboard/pages/2146795584/Account+States
  const frozen = account.status === 'frozen';
  const suspended =
    account.status === 'pending' &&
    account.billing.status === 'delinquent' &&
    account.billing.notice_count > 29;
  const suspensionWarning =
    account?.status === 'active' && account?.billing.status === 'delinquent';
  const reachedAppLimit = numberOfApps ? numberOfApps >= appsLimit : false;
  const reachedStorageLimit = storageUsedInBytes >= storageLimitInBytes;
  const reachedRecordLimit = numberOfRecords >= recordsLimit;
  const trialExpired =
    account.status === 'pending' &&
    getHasTrialExpired(account.beta_deadline) &&
    !account.billing.has_paid;

  return {
    frozen,
    suspended,
    suspensionWarning,
    reachedAppLimit,
    reachedStorageLimit,
    reachedRecordLimit,
    trialExpired
  };
}

export function useAccountRestrictions() {
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const { data: account } = useAccountQuery();
  const [accountRestrictions, setAccountRestrictions] = useState<AccountRestrictionsProps>();

  useEffect(() => {
    if (account) {
      setAccountRestrictions(getAccountRestrictions(account));
    }
  }, [account, isSharedBuilderOnly]);

  return accountRestrictions;
}
