import { cn } from '@/utils/tailwind';

export default function GradientSpinner({ className }: { className?: string }) {
  return (
    <svg
      aria-hidden="true"
      className={cn('animate-spin text-gray-200', className)}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM24 40.186C32.9393 40.186 40.186 32.9393 40.186 24C40.186 15.0607 32.9393 7.81395 24 7.81395C15.0607 7.81395 7.81395 15.0607 7.81395 24C7.81395 32.9393 15.0607 40.186 24 40.186Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3.92951C24 1.75916 25.7721 -0.0339975 27.9138 0.317283C39.307 2.18597 48 12.0777 48 23.9997C48 26.1513 45.9205 27.5589 43.8157 27.1124L42.0013 26.7275C40.6716 26.4455 39.814 25.173 39.814 23.8137C39.814 16.3687 34.729 10.1114 27.8427 8.3252C25.7588 7.78468 24 6.0824 24 3.92951Z"
        fill="url(#svg-gradient-2)"
      />
    </svg>
  );
}
